import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import {
  updateReactions,
  updateStarboardPointsDonationPosts,
} from "../../Redux/Slice/starboardBeerDonationPostsSlice";
import useNotification from "../../components/snackbars/SnackBar";
import CommentSection from "./CommentSection/CommentSection";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import ReactionComponent from "./ReactionComponent";

function PostCard() {
  const [userData, setUserData] = useState(null);
  const [openComments, setOpenComments] = useState(null); // Track which post's comments are open
  const dispatch = useDispatch();
  const [conf, setConf] = useNotification();
  const [reactions, setReactions] = useState({
    like: 0,
    cake: 0,
    love: 0,
    thumbsup: 0,
  });

  const pointDonationPosts = useSelector(
    (state) =>
      state.starboardPointsDonationPosts.starboardPointsDonationPostsData
  );

  // Getting user Data from Local Storage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = JSON.parse(
          localStorage.getItem("dexkorUserData")
        );
        setUserData(storedUserData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleReactionClick = async (postId, reactionType) => {
    try {
      // Make the API call to update the server
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/starboard/beer-donation-post/${postId}/reactions`,
        {
          reactionType,
          userId: userData._id, // Send userId with the reaction
        }
      );
      const reactions = response.data.post.reactions;
      dispatch(
        updateReactions({
          postId: postId,
          reactions: reactions,
        })
      );
      // Optionally, update the post data if necessary
      // You might want to fetch the updated post data from the server
    } catch (error) {
      console.error("Error toggling reaction:", error);
      setConf({ msg: "Server error!" });
    }
  };

  const toggleComments = (postId) => {
    setOpenComments((prevOpenComments) =>
      prevOpenComments === postId ? null : postId
    );
  };

  //Handling the Profile Pics of the Users
  const userPhoto = null;
  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  return (
    <div>
      {pointDonationPosts.length === 0 && (
        <div className="flex items-center justify-center text-xl mt-32">
          No posts found
        </div>
      )}
      {pointDonationPosts.map((pointDonationPost, index) => {
        const userHasLiked = pointDonationPost.reactions?.like.includes(
          userData?._id
        );
        const userHasCake = pointDonationPost.reactions?.cake.includes(
          userData?._id
        );
        return (
          <div key={index}>
            <div
              className="bg-white shadow-xl rounded-xl mx-4 md:mx-auto mt-2 max-w-md md:max-w-5xl px-2"
              style={{
                height: "auto",
                overflow: "auto",
                msOverflowStyle: "none",
              }}
            >
              <div className="relative flex">
                <div className="flex items-start px-4 py-6">
                  {userPhoto ? (
                    <img
                      className="w-8 h-8 mt-4 rounded-full object-cover mr-4 shadow"
                      src={userPhoto}
                      alt={`${pointDonationPost?.donorDetails.name} avatar`}
                    />
                  ) : (
                    <div className="w-8 h-8 mt-4 rounded-full object-cover mr-4 shadow flex items-center justify-center bg-gray-300 text-gray-800 font-bold">
                      {getInitial(pointDonationPost?.donorDetails.name)}
                    </div>
                  )}
                  <div>
                    <div className="flex items-center justify-between mt-1">
                      <h2 className="text-lg font-semibold text-gray-900">
                        {pointDonationPost?.donorDetails.name}
                      </h2>
                    </div>
                    <p className="text-gray-700 text-xs">
                      Posted{" "}
                      {new Date(pointDonationPost.createdAt).toLocaleString(
                        [],
                        {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        }
                      )}
                      .{" "}
                    </p>
                    <p className="mt-3 text-gray-700 text-sm">
                      {pointDonationPost.message}
                    </p>
                    <div className="mt-4">
                      Tagged people
                      <div className="flex flex-wrap my-1 mx-1 py-1 gap-y-2">
                        {pointDonationPost.recipientDetails.length > 0 &&
                          pointDonationPost.recipientDetails.map(
                            (recipientDetail, index) => (
                              <div key={index} className="mr-3">
                                {userPhoto ? (
                                  <img
                                    className="w-8 h-8 mt-4 rounded-full object-cover mr-4 shadow"
                                    src={userPhoto}
                                    alt={`${recipientDetail.name} avatar`}
                                  />
                                ) : (
                                  <div className="w-8 h-8 mt-4 rounded-full object-cover mr-4 shadow flex items-center justify-center bg-gray-300 text-gray-800 font-bold">
                                    {getInitial(recipientDetail.name)}
                                  </div>
                                )}
                                <p className="text-xs">
                                  {recipientDetail.name}
                                </p>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    <div className="mt-4">
                      Core values
                      <div className="flex flex-wrap py-1 gap-y-2 mt-1">
                        {pointDonationPost.coreValues.length > 0 &&
                          pointDonationPost.coreValues.map(
                            (coreValue, index) => (
                              <span
                                key={index}
                                className={`${
                                  coreValue.background + " " + coreValue.color
                                }  text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer`}
                              >
                                {coreValue.name}
                              </span>
                            )
                          )}
                      </div>
                    </div>
                    <div className="mt-4 flex items-center">
                      <div className="flex space-x-4">
                        {/* Reaction for "Like" */}
                        <div
                          onClick={() =>
                            handleReactionClick(pointDonationPost._id, "like")
                          }
                          className="flex text-gray-700 text-sm cursor-pointer items-center"
                        >
                          <span className="mr-2 ">😍</span>
                          <span className="text-sm text-gray-700 font-medium">
                            {" "}
                            {pointDonationPost?.reactions?.like?.length}
                          </span>
                        </div>

                        {/* Reaction for "Cake" */}
                        <div
                          onClick={() =>
                            handleReactionClick(pointDonationPost._id, "cake")
                          }
                          className="flex text-gray-700 text-sm cursor-pointer items-center"
                        >
                          <span className="mr-2">🍰</span>
                          <span className="text-base text-gray-700 font-medium">
                            {" "}
                            {pointDonationPost?.reactions?.cake?.length}
                          </span>
                        </div>

                        {/* Reaction for "Love" */}
                        <div
                          onClick={() =>
                            handleReactionClick(pointDonationPost._id, "love")
                          }
                          className="flex text-gray-700 cursor-pointer items-center"
                        >
                          <span className="mr-2 ">❤️</span>
                          <span className="text-gray-700 font-medium">
                            {" "}
                            {pointDonationPost?.reactions?.love?.length}
                          </span>
                        </div>

                        {/* Reaction for "Thumbs Up" */}
                        <div
                          onClick={() =>
                            handleReactionClick(
                              pointDonationPost._id,
                              "thumbsup"
                            )
                          }
                          className="flex text-gray-700 text-sm cursor-pointer items-center"
                        >
                          <span className="mr-2">👍</span>
                          <span className="text-gray-700 text-sm font-medium">
                            {" "}
                            {pointDonationPost?.reactions?.thumbsup?.length}
                          </span>
                        </div>
                      </div>
                      <div className="flex text-blue-700 text-sm mr-4 ml-8 cursor-pointer">
                        <a
                          onClick={() => toggleComments(pointDonationPost._id)}
                          className="mr-16 flex items-center text-sm text-gray-500 p-0 bg-transparent hover:bg-transparent font-medium"
                        >
                          {pointDonationPost?.comments?.length > 0 &&
                            (openComments === pointDonationPost._id ? (
                              <GoChevronUp className="h-5 w-5 mr-2" />
                            ) : (
                              <GoChevronDown className="h-5 w-5 mr-2" />
                            ))}
                          {pointDonationPost?.comments?.length}{" "}
                          {pointDonationPost?.comments?.length <= 1
                            ? "Comment"
                            : "Comments"}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <small className="absolute right-8 top-8 text-xs text-gray-700">
                  {moment(pointDonationPost.createdAt).local().fromNow()}
                </small>
              </div>

              {/* Comment Section */}
              {openComments === pointDonationPost._id && (
                <CommentSection beerDonationPostId={pointDonationPost._id} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PostCard;
