import React, { useEffect, useRef, useState } from "react";
import logo from "../../Images/Logo icon.png";
import googleLogo from "../../Images/google-logo.png";
import portalLogo from "../../Images/portalLogo.png";
import Phone from "../../Images/Phone.png";
import internalLogo from "../../Images/internalLogo.png";
import TicketDetails from "../TicketDetails";
import "./TicketViewer.css";
import { Tabs, Col, Divider } from "antd";
import { RiAlarmLine } from "react-icons/ri";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import SLADetails from "../SLADetails";
import Emails from "../Emails/Emails";
import Tickets from "../Tickets/Tickets";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import useNotification from "../snackbars/SnackBar";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaLink } from "react-icons/fa6";
import SubTickets from "../SubTickets/SubTickets";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";

function TicketViewer({
  toggleDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  convo,
  setConversations,
  conversations,
  setSelectedConvo,
  viewData,
  subTicketViewData,
}) {
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [snoozeDateTime, setSnoozeDateTime] = useState(null);
  const [conf, setConf] = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (snoozeDateTime) {
      const selectedDate = new Date(snoozeDateTime); // Convert snoozeDateTime to a Date object
      const currentDate = new Date(); // Get the current date and time

      // Check if snoozeDateTime is in the future
      if (selectedDate < currentDate) {
        setConf({
          msg: "snooze date and time must be in the future.",
          variant: "error",
        });
        setShowDateTimePicker(true);
      } else {
        snoozeTickets();
      }
    }
  }, [snoozeDateTime]);

  const snoozeTickets = async () => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const userToken = localStorage.getItem("dexkorUserToken");
    const ticketIds = [convo.ticketId._id];
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/tickets/snooze`,
        {
          snoozeDateTime,
          ticketIds,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Role: `${userData.role}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setSnoozeDateTime(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Close the drawer when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const drawerElement = document.getElementById("drawer-form");
      if (
        isDrawerOpen &&
        drawerElement &&
        !drawerElement.contains(event.target)
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isDrawerOpen, toggleDrawer]);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [convo?.messages]);

  const logoMapping = {
    widget: logo,
    portal: portalLogo,
    email: googleLogo,
    servetel: Phone,
    internal: internalLogo,
    Internal: internalLogo,
  };

  const [copied, setCopied] = useState(false); // Track whether the URL is copied

  const handleCopyUrl = () => {
    const url = `${window.location}`; // Get the current URL
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true); // Set copied state to true
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean); // Split and remove empty segments

  // Handle navigation when a segment is clicked
  const handleSegmentClick = (index) => {
    // Construct the new path up to the clicked segment
    const newPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
    navigate(newPath);
  };

  const { subticketNumber } = useParams();
  const [tabKey, setTabKey] = useState(subticketNumber ? "3" : "1");

  const switchTab = (key) => {
    setTabKey(`${key}`);
  };

  return (
    <div>
      {/* Backdrop */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-500 z-50
          ${isDrawerOpen ? "opacity-100" : "opacity-0"}`}
        onClick={() => {
          const currentPath = window.location.pathname; // Get the current path
          // Replace the last segment of the URL
          const updatedPath = currentPath.replace(/\/[^/]*$/, ``);

          navigate(updatedPath); // Navigate to the updated path
          setIsDrawerOpen(false);
        }}
      ></div>

      {/* Drawer */}
      <div
        className={`fixed top-0 w-1/2 right-0 bg-gray-100 overflow-y-auto z-50
          ${isDrawerOpen ? "animate-slideIn" : "animate-slideOut"}`}
      >
        <div
          style={{ backgroundColor: "#eff3f5" }}
          className="bg-gray-100 w-full p-2 pb-0"
        >
          <p className="text-xs font-semibold text-gray-500 flex items-center justify-left">
            {pathSegments.map((segment, index) => (
              <React.Fragment key={index}>
                <span
                  onClick={() => handleSegmentClick(index)}
                  className={`capitalize hover:text-blue-500 hover:underline cursor-pointer ${
                    index === pathSegments.length - 1 &&
                    "text-blue-500 underline"
                  } relative group`}
                >
                  {segment}
                </span>
                {index !== pathSegments.length - 1 && (
                  <MdKeyboardArrowRight className="size-5" />
                )}
              </React.Fragment>
            ))}
            <div className="ml-2 relative group" onClick={handleCopyUrl}>
              <FaLink className="size-3 cursor-pointer" />
              <span className="absolute top-[-12px] left-2 transform -translate-x-1/2 text-[10px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                {copied ? "Copied!" : "Copy"}
              </span>
            </div>
          </p>
        </div>
        <hr></hr>
        <div className="p-4">
          <div className="flex space-x-2 items-center">
            <img
              src={logoMapping[convo.ticketId.source] || logo} // Fallback to defaultLogo if source is not matched
              alt="source image"
              className="w-auto h-8"
            />
            <h1 className="text-xl font-bold">
              {convo.ticketId.ticketNumber || "---"}
              {" - "}
              {convo?.ticketId?.subject}
            </h1>

            <div style={{ marginLeft: "auto" }}>
              <div className="flex items-center space-x-2">
                {convo?.ticketId?.isSubticket && (
                  <div
                    className="relative group"
                    onClick={() => {
                      const currentPath = window.location.pathname; // Get the current path
                      // Replace the last segment of the URL
                      const updatedPath = currentPath.replace(
                        /\/[^/]*$/,
                        `/${convo?.ticketId?.parentTicketNumber}`
                      );

                      navigate(updatedPath); // Navigate to the updated path
                    }}
                  >
                    <VscTypeHierarchySub className="size-4 cursor-pointer text-gray-600" />
                    <span className="absolute top-[-15px] left-2 transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                      Parent
                    </span>
                  </div>
                )}
                <div className="relative group">
                  <RiAlarmLine
                    className="size-4 cursor-pointer text-blue-600"
                    onClick={() => setShowDateTimePicker(true)}
                  />
                  <span className="absolute top-[-15px] left-1/2 transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                    Snooze
                  </span>
                </div>
              </div>
              {showDateTimePicker && (
                <DateTimePicker
                  setDateTime={setSnoozeDateTime}
                  dateTime={snoozeDateTime}
                  setShowDateTimePicker={setShowDateTimePicker}
                />
              )}
            </div>
          </div>
          <div>
            <Tabs
              defaultActiveKey={tabKey}
              onChange={switchTab}
              items={[
                {
                  label: "Messages",
                  key: "1",
                  children:
                    tabKey === "1" ? (
                      <Col style={{ height: "100vh" }}>
                        <div
                          style={{
                            justifyContent: "center",
                            backgroundColor: "#F6F7F9",
                          }}
                        >
                          {convo && convo.ticketId.source !== "email" && (
                            <div
                              ref={chatContainerRef}
                              style={{
                                overflow: "auto",
                              }}
                            >
                              <Tickets
                                selectedConversation={convo}
                                setSelectedConversation={setSelectedConvo}
                                setConversations={setConversations}
                                conversations={conversations}
                                departmentCode="DPT1001"
                              />
                            </div>
                          )}

                          {convo && convo.ticketId.source === "email" && (
                            <Emails
                              selectedConversation={convo}
                              setSelectedConversation={setSelectedConvo}
                              setConversations={setConversations}
                              conversations={conversations}
                              departmentCode="DPT1001"
                            />
                          )}
                        </div>
                      </Col>
                    ) : (
                      ""
                    ),
                },
                {
                  label: "Details",
                  key: "2",
                  children: (
                    <div
                      style={{
                        height: "100vh",
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                    >
                      {convo && (
                        <div>
                          {convo && (
                            <TicketDetails
                              selectedConversation={convo}
                              setConversations={setConversations}
                              setSelectedConvo={setSelectedConvo}
                            />
                          )}

                          <Divider />
                          {convo && <SLADetails selectedConversation={convo} />}
                        </div>
                      )}
                    </div>
                  ),
                },

                // Conditionally render the "Subtickets" tab
                ...(!convo?.ticketId?.isSubticket
                  ? [
                      {
                        label: "Subtickets",
                        key: "3",
                        children: (
                          <div
                            style={{
                              height: "100vh",
                              overflowY: "auto",
                              scrollbarWidth: "none",
                              msOverflowStyle: "none",
                            }}
                          >
                            {convo && (
                              <div>
                                <SubTickets
                                  viewData={subTicketViewData}
                                  parentConvo={convo}
                                  setParentConvo={setSelectedConvo}
                                  setParentConversations={setConversations}
                                />
                              </div>
                            )}
                          </div>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketViewer;
