import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../Private Route/PrivateRoute";
import PageNotFound from "../../components/PageNotFound";
import Index from "../Index";
import TicketExplorer from "../pages/TicketExplorer/TicketExplorer";
import { useSelector } from "react-redux";

const ProductProRoutes = () => {
  const ticketExplorerViews = useSelector(
    (state) => state.ticketExplorer.views
  );

  return (
    <>
      <Route
        path="/product-pro"
        element={
          <PrivateRoute>
            <Index />
          </PrivateRoute>
        }
      >
        <Route>
          {ticketExplorerViews.map((view, index) => {
            return (
              <Route
                key={index}
                path={`${
                  view?.isDefault ? "ticket-explorer" : "custom-views"
                }/${view.name
                  .replace(/\s+/g, "-")
                  .toLowerCase()}/:ticketNumber?`}
                element={
                  <PrivateRoute>
                    <TicketExplorer viewData={view} />
                  </PrivateRoute>
                }
              />
            );
          })}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </>
  );
};

export default ProductProRoutes;
