import React, { useEffect, useState } from "react";
import useNotification from "../../components/snackbars/SnackBar";
import axios from "axios";
import Add from "./icons/Add";
import Edit from "./icons/Edit";
import CakeIcon from "./icons/Cake";
import CelebrationIcon from "./icons/Celebration";
import EventIcon from "./icons/Event";
import CalendarIcon from "./icons/calendar";
import RankSkeletonLoader from "../../components/pageLoaders/RankSkeletonLoader";

const Events = () => {
  const [userData, setUserData] = useState(null);
  const [events, setEvents] = useState([]);
  const [conf, setConf] = useNotification();
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startdate: "",
    endDate: "",
    time: "",
    status: false,
    place: "",
    members: [],
    eventType: "normal", // default event type
  });

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  const isAllowedUser = true;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/get-all-events/${userData.uniquePartnerId}`
        );
        setEvents(response.data.data);
      } catch (error) {
        console.log("Error in fetching events");
      }
    };
    if (userData) fetchEvents();
  }, [userData]);

  const handleAddEvent = async (e) => {
    e.preventDefault();
    const newEvent = {
      uniquePartnerId: userData.uniquePartnerId,
      ...formData,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/create-event`,
        newEvent
      );
      setEvents([...events, response.data.data]);
      setShowForm(false);
      setFormData({
        name: "",
        description: "",
        startdate: "",
        endDate: "",
        time: "",
        status: false,
        place: "",
        members: [],
        eventType: "normal",
      });
    } catch (error) {
      console.error("Failed to add event:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/deleteEvent/${eventId}`
      );
      if (response.status === 200) {
        setEvents(events.filter((event) => event._id !== eventId));
        setConf({
          msg: "Event is deleted",
          variant: "success",
        });
      } else {
        console.error("Failed to delete event:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const formatEventDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const renderEventIcon = (eventType) => {
    switch (eventType) {
      case "birthday":
        return <CakeIcon />;
      case "celebration":
        return <CelebrationIcon />;
      default:
        return <EventIcon />;
    }
  };

  return (
    <div
      className="overflow-y-scroll px-2 overflow-hidden border-2 w-full rounded-lg"
      style={{ height: "40vh", scrollbarWidth: "none" }}
    >
      <div className="">
        <div className="flex justify-between mt-1">
          <div className="flex p-2 my-0">
            <CalendarIcon />
            <h2 className="text-xl mx-2" style={{ color: "#2A4DAD" }}>
              Events
            </h2>
          </div>

          {isAllowedUser && (
            <button
              onClick={() => setShowForm(true)}
              className="bg-transparent hover:bg-gray-300"
            >
              <Edit />
            </button>
          )}
        </div>
        {/* Modal Form */}
        {showForm && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/2">
              <h2 className="text-xl mb-4" style={{ color: "#2A4DAD" }}>
                Create New Event
              </h2>
              <form className="space-y-4">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Event Name"
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <input
                  type="date"
                  name="startdate"
                  value={formData.startdate}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <input
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <input
                  type="text"
                  name="place"
                  value={formData.place}
                  onChange={handleInputChange}
                  placeholder="Place"
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="status"
                    checked={formData.status}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        status: e.target.checked,
                      }))
                    }
                    className="mr-2"
                  />
                  Status (Active/Inactive)
                </label>
                <select
                  name="eventType"
                  value={formData.eventType}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="normal">Normal</option>
                  <option value="birthday">Birthday</option>
                  <option value="celebration">Celebration</option>
                </select>
              </form>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowForm(false)}
                  className="text-black bg-gray-200 px-4 py-2 rounded-md mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddEvent}
                  className="text-white px-4 py-2 rounded-md"
                  style={{
                    background: "#2A4DAD",
                    boxShadow: "0px 4px 25px 0px #2a4dad40",
                  }}
                >
                  <Add />
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Event Display Section */}
        {events.length > 0 ? (
          events.map((event) => (
            <div key={event._id} className="p-3 mt-1 bg-gray-50 rounded-xl">
              <div
                className="text-xl flex items-center"
                style={{ color: "#2A4DAD" }}
              >
                {renderEventIcon(event.eventType)}
                <span className="mx-4 font-medium">{event.name}</span>
                {isAllowedUser && (
                  <button
                    onClick={() => handleDeleteEvent(event._id)}
                    className=" ml-auto text-xs text-white"
                    style={{
                      borderRadius: "5px",
                      background:
                        "linear-gradient(90deg, #4c73de 0%, #2a4dad 100%)",
                      boxShadow: "0px 4px 25px 0px #2a4dad40",
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
              <p className="ml-10 text-base">
                <div className="text-xs">
                  {formatEventDate(event.startdate)} -{" "}
                  {formatEventDate(event.endDate)}{" "}
                  {event.time && <span>at {event.time}</span>}
                </div>
                <span className="font-medium">{event.description}</span>
                <br />
              </p>
            </div>
          ))
        ) : (
          <RankSkeletonLoader />
        )}
      </div>
    </div>
  );
};

export default Events;
