import React, { useState } from "react";
import { Button, Input, Select, Form, Card, Row, Col, DatePicker, message } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";

const { Option } = Select;

const Details = () => {
  const location = useLocation();
  const initialCampaign = location.state?.selectedCampaign;
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();

  const handleUpdate = async (values) => {
    setIsEditing(false);
    const userToken = localStorage.getItem("dexkorUserToken")
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"))

    if( userData && userToken) {
    try {
      // Convert Moment dates to strings before sending
      const payload = {
        ...values,
        startDate: values.startDate
          ? values.startDate.format("YYYY-MM-DD")
          : null,
        endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : null,
      };

      await axios.patch(
        `${process.env.REACT_APP_CONVERSATION}/campaign-explorer/update/${initialCampaign?._id}`,
        payload, // The payload comes as the second parameter
        {
          headers: {
            Authorization: `Bearer ${userToken}`, // Pass the user token
            Role: `${userData.role}`, // Include the role
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Campaign updated successfully");    
    } catch (error) {
      console.error("Error updating campaign:", error);
    }
  }
  };

  const handleCancel = () => {
    setIsEditing(false);
    // Reset form fields to initial values
    form.setFieldsValue({
      ...initialCampaign,
      startDate: initialCampaign?.startDate
        ? moment(initialCampaign.startDate)
        : null,
      endDate: initialCampaign?.endDate
        ? moment(initialCampaign.endDate)
        : null,
    });
  };

  return (
    <Card className="p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Campaign Details</h2>
        {!isEditing ? (
          <Button type="primary" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        ) : (
          <div className="space-x-2">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit(); // Trigger form submission
              }}
            >
              Update
            </Button>
          </div>
        )}
      </div>

      {/* Form */}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleUpdate}
        initialValues={{
          ...initialCampaign,
          startDate: initialCampaign?.startDate
            ? moment(initialCampaign.startDate)
            : null,
          endDate: initialCampaign?.endDate
            ? moment(initialCampaign.endDate)
            : null,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Campaign Name"
              name="name"
              rules={[{ required: true, message: "Campaign Name is required" }]}
            >
              <Input disabled={!isEditing} placeholder="Enter Campaign name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Description" name="description">
              <Input
                disabled={!isEditing}
                placeholder="Enter Description"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: "Status is required" }]}
            >
              <Select disabled={!isEditing} placeholder="Select Status">
                <Option value="Active">Planned</Option>
                <Option value="In Progress">In Progress</Option>
                <Option value="Aborted">Aborted</Option>
                <Option value="Completed">Completed</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: "Start Date is required" }]}
            >
              <DatePicker
                disabled={!isEditing}
                style={{ width: "100%" }}
                placeholder="Select Start Date"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: "End Date is required" }]}
            >
              <DatePicker
                disabled={!isEditing}
                style={{ width: "100%" }}
                placeholder="Select End Date"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Details;
