import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import logo from "../../../Images/Logo icon.png";
import googleLogo from "../../../Images/google-logo.png";
import portalLogo from "../../../Images/portalLogo.png";
import internalLogo from "../../../Images/internalLogo.png";
import Phone from "../../../Images/Phone.png";
import TicketViewer from "./components/TicketViewer/TicketViewer";
import { RxCross1 } from "react-icons/rx";
import { RiChatNewFill } from "react-icons/ri";
import useNotification from "../../../components/snackbars/SnackBar";
import { MdSnooze, MdOutlineEdit } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TicketForm from "../../../components/TicketForm/TicketForm";
import Preferences from "../../../components/Preferences/Preferences";
import TicketExplorerViewEditor from "../../../components/TicketExplorerViewEditor/TicketExplorerViewEditor";
import SlaRelatedDetails from "../../../components/SlaRelatedDetails";

function TicketExplorer({ viewData }) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConvo, setSelectedConvo] = useState(null);
  const [filterReady, setFilterReady] = useState(false); // New state to track filter readiness
  const [keyword, setKeyword] = useState("");
  const [openForm, setOpenForm] = useState({ open: false, update: false });
  const [openTicketForm, setOpenTicketForm] = useState(false);
  const [openPreferences, setOpenPreferences] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showSaveButton, setShowSaveButton] = useState(null); // New state to control save button visibility
  const [showFilters, setShowFilters] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Define how many items per page
  const [showColumnDropDown, setShowColumnDropDown] = useState(false);
  const [showActionsDropDown, setShowActionsDropDown] = useState(false);
  const [showOwnerDropdown, setShowOwnerDropDown] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropDown] = useState(false);
  const [page, setPage] = useState(1); // Track the current page
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true); // Whether more users are available
  const [limit, setLimit] = useState(4); // Number of users per page
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOwner, setSelectedOwner] = useState({ name: "Select" });
  const [selectedAssignee, setSelectedAssignee] = useState({ name: "Select" });
  const [userData, setUserData] = useState({});
  const [hoverFlag, setHoverFlag] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(
    Math.ceil(conversations.length / itemsPerPage)
  );
  const [totalCount, setTotalCount] = useState(conversations.length);
  const navigate = useNavigate();
  const [parentConvo, setParentConvo] = useState({});
  const location = useLocation();

  const fetchParetnConvo = async (parentTicketId) => {
    console.log({ parentTicketId });
    try {
      if (parentTicketId) {
        const res = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/conversation/${parentTicketId}`
        );
        console.log({ res });
        if (res.status === 200) {
          setParentConvo(res.data.conversation);
        }
      } else {
        console.log("Parent ticket id is missing!");
      }
    } catch (error) {
      console.log("Internal server error", error);
    }
  };

  const { ticketNumber } = useParams(); // Access the ticket number from the URL

  useEffect(() => {
    if (ticketNumber) {
      // Logic to pre-select the ticket or fetch related data
      const selected = conversations.find(
        (convo) => convo?.ticketId?.ticketNumber === ticketNumber
      );
      if (selected) {
        setSelectedConvo(selected);
        setIsDrawerOpen(true);
      } else {
        setKeyword(ticketNumber);
      }
    } else {
      setIsDrawerOpen(false);
    }
  }, [ticketNumber, conversations, location]);

  const fetchUser = async () => {
    const userIdFilter = viewData.filters.find(
      (filter) => filter.name === "owner"
    );
    const userId = userIdFilter?.value; // Extract the value of the userId filter

    if (userId) {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/batch`,
        { ids: [userId] }
      );
      setSelectedOwner(response.data[0]);
    }

    const assigneeIdFilter = viewData.filters.find(
      (filter) => filter.name === "assignee"
    );
    const assigneeId = assigneeIdFilter?.value;

    if (assigneeId) {
      const res = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/batch`,
        { ids: [assigneeId] }
      );
      setSelectedAssignee(res.data[0]);
    }
  };

  useEffect(() => {
    fetchUser();
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userInfo) setUserData(userInfo);
    setCurrentPage(1);
    setKeyword("");
  }, [viewData]);

  const showModal = () => {
    setOpenForm((prevState) => ({
      ...prevState, // Preserve the other keys in the object
      update: prevState.open ? false : prevState.update,
      open: !prevState.open, // Toggle the `open` key
    }));
  };

  const showTicketForm = () => {
    setOpenTicketForm(!openTicketForm);
  };

  const [columns, setColumns] = useState(viewData?.columns);

  const [filters, setFilters] = useState({});
  // Helper function to initialize showFilters based on viewData.filters
  const initializeFilters = (defaultFilters, viewFilters) => {
    return defaultFilters.map((filter) => {
      // Check if a matching filter (ignoring case) exists in viewFilters
      const match = viewFilters.find(
        (viewFilter) =>
          viewFilter.name.toLowerCase() === filter.name.toLowerCase()
      );

      // If a match is found, set `isActive` to true and copy the value
      return match ? { ...filter, isActive: true, value: match.value } : filter;
    });
  };

  // Helper function to compare initial viewData.filters with current filters
  const areFiltersDifferent = (
    initialFilters,
    currentFilters,
    initialColumns,
    currentColumns
  ) => {
    // Create a map of initialFilters with case-insensitive keys (lowercase names)
    const initialFilterValues = initialFilters.reduce((acc, filter) => {
      // Use toLowerCase for both name and value to ensure case-insensitive comparison
      acc[filter.name.toLowerCase()] = filter.value
        ? filter.value.toLowerCase()
        : null; // Store null if value is null
      return acc;
    }, {});

    // Create a map of currentFilters with case-insensitive keys (lowercase names)
    const currentFilterValues = Object.keys(currentFilters).reduce(
      (acc, key) => {
        // Normalize key to lowercase for case-insensitive comparison
        // If the key is 'slaBreaches', treat it as 'sla'
        const normalizedKey = key === "slaBreached" ? "sla" : key.toLowerCase();
        // Normalize value to lowercase and store null if value is null
        acc[normalizedKey] = currentFilters[key]
          ? currentFilters[key].toLowerCase()
          : null;
        return acc;
      },
      {}
    );

    // Compare both maps to check for any differences
    for (const key in initialFilterValues) {
      if (initialFilterValues[key] !== currentFilterValues[key]) {
        return true; // Found a difference
      }
    }

    // Also check if there are extra filters in currentFilters that don't exist in initialFilters
    if (filterCount !== viewData.filters.length) return true;

    // Filter columns based on isActive
    const activeInitialColumns = initialColumns.filter((col) => col.isActive);
    const activeCurrentColumns = currentColumns.filter((col) => col.isActive);

    // Check if the number of active columns is different
    if (activeInitialColumns.length !== activeCurrentColumns.length) {
      return true;
    }

    // Compare active columns for differences
    for (let i = 0; i < activeInitialColumns.length; i++) {
      if (activeInitialColumns[i].name !== activeCurrentColumns[i].name) {
        return true; // Found a difference in active column names
      }
    }

    return false; // No differences found
  };

  // Effect to set visibility of the save button based on filter changes
  useEffect(() => {
    const isDifferent = areFiltersDifferent(
      viewData.filters || [],
      filters,
      viewData.columns || [],
      columns
    );
    setShowSaveButton(isDifferent);
  }, [filters, viewData.filters, filterCount, columns]);

  // Inside the component
  const defaultFilters = [
    { name: "Status", isActive: false, value: null },
    { name: "Source", isActive: false, value: null },
    { name: "Type", isActive: false, value: null },
    { name: "Category", isActive: false, value: null },
    { name: "Priority", isActive: false, value: null },
    { name: "SLA", isActive: false, value: null },
    { name: "owner", isActive: false, value: null },
    { name: "assignee", isActive: false, value: null },
  ];

  useEffect(() => {
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setItemsPerPage(viewData.itemsPerPage);
    setColumns(viewData?.columns);
    setSelectedAssignee({ name: "Select" });
    setSelectedOwner({ name: "Select" });
  }, [viewData]);

  // Transform viewData.filters to set initial filters state, setting null values to ""
  const setInitialFilters = () => {
    if (viewData?.filters) {
      const transformedFilters = viewData.filters.reduce((acc, filter) => {
        const key = filter.name.toLowerCase(); // Convert name to lowercase
        acc[key] = filter.value !== null ? filter.value : ""; // Set value to empty string if null
        return acc;
      }, {});

      setFilters(transformedFilters);
      setFilterReady(true); // Mark filters as ready
    }
  };

  useEffect(() => {
    setInitialFilters();
  }, [viewData]);

  // Pagination logic
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  useEffect(() => {
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

        let response;

        if (keyword === "") {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/conversations/search/${userInfo.uniquePartnerId}?page=${currentPage}&limit=${itemsPerPage}`,
            { filters, departmentAssigned: "DevCore" }
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/conversations/search/${userInfo.uniquePartnerId}?keyword=${keyword}&page=${currentPage}&limit=${itemsPerPage}`,
            { filters, departmentAssigned: "DevCore" }
          );
        }

        setLoading(false);
        // Sorting tickets in descending order based on createdAt
        const data = response.data.conversations;
        setConversations(data); // Set the filtered conversations
        setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
        setTotalCount(response.data.totalCount);
      } catch (error) {
        setLoading(false); // Handle errors properly
        if (error.response && error.response.status === 404) {
          setConversations([]);
          setTotalPages(0);
          setTotalCount(0);
        }
        console.error(error); // Log error for debugging
      }
    };

    if (filterReady) {
      getAllConversation();
    }
  }, [keyword, currentPage, filters, itemsPerPage, filterReady]);

  // Define an array of background colors
  const backgroundColors = [
    "bg-red-300",
    "bg-green-300",
    "bg-blue-300",
    "bg-yellow-300",
    "bg-purple-300",
    "bg-pink-300",
    "bg-indigo-300",
    "bg-orange-300",
    "bg-teal-300",
    "bg-cyan-300",
    "bg-lime-300",
    "bg-amber-300",
    "bg-emerald-300",
    "bg-fuchsia-300",
    "bg-rose-300",
    "bg-sky-300",
    "bg-violet-300",
    "bg-gray-300",
    "bg-stone-300",
    "bg-amber-400",
    "bg-cyan-400",
    "bg-blue-400",
    "bg-indigo-400",
    "bg-orange-400",
    "bg-teal-400",
    "bg-pink-400",
    "bg-lime-400",
    "bg-purple-400",
    "bg-rose-400",
    "bg-sky-400",
    "bg-green-400",
    "bg-red-400",
    "bg-violet-400",
  ];

  const typeOptions = {
    Accounting: [
      {
        value: "Data Value MisMatch/Missing",
        label: "Data Value MisMatch/Missing",
      },
      { value: "Unmapped SKU Not Shown", label: "Unmapped SKU Not Shown" },
      { value: "Offline Unmapped", label: "Offline Unmapped" },
      { value: "Tax Rate Set up", label: "Tax Rate Set up" },
      {
        value: "Tally Sale/Returns Value mismatch",
        label: "Tally Sale/Returns Value mismatch",
      },
      { value: "GSTR1 Error", label: "GSTR1 Error" },
    ],
    Reconciliation: [
      { value: "Data misMatch/missing", label: "Data misMatch/missing" },
      { value: "P & L Related", label: "P & L Related" },
      { value: "Claims Related", label: "Claims Related" },
      { value: "Reports Related", label: "Reports Related" },
      { value: "Unable to Scan Returns", label: "Unable to Scan Returns" },
    ],
  };
  // Hash function to generate a consistent index based on the owner's name
  const getColorForOwner = (name) => {
    if (!name) return backgroundColors[0]; // default color if no name
    const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return backgroundColors[hash % backgroundColors.length];
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFilters = (name, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (value === null || value === "") {
        // If value is null or an empty string, remove the filter
        const { [name]: removed, ...remainingFilters } = newFilters;
        return remainingFilters; // Return the filters without the removed one
      }

      // Otherwise, update the filter state
      newFilters[name] = value;
      return newFilters;
    });

    setShowFilters((prevFilters) =>
      prevFilters.map((f) => {
        // Normalize the name to handle case-insensitive comparison
        const normalizedName = f.name.toLowerCase();
        const normalizedInputName =
          name === "slaBreached" ? "sla" : name.toLowerCase();

        // Update the filter if names match (considering case insensitivity)
        return normalizedName === normalizedInputName
          ? { ...f, value: value }
          : f;
      })
    );
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname; // Get the current path
    if (selectedConvo) {
      // navigate(`${selectedConvo?.ticketId?.ticketNumber}`);
      // toggleDrawer();
    } else {
      // navigate(`hellothere`);
    }
  }, [selectedConvo]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [rotate, setRotate] = useState(false); // State to track rotation

  const handleClick = () => {
    setRotate(!rotate); // Toggle rotation on every click
    // setFilters({});
    setInitialFilters();
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setKeyword("");
    setColumns(viewData.columns);
  };

  const handleItemsPerpage = (value) => {
    if (value === "" || value === null) {
      setItemsPerPage(25);
    } else setItemsPerPage(value);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const scrollDropDownRef = useRef(null);
  const columnDropdownRef = useRef(null);
  const actionsDropdownRef = useRef(null);
  const ownerDropdownRef = useRef(null);
  const assigneDropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    // Define an async function to filter all users
    const searchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${loggedInUserData.uniquePartnerId}`,
          {
            params: { query: searchQuery }, // Pass the query as a query parameter
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call the fetch function
    if (searchQuery !== "") {
      searchUsers();
    }
  }, [searchQuery]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionsDropdownRef.current &&
        !actionsDropdownRef.current.contains(event.target)
      ) {
        setShowActionsDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showActionsDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showActionsDropDown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        assigneDropdownRef.current &&
        !assigneDropdownRef.current.contains(event.target)
      ) {
        setShowAssigneeDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showAssigneeDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAssigneeDropdown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setShowColumnDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnDropdownRef.current &&
        !columnDropdownRef.current.contains(event.target)
      ) {
        setShowColumnDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showColumnDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColumnDropDown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ownerDropdownRef.current &&
        !ownerDropdownRef.current.contains(event.target)
      ) {
        setShowOwnerDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showOwnerDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOwnerDropdown]);

  // Determine which type options to show based on the selected category
  const getFilteredTypeOptions = () => {
    if (!filters.category) {
      // Show all types if no category is selected
      return [...typeOptions.Accounting, ...typeOptions.Reconciliation];
    }
    return typeOptions[filters.category] || [];
  };

  const handleToggle = (index) => {
    // Create a new array to avoid direct mutation
    const updatedColumns = columns.map((col, idx) =>
      idx === index ? { ...col, isActive: !col.isActive } : col
    );
    setColumns(updatedColumns); // Update state
  };

  // Fetch users based on page and limit
  const fetchUsers = async (reset = false) => {
    if (!hasMore || !loggedInUserData) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/chunks/${loggedInUserData.uniquePartnerId}?page=${page}&limit=${limit}`
      );
      const fetchedUsers = response.data.users;

      if (response.status === 204) {
        setHasMore(false); // Stop fetching when no more users are available
      } else {
        setUsers((prevUsers) =>
          reset ? fetchedUsers : [...prevUsers, ...fetchedUsers]
        );
        setPage((prevPage) => prevPage + 1); // Increment the page
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  // Fetch users when the dropdown is shown for the first time
  useEffect(() => {
    if (showOwnerDropdown || showAssigneeDropdown) {
      fetchUsers(true); // Fetch users for the first time or reset if dropdown is reopened
    }
  }, [showOwnerDropdown, showAssigneeDropdown]);

  // Infinite scroll logic for dropdown
  useEffect(() => {
    const dropdownElement = scrollDropDownRef.current; // Reference the dropdown element
    const handleScroll = () => {
      if (
        dropdownElement &&
        dropdownElement.scrollTop + dropdownElement.clientHeight >=
          dropdownElement.scrollHeight &&
        !loading
      ) {
        fetchUsers(); // Fetch more users on scroll
      }
    };

    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, page]);

  // Fetch logged-in user data
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userData) setLoggedInUserData(userData);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [viewData]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedConvo(null);
    }
  }, [isDrawerOpen]);

  const [conf, setConf] = useNotification();

  const handleCancelTicket = async () => {
    try {
      const status = "Cancel";
      const token = localStorage.getItem("dexkorUserToken");
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/tickets/${selectedConvo?.ticketId?._id}/cc-bcc`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
            Role: "user", // Add the Role header
            "Content-Type": "application/json", // Optional: set content type
          },
        }
      );

      setConversations((prevConversations) =>
        prevConversations.filter(
          (conversation) => conversation._id !== selectedConvo._id
        )
      );
      setSelectedConvo(null);
      setShowActionsDropDown(false);
      setConf({
        msg: `Ticket ${
          selectedConvo?.ticketId?.ticketNumber || "---"
        } is cancelled`,
        variant: "success",
      });
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const addSpamEmail = async (email) => {
    if (!email) {
      setConf({ msg: "Email missing.", variant: "warning" });
      return;
    }
    try {
      const userData = localStorage.getItem("dexkorUserData");
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/email/addSpamEmail/${userData.uniquePartnerId}`,
        { email }
      );
      if (response.status === 200 || response.status === 201) {
        setConf({ msg: `${email} is marked as spam.`, variant: "success" });
        setShowActionsDropDown(false);
      }
    } catch (error) {
      console.error("Error occurred while marking email as spam:", error);
    }
  };

  const logoMapping = {
    widget: logo,
    portal: portalLogo,
    email: googleLogo,
    servetel: Phone,
    internal: internalLogo,
    Internal: internalLogo,
  };

  return (
    <section className="bg-gray-100 p-1">
      <div className="mx-auto">
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <div className="flex flex-col px-2 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
            <div className="flex items-start">
              <h5 className="flex">
                <p
                  onClick={() => {
                    if (viewData?.userId === userData?._id) {
                      // Check if the IDs match
                      setOpenForm((prevOpenForm) => ({
                        ...prevOpenForm,
                        update: true,
                      }));
                      showModal();
                    }
                  }}
                  className="flex items-center justify-center space-x-1 text-gray-500 justify-center cursor-pointer hover:bg-blue-600 hover:text-white px-2 py-1 text-xs font-medium rounded-lg border rounded-lg"
                >
                  <span>{viewData.name}</span>{" "}
                  {viewData?.userId === userData?._id && <MdOutlineEdit />}{" "}
                </p>
                <span className="p-1">
                  {" : "}
                  {totalCount}
                </span>
              </h5>
            </div>
            {isDrawerOpen && selectedConvo && (
              <TicketViewer
                isDrawerOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                setIsDrawerOpen={setIsDrawerOpen}
                convo={selectedConvo}
                setSelectedConvo={setSelectedConvo}
                conversations={conversations}
                setConversations={setConversations}
                parentConvo={parentConvo}
                setParentConvo={setParentConvo}
              />
            )}

            {/* Middle container for the search input and dropdown actions */}
            <div className="flex flex-1 justify-center items-center h-8">
              <div className="flex items-center space-x-4 relative">
                <div className="w-full">
                  <input
                    type="text"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    id="simple-search"
                    className="bg-gray-50 z-0 border h-8 border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 pr-16"
                    placeholder="Search ticket here"
                    style={{
                      fontSize: "13px",
                      minWidth: "5rem",
                      borderRadius: "100px",
                    }}
                    required=""
                  />
                </div>
                <div className="absolute right-2">
                  <CiSearch />
                </div>
              </div>
            </div>

            {/* Right-side actions and select dropdown */}
            <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
              {/* {showSaveButton && ( */}
              {selectedConvo && (
                <div ref={actionsDropdownRef}>
                  <button
                    id="dropdownHelperButton"
                    data-dropdown-toggle="dropdownHelper"
                    className="flex items-center justify-center px-2 py-1 text-xs font-medium text-white text-xs bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-md text-center inline-flex items-center"
                    type="button"
                    onClick={() => setShowActionsDropDown(!showActionsDropDown)}
                  >
                    Actions{" "}
                    <svg
                      class="w-2 h-2 ms-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  {/* <!-- Dropdown menu --> */}
                  {showActionsDropDown && (
                    <div
                      id="dropdownHelper"
                      className="absolute cursor-pointer z-10 w-60 mt-2 right-24 top-10 bg-white rounded-lg shadow divide-y divide-gray-100"
                      style={{ maxHeight: "300px", overflowY: "auto" }} // Set a fixed height and make it scrollable
                    >
                      <ul
                        className="p-3 cursor-pointer space-y-1 text-sm text-gray-700"
                        aria-labelledby="dropdownHelperButton"
                      >
                        <li
                          className="cursor-pointer"
                          onClick={() => handleCancelTicket()}
                        >
                          <div className=" cursor-pointer flex p-2 rounded hover:bg-gray-100">
                            <div className=" cursor-pointer ms-2 text-sm">
                              <label className="cursor-pointer font-medium text-gray-900">
                                <div>Cancel tickets</div>
                              </label>
                            </div>
                          </div>
                        </li>
                        {selectedConvo?.ticketId?.email &&
                          selectedConvo?.ticketId?.source === "email" && (
                            <li
                              onClick={() =>
                                addSpamEmail(selectedConvo?.ticketId?.email)
                              }
                              className="cursor-pointer"
                            >
                              <div className=" cursor-pointer flex p-2 rounded hover:bg-gray-100">
                                <div className=" cursor-pointer ms-2 text-sm">
                                  <label className="cursor-pointer font-medium text-gray-900">
                                    <div>
                                      Mark as spam from:{" "}
                                      <span className="cursor-pointer text-xs">
                                        {selectedConvo?.ticketId?.email}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </li>
                          )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              <button
                type="button"
                className={`flex items-center justify-center px-2 py-1 text-xs font-medium rounded-lg  text-white ${
                  showSaveButton
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "cursor-not-allowed text-white bg-blue-400 hover:bg-blue-400"
                }`}
                onClick={() => showModal()}
                disabled={!showSaveButton}
              >
                Save view
              </button>

              <button
                onClick={showTicketForm}
                className="text-white font-medium text-xs px-2 py-1 bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-lg text-center inline-flex items-center"
                type="button"
              >
                <RiChatNewFill className="mr-2" /> Create New Ticket{" "}
              </button>
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex space-x-2" style={{ width: "70%" }}>
              <div className="flex relative group">
                <button
                  onClick={() =>
                    setFilters((prevFilters) => {
                      const newFilters = { ...prevFilters };

                      // If snoozed filter exists, remove it (set to null or delete it)
                      if (
                        newFilters.snoozed === null ||
                        newFilters.snoozed === undefined
                      ) {
                        newFilters.snoozed = "true"; // Or you can use delete newFilters.snoozed to remove it entirely
                      } else {
                        newFilters.snoozed = null; // Add snoozed filter if it doesn't exist
                      }

                      return newFilters;
                    })
                  }
                  id="dropdownBgHoverButton"
                  className={`flex-shrink-0 h-8 inline-flex items-center py-2 px-1 text-xs text-center ${
                    filters.snoozed === null || filters.snoozed === undefined
                      ? "bg-gray-100 hover:bg-gray-200"
                      : "bg-blue-600 hover:bg-blue-700"
                  } text-gray-500  border border-gray-300 rounded-xl  focus:ring-4 focus:outline-none focus:ring-gray-100`}
                  type="button"
                >
                  <MdSnooze
                    className={`size-5 ${
                      filters.snoozed === null || filters.snoozed === undefined
                        ? ""
                        : "text-white"
                    }`}
                  />
                </button>
                <span className="absolute top-[-15px] left-8 transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                  Snoozed
                </span>
              </div>

              {showFilters?.map((filter) => {
                if (filter.name === "Status" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className=" h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Status{" "}
                        </p>
                        <label for="states" className="sr-only">
                          Choose state
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("status", e.target.value || null)
                          } // Handle select change
                          value={filters.status || filter.value || ""}
                        >
                          <option value="" selected>
                            select
                          </option>
                          <option value="Open">Open</option>
                          <option value="Assigned">Assigned</option>
                          <option value="UnAssigned">UnAssigned</option>
                          <option value="Work In Progress">
                            WorkInProgress
                          </option>
                          <option value="Pending To Tech">
                            Pending To Tech
                          </option>
                          <option value="Resolved">Resolved</option>
                          <option value="Awaiting Customer Response">
                            Awaiting Customer Response
                          </option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("status", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "owner" && filter.isActive) {
                  return (
                    <div className="flex" key={filter.name}>
                      <p class="relative inline-flex p-0">
                        <p
                          id="source-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Owner{" "}
                        </p>
                        <div ref={ownerDropdownRef}>
                          <div
                            className="cursor-pointer h-8 rounded-e-lg text-xs text-center border inline-flex items-center p-2 bg-gray-100"
                            onClick={() => {
                              setShowOwnerDropDown(!showOwnerDropdown);
                              setPage(1);
                              setShowAssigneeDropDown(false);
                            }}
                          >
                            {selectedOwner?.name?.split(" ")[0]}
                            <svg
                              className="w-2 h-1.5 ms-3 tex-xs"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </div>

                          {showOwnerDropdown && !showAssigneeDropdown && (
                            <div className="flex justify-end">
                              <div className="absolute z-20 left-0 top-8 bg-white rounded-lg shadow w-60">
                                <ul
                                  id="dropdownUsers"
                                  className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                  aria-labelledby="dropdownUsersButton"
                                  ref={scrollDropDownRef}
                                >
                                  {users.length > 0 &&
                                    users.map((user, index) => (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          handleFilters(
                                            "owner",
                                            user?._id || null
                                          );
                                          setShowOwnerDropDown(false);
                                          setSelectedOwner(user);
                                        }}
                                      >
                                        <p className="flex px-4 py-2 hover:bg-gray-100 text-wrapper">
                                          {user?.image ? (
                                            <img
                                              className="w-6 h-6 me-2 mb-3 rounded-full"
                                              src={user.image}
                                              alt="User image"
                                            />
                                          ) : (
                                            <div className="w-6 h-6 me-2 mb-3 rounded-full bg-gray-300 flex items-center justify-center text-white">
                                              {user?.name?.charAt(0)}
                                            </div>
                                          )}
                                          <span className="flex-1 overflow-hidden">
                                            <p className="text-sm font-medium text-gray-800 truncate">
                                              {user.name}
                                            </p>
                                            <span className="text-xs text-gray-600 truncate">
                                              {user.email}
                                            </span>
                                          </span>
                                        </p>
                                      </li>
                                    ))}
                                  {loading && (
                                    <li className="text-center">Loading...</li>
                                  )}
                                </ul>
                                <div className="flex items-center p-2 text-xs text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                                  <input
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Search user..."
                                    className="w-full h-6 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("owner", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "assignee" && filter.isActive) {
                  return (
                    <div className="flex" key={filter.name}>
                      <p class="relative inline-flex p-0">
                        <p
                          id="source-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Assignee{" "}
                        </p>
                        <div ref={assigneDropdownRef}>
                          <div
                            className="cursor-pointer h-8 rounded-e-lg text-xs text-center border inline-flex items-center p-2 bg-gray-100"
                            onClick={() => {
                              setShowAssigneeDropDown(!showAssigneeDropdown);
                              setPage(1);
                            }}
                          >
                            {selectedAssignee?.name?.split(" ")[0]}
                            <svg
                              className="w-2 h-1.5 ms-3 tex-xs"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </div>

                          {!showOwnerDropdown && showAssigneeDropdown && (
                            <div className="flex justify-end">
                              <div className="absolute z-20 left-0 top-8 bg-white rounded-lg shadow w-60">
                                <ul
                                  id="dropdownUsers"
                                  className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                  aria-labelledby="dropdownUsersButton"
                                  ref={scrollDropDownRef}
                                >
                                  {users.length > 0 &&
                                    users.map((user, index) => (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          handleFilters(
                                            "assignee",
                                            user?._id || null
                                          );
                                          setShowAssigneeDropDown(false);
                                          setSelectedAssignee(user);
                                        }}
                                      >
                                        <p className="flex px-4 py-2 hover:bg-gray-100 text-wrapper">
                                          {user?.image ? (
                                            <img
                                              className="w-6 h-6 me-2 mb-3 rounded-full"
                                              src={user.image}
                                              alt="User image"
                                            />
                                          ) : (
                                            <div className="w-6 h-6 me-2 mb-3 rounded-full bg-gray-300 flex items-center justify-center text-white">
                                              {user?.name?.charAt(0)}
                                            </div>
                                          )}
                                          <span className="flex-1 overflow-hidden">
                                            <p className="text-sm font-medium text-gray-800 truncate">
                                              {user.name}
                                            </p>
                                            <span className="text-xs text-gray-600 truncate">
                                              {user.email}
                                            </span>
                                          </span>
                                        </p>
                                      </li>
                                    ))}
                                  {loading && (
                                    <li className="text-center">Loading...</li>
                                  )}
                                </ul>
                                <div className="flex items-center p-2 text-xs text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                                  <input
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Search user..."
                                    className="w-full h-6 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("assignee", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Source" && filter.isActive) {
                  return (
                    <div className="flex" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="source-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Source{" "}
                        </p>
                        <label for="Type" className="sr-only">
                          Choose Source
                        </label>
                        <select
                          id="type"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("source", e.target.value || null)
                          } // Handle select change
                          value={filters.source || filter.value || ""}
                        >
                          <option value="">Select</option>
                          <option value="widget">Widget</option>
                          <option value="email">Email</option>
                          <option value="servetel">Servetel</option>
                          <option value="portal">Portal</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("source", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Category" && filter.isActive) {
                  return (
                    <div className="flex " key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="category-button"
                          data-dropdown-toggle="dropdown-category"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Category{" "}
                        </p>
                        <label for="category" className="sr-only">
                          Choose Category
                        </label>
                        <select
                          id="Category"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("category", e.target.value || null)
                          } // Handle select change
                          value={filters.category || filter.value || ""}
                        >
                          <option value="">Select category</option>
                          <option value="Accounting">Accounting</option>
                          <option value="Reconciliation">Reconciliation</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("category", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Type" && filter.isActive) {
                  return (
                    <div className="flex " key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="type-button"
                          data-dropdown-toggle="dropdown-type"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Type{" "}
                        </p>
                        <label for="Type" className="sr-only">
                          Choose Type
                        </label>
                        <select
                          id="Type"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("type", e.target.value || null)
                          } // Handle select change
                          value={filters.type || filter.value || ""}
                        >
                          <option value="" selected>
                            Select
                          </option>
                          {getFilteredTypeOptions().map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("type", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Priority" && filter.isActive) {
                  return (
                    <div className="flex " key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0  h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Priority{" "}
                        </p>
                        <label for="states" className="sr-only">
                          Choose Priority
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("priority", e.target.value || null)
                          } // Handle select change
                          value={filters.priority || filter.value || ""}
                        >
                          <option value="" selected>
                            select
                          </option>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                          <option value="Critical">Critical</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("priority", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "SLA" && filter.isActive) {
                  return (
                    <div className="flex " key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0  h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          SLA{" "}
                        </p>
                        <label for="states" className="sr-only">
                          Choose SLA
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("slaBreached", e.target.value || null)
                          } // Handle select change
                          value={filters.slaBreached || filter.value || ""}
                        >
                          <option value="" selected>
                            select
                          </option>
                          <option value={true}>Breached</option>
                          <option value={false}>Not Breached</option>
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("slaBreached", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                return null;
              })}

              {filterCount !== showFilters.length && (
                <div className="flex" ref={dropdownRef}>
                  {/* Button to toggle dropdown */}
                  <div className="flex relative group w-full">
                    <button
                      id="dropdownBgHoverButton"
                      onClick={toggleDropdown}
                      className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-xl hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                      type="button"
                    >
                      +
                    </button>
                    <span className="absolute top-[-15px] left-7 w-full transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                      Add
                    </span>
                  </div>

                  {/* Dropdown menu, visible only if isDropdownOpen is true */}
                  {isDropdownOpen && (
                    <div
                      id="dropdownBgHover"
                      className="absolute z-20 w-60 mt-2 bg-white rounded-lg shadow"
                    >
                      <ul
                        className="p-3 space-y-1 text-sm text-gray-700"
                        aria-labelledby="dropdownBgHoverButton"
                      >
                        {showFilters.map((filter) => {
                          if (!filter.isActive) {
                            return (
                              <li>
                                <div
                                  onClick={() => {
                                    setShowFilters((prevFilters) =>
                                      prevFilters.map((f) =>
                                        f.name === filter.name
                                          ? { ...f, isActive: !f.isActive }
                                          : f
                                      )
                                    );
                                    setFilterCount(filterCount + 1);
                                  }}
                                  className="flex items-center p-2 rounded hover:bg-gray-100"
                                >
                                  <label
                                    htmlFor="checkbox-item-5"
                                    className="w-full ms-2 text-xs font-medium text-gray-900 rounded"
                                  >
                                    {filter.name}
                                  </label>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              <div
                className="flex items-center relative group mb-2 cursor-pointer"
                onClick={handleClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`size-5 transition-transform duration-500 ease-in-out ${
                    rotate ? "rotate-[-180deg]" : "rotate-0"
                  }`} // Rotate counterclockwise on click
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                {/* Label that appears with hover */}
                <span className="absolute top-[-15px] left-3 transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                  Reset
                </span>
              </div>
            </div>
            <div className="flex ml-auto mr-2 mb-2">
              <Preferences
                setOpenPreferences={setOpenPreferences}
                columns={columns}
                handleToggle={handleToggle}
                itemsPerPage={itemsPerPage}
                handleItemsPerpage={handleItemsPerpage}
                openPreferences={openPreferences}
              />
            </div>
          </div>

          {conversations.length > 0 ? (
            <>
              <div className="overflow-x-auto overflow-y-auto max-h-[80vh]">
                <table className="w-full h-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 z-10">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 sticky left-0 z-0 bg-gray-50"
                      >
                        <div className="flex items-center">
                          <input
                            id="checkbox-all"
                            type="checkbox"
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2 "
                          />
                          <label for="checkbox-all" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </th>
                      {columns?.map((col) => {
                        if (col.isActive) {
                          return (
                            <th
                              scope="col"
                              className={`px-4 py-3 justify-center whitespace-nowrap ${
                                col.name === "ticket" ||
                                col.name === "ticket-number"
                                  ? "sticky left-12 z-0 bg-gray-50"
                                  : ""
                              } ${
                                col.name === "subject" || col.name === "title"
                                  ? "sticky left-[13rem] z-0 bg-gray-50"
                                  : ""
                              }`}
                            >
                              {col.name}
                            </th>
                          );
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      conversations.length > 0 &&
                      conversations.map((convo, index) => {
                        return (
                          <tr
                            onClick={() => {
                              toggleDrawer();
                              setSelectedConvo(convo);
                              fetchParetnConvo(convo?.ticketId?.parentTicketId);
                              navigate(`${convo?.ticketId?.ticketNumber}`);
                            }}
                            className="border-b bg-white hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            <td
                              onMouseEnter={() => setHoverFlag(convo._id)}
                              onMouseLeave={() => setHoverFlag(null)}
                              className={`w-4 px-4 py-3 sticky left-0 z-0 ${
                                hoverFlag === convo._id
                                  ? "bg-gray-100"
                                  : "bg-white"
                              }`}
                              onClick={(event) => event.stopPropagation()}
                            >
                              <div className="flex items-center">
                                <input
                                  id="checkbox-table-search-1"
                                  type="checkbox"
                                  onChange={() => {
                                    selectedConvo === null
                                      ? setSelectedConvo(convo)
                                      : selectedConvo._id === convo._id
                                      ? setSelectedConvo(null)
                                      : setSelectedConvo(convo);
                                  }}
                                  checked={selectedConvo?._id === convo._id}
                                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2"
                                />
                                <label
                                  for="checkbox-table-search-1"
                                  className="sr-only"
                                >
                                  checkbox
                                </label>
                              </div>
                            </td>
                            {columns.some(
                              (col) =>
                                (col.name === "ticket-number" ||
                                  col.name === "ticket") &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className={`flex items-center pl-2 pr-16 py-2 font-medium text-gray-700 whitespace-nowrap sticky left-12 z-0 ${
                                  hoverFlag === convo._id
                                    ? "bg-gray-100"
                                    : "bg-white"
                                }`}
                              >
                                <img
                                  src={
                                    logoMapping[convo.ticketId.source] || logo
                                  } // Fallback to defaultLogo if source is not matched
                                  alt="iMac Front Image"
                                  className="w-auto h-8 mr-2"
                                />
                                {convo.ticketId.ticketNumber || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                (col.name === "subject" ||
                                  col.name === "title") &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className={`px-4 py-2 font-medium text-gray-700 whitespace-nowrap sticky left-[13rem] z-0 ${
                                  hoverFlag === convo._id
                                    ? "bg-gray-100"
                                    : "bg-white"
                                }`}
                              >
                                {convo.ticketId.subject || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "status" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.status || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "owner" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.owner?.imageUrl ? (
                                    <img
                                      src={convo.owner.imageUrl}
                                      alt={`${convo.owner.name}'s avatar`}
                                      className="w-auto h-6 mr-6 rounded-full"
                                    />
                                  ) : (
                                    <div
                                      className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                        convo.owner?.name
                                      )}`}
                                    >
                                      {convo.owner?.name
                                        ? convo.owner?.name
                                            .split(" ") // Split the name into words
                                            .filter(
                                              (word) => word.trim().length > 0
                                            ) // Ensure no empty strings are included
                                            .reduce(
                                              (acc, word, idx, arr) =>
                                                idx === 0 ||
                                                idx === arr.length - 1
                                                  ? acc + word[0]
                                                  : acc, // Take the first letter of the first and last words
                                              ""
                                            )
                                            .toUpperCase() // Convert to uppercase
                                        : "--"}
                                    </div>
                                  )}
                                  {convo.owner?.name || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "assignee" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.assignee?.imageUrl ? (
                                    <img
                                      src={convo.assignee.imageUrl}
                                      alt={`${convo.assignee.name}'s avatar`}
                                      className="w-auto h-6 mr-6 rounded-full"
                                    />
                                  ) : (
                                    <div
                                      className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                        convo.assignee?.name
                                      )}`}
                                    >
                                      {convo.assignee?.name
                                        ? convo.assignee?.name
                                            .split(" ") // Split the name into words
                                            .filter(
                                              (word) => word.trim().length > 0
                                            ) // Ensure no empty strings are included
                                            .reduce(
                                              (acc, word, idx, arr) =>
                                                idx === 0 ||
                                                idx === arr.length - 1
                                                  ? acc + word[0]
                                                  : acc, // Take the first letter of the first and last words
                                              ""
                                            )
                                            .toUpperCase() // Convert to uppercase
                                        : "--"}
                                    </div>
                                  )}
                                  {convo.assignee?.name || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "last update" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.updatedAt
                                  ? new Date(
                                      convo.ticketId.updatedAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.updatedAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}

                            {columns.some(
                              (col) =>
                                col.name === "customer code" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo?.customerDetails?.customerCode ||
                                    "---"}
                                </div>
                              </td>
                            )}

                            {columns.some(
                              (col) =>
                                col.name === "company name" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo?.customerDetails?.companyLogo ? (
                                    <img
                                      src={convo?.customerDetails?.companyLogo}
                                      alt={`avatar`}
                                      className="w-auto h-6 mr-6 rounded-full"
                                    />
                                  ) : (
                                    <div
                                      className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                        convo?.customerDetails?.companyName
                                      )}`}
                                    >
                                      {convo?.customerDetails?.companyName
                                        ? convo.customerDetails.companyName
                                            .split(" ") // Split the name into words
                                            .filter(
                                              (word) => word.trim().length > 0
                                            ) // Ensure no empty strings are included
                                            .reduce(
                                              (acc, word, idx, arr) =>
                                                idx === 0 ||
                                                idx === arr.length - 1
                                                  ? acc + word[0]
                                                  : acc, // Take the first letter of the first and last words
                                              ""
                                            )
                                            .toUpperCase() // Convert to uppercase
                                        : "--"}
                                    </div>
                                  )}
                                  {convo?.customerDetails?.companyName || "---"}
                                </div>
                              </td>
                            )}

                            {columns.some(
                              (col) =>
                                col.name === "customer name" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  <div
                                    className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                      convo.ticketId?.name
                                    )}`}
                                  >
                                    {convo?.ticketId?.name
                                      ? convo?.ticketId?.name
                                          .split(" ") // Split the name into words
                                          .filter(
                                            (word) => word.trim().length > 0
                                          ) // Ensure no empty strings are included
                                          .reduce(
                                            (acc, word, idx, arr) =>
                                              idx === 0 ||
                                              idx === arr.length - 1
                                                ? acc + word[0]
                                                : acc, // Take the first letter of the first and last words
                                            ""
                                          )
                                          .toUpperCase() // Convert to uppercase
                                      : "--"}
                                  </div>
                                  {convo.ticketId?.name || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "customer email" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.ticketId?.email || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "created date" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.updatedAt
                                  ? new Date(
                                      convo.ticketId.createdAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.createdAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "resolved date" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.resolvedAt
                                  ? new Date(
                                      convo.ticketId.resolvedAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.resolvedAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "first response due" &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <SlaRelatedDetails
                                  selectedConversation={convo}
                                  isfirstResponseDue={true}
                                />
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "resolution due" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <SlaRelatedDetails
                                  selectedConversation={convo}
                                  isResolutionDue={true}
                                />
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "first response date" &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.firstResponseAt
                                  ? new Date(
                                      convo.ticketId.firstResponseAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.firstResponseAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}

                            {columns.some(
                              (col) =>
                                col.name === "sla breached" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.ticketId.slaBreached ? (
                                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                      Breached
                                    </span>
                                  ) : (
                                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                      Not breached
                                    </span>
                                  )}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "type" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.type || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "source" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo?.ticketId?.source || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "priority" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <span
                                  className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded ${
                                    convo.ticketId.priority === "Low"
                                      ? "bg-green-100 text-green-800"
                                      : convo.ticketId.priority === "Medium"
                                      ? "bg-blue-100 text-blue-800"
                                      : convo.ticketId.priority === "High"
                                      ? "bg-yellow-100 text-yellow-800"
                                      : convo.ticketId.priority === "Critical"
                                      ? "bg-red-100 text-red-800"
                                      : "bg-gray-100 text-gray-800"
                                  }`}
                                >
                                  {convo.ticketId.priority || "---"}
                                </span>
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "category" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.category || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "tags" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.tags || "---"}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {loading && (
                <div className="animate-pulse w-full p-4 overflow-y-auto max-h-[70vh]">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
              <nav
                className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500">
                  Showing{" "}
                  <span className="font-semibold text-gray-900">
                    {firstIndex + 1}-{Math.min(lastIndex, totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900">
                    {totalCount}
                  </span>
                </span>
                <ul className="inline-flex items-stretch -space-x-px">
                  {/* Previous Button */}
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handlePrevPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>

                  {/* Page Numbers */}
                  {(() => {
                    const paginationItems = [];
                    const delta = 2; // Range around the current page
                    const lastPage = totalPages;
                    const addPageItem = (page) => {
                      paginationItems.push(
                        <li className="cursor-pointer" key={page}>
                          <p
                            onClick={() => handlePageChange(page)}
                            className={`py-2 px-3 text-sm font-medium ${
                              currentPage === page
                                ? "bg-blue-600 text-white"
                                : "text-gray-500 bg-white hover:bg-gray-100"
                            } border border-gray-300`}
                          >
                            {page}
                          </p>
                        </li>
                      );
                    };

                    // Always show the first page
                    addPageItem(1);

                    // Add dots if there's a gap between first page and the start of middle range
                    if (currentPage - delta > 2) {
                      paginationItems.push(
                        <li key="left-dots">
                          <p className="py-2 px-3 text-sm text-gray-500">...</p>
                        </li>
                      );
                    }

                    // Add pages around the current page
                    for (
                      let i = Math.max(2, currentPage - delta);
                      i <= Math.min(lastPage - 1, currentPage + delta);
                      i++
                    ) {
                      addPageItem(i);
                    }

                    // Add dots if there's a gap between the end of middle range and last page
                    if (currentPage + delta < lastPage - 1) {
                      paginationItems.push(
                        <li key="right-dots">
                          <p className="py-2 px-3 text-sm text-gray-500">...</p>
                        </li>
                      );
                    }

                    // Always show the last page
                    if (lastPage > 1) addPageItem(lastPage);

                    return paginationItems;
                  })()}

                  {/* Next Button */}
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handleNextPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              {!loading && (
                <section className="bg-white">
                  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                      <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600">
                        Oops!
                      </h1>
                      <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
                        No Tickets Found
                      </p>
                      <p className="mb-4 text-lg font-light text-gray-500">
                        Sorry, we couldn't find any tickets matching your search
                        terms or filters. Try adjusting your filters or search
                        terms.
                      </p>
                      <p
                        onClick={handleClick}
                        className="inline-flex text-blue-500 bg-primary-600 cursor-pointer font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
                      >
                        clear filters
                      </p>
                    </div>
                  </div>
                </section>
              )}

              {loading && (
                <div className="animate-pulse w-full p-4">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {openForm.open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black z-50 bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <TicketExplorerViewEditor
              setShowForm={showModal}
              openForm={openForm}
              filters={showFilters}
              columns={columns}
              itemsPerPage={itemsPerPage}
              viewData={viewData}
              department={"ProductPro"}
            />
          </div>
        </div>
      )}

      {openTicketForm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <TicketForm
              setShowForm={showTicketForm}
              setConversations={setConversations}
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default TicketExplorer;
