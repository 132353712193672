import React, { useEffect, useState, useRef } from "react";
import "./AllUsers.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TbEdit } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import {
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Space,
  Select,
  Row,
  Switch,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Modal,
  Table,
  Typography,
  Drawer,
} from "antd";
import { useScrollTrigger } from "@mui/material";
import { render } from "@testing-library/react";
import Spinner from "../../../components/Full Page Spinner/fullpagespinner";
import InviteLoader from "./InviteLoader";
import useNotification from "../../../components/snackbars/SnackBar";
const { Option } = Select;

const AllUsers = () => {
  const [form] = Form.useForm();
  const [conf, setConf] = useNotification();
  const [searchQuery, setSearchQuery] = useState("");
  const [userData, setUserData] = useState(null);
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const [allResources, setAllResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState();
  const [partnerResources, setPartnerResources] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [localResources, setLocalResources] = useState(allResources);
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState(allUsers);
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invitationFlag, setInvitationFlag] = useState(false);
  const tempURL = window.location.origin;
  const [userInvitationData, setUserInvitationData] = useState({
    email: "",
    role: "Agent",
    inviter: "",
    inviterUserId: "",
    accessURL:
      tempURL === "https://client-admin-portal.vercel.app"
        ? "https://demo.dexkor.com"
        : tempURL,
    resources: [],
    status: "Pending",
  });
  const formRef = useRef(null);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    setHasAccess(data.role);
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userData) {
      setUserInvitationData((prevData) => ({
        ...prevData,
        inviter: userData.name,
        inviterUserId: userData._id,
      }));
      setInvitationFlag(false);
    }
  }, [userData, invitationFlag]);

  const handleOk = () => {
    setOpenEditDrawer(true);
    setInvitationFlag(true);
  };
  const handleCancel = () => {
    setOpenInviteUserModal(false);
    formRef.current.resetFields();
    setInvitationFlag(true);
  };

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const showEditDrawer = (record) => {
    setSelectedUserData(record);
    form.setFieldsValue(record);
    setOpenEditDrawer(true);
  };

  const onClose = () => {
    setOpenEditDrawer(false);
  };

  const handleResendInvite = async (record) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    setLoading(true); // Show spinner
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/resend-invite/${record.uniquePartnerId}`,
        {
          email: record.email,
          role: record.role,
          accessURL: window.location.origin,
          inviter: userInfo.name,
          inviterUserId: userInfo._id,
          resources: record.resources,
          status: record.status,
        }
      );
      setConf({ msg: "Invitation Resend Successfully", variant: "success" });
    } catch (error) {
      setConf({ msg: "Invitation Resend Failed", variant: "error" });
    } finally {
      setLoading(false); // Hide spinner after the request
    }
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleToggleResource = (resourceName, checked) => {
    setAllResources((prevResources) =>
      prevResources.map((resource) =>
        resource.name === resourceName
          ? { ...resource, enabled: checked } // Update the enabled state
          : resource
      )
    );
  };

  const ResourcesColumn = [
    {
      title: "Resource name",
      dataIndex: "name",
      key: "resource",
      width: "80%",
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (_, record) => (
        <div key={record.name}>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.enabled === true} // Ensure it's treated as a boolean
            onChange={(checked) => handleToggleResource(record.name, checked)} // Handle local state change
            disabled={record.isDisabled} // Disable if the user cannot modify the resource
          />
        </div>
      ),
    },
  ];
  const filteredUsers =
    searchQuery.trim() === ""
      ? allUsers
      : allUsers.filter((users) => {
          const searchQueryLower = searchQuery.toLowerCase();
          return (
            (users.name &&
              users.name.toLowerCase().includes(searchQueryLower)) ||
            (users.email &&
              users.email.toLowerCase().includes(searchQueryLower))
          );
        });

  const handleSendInvite = async (e) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (!userInvitationData.email) {
      setConf({ msg: "Please enter the email", variant: "warning" });
      return;
    }

    if (userInfo) {
      if (
        !userInvitationData.inviter ||
        !userInvitationData.inviterUserId ||
        !userInvitationData.accessURL
      ) {
        setConf({ msg: "Please refresh the page", variant: "warning" });
        return;
      }

      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/invite-user/${userInfo.uniquePartnerId}`,
          userInvitationData
        );

        // After success
        setLoading(false);
        setConf({ msg: "Invite Sent Successfully", variant: "success" });
        setOpenInviteUserModal(false);
        form.resetFields();
      } catch (error) {
        setLoading(false);

        if (error.response?.status === 409) {
          setConf({
            msg: "User verification is already pending",
            variant: "warning",
          });
        }
        if (error.response?.data?.status === 403) {
          setConf({
            msg: "User Already Exist",
            variant: "warning",
          });
        } else {
          setConf({
            msg: "Please Enter the Correct Details",
            variant: "warning",
          });
        }

        setOpenInviteUserModal(false); // Close modal even on error
        form.resetFields();
      }
    }
  };

  const handleUpdateUser = async (resourceName, checked) => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userInfo) {
      try {
        const values = await form.validateFields(); // Validate form fields and get values
        const { name, email, role, status } = values; // Destructure the values

        // Prepare data for update
        const updateData = {
          name,
          email,
          role,
          status,
          resources: allResources,
        };

        // Send update request to the API
        const response = await axios.put(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/update-user/${userInfo.uniquePartnerId}/${email}`,
          updateData
        );

        // Update the users state with the new user data
        setAllUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.email === email ? { ...user, ...updateData } : user
          )
        );

        // Handle success response
        onClose(); // Close the drawer after update
      } catch (error) {
        // Handle error response
        console.error("Update failed:", error);
      }
    }
  };

  useEffect(() => {
    const getResources = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      const token = localStorage.getItem("dexkorUserToken");

      if (userInfo && token) {
        try {
          // Fetch partner resources
          const partnerResponse = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/partners/${userInfo.uniquePartnerId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Role: `${userInfo.role}`,
              },
            }
          );

          const enabledPartnerResources =
            partnerResponse.data.partner.resources.filter(
              (resource) => resource.enabled === true
            );

          setPartnerResources(enabledPartnerResources);

          // Fetch user-specific resources
          const userResourcesResponse = await axios.get(
            `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${userInfo.uniquePartnerId}?query=${selectedUserData.email}`
          );

          const userResources = userResourcesResponse.data[0].resources;

          // Step 1: Filter partner's enabled resources only

          // Step 2: Map user's access (enabled state) to partner's enabled resources
          const updatedResources = enabledPartnerResources.map(
            (partnerResource) => {
              // Find matching resource in userResources
              const userResource = userResources.find(
                (userRes) => userRes.name === partnerResource.name
              );

              return {
                ...partnerResource,
                enabled: userResource ? userResource.enabled === true : false, // Ensure enabled is boolean
              };
            }
          );

          setAllResources(updatedResources); // Update state with filtered and mapped resources
        } catch (error) {
          console.error("Error fetching resources:", error);
        }
      }
    };

    getResources();
  }, [selectedUserData]);

  const columns = [
    {
      title: "Full name",
      dataIndex: "name",
      width: "20%",
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
      editable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      width: "20%",
      editable: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      with: "20%",
      render: (_, record) => {
        return (
          <div className="flex">
            <span>
              {record.status === "Pending" ? (
                <Button
                  onClick={() => handleResendInvite(record)}
                  disabled={
                    hasAccess !== "Admin" && hasAccess !== "Super admin"
                  } // Disable the button if hasAccess is not "Admin"
                >
                  Resend Invite
                </Button>
              ) : (
                <Button
                  onClick={() => showEditDrawer(record)}
                  disabled={
                    hasAccess !== "Admin" && hasAccess !== "Super admin"
                  } // Disable the button if hasAccess is not "Admin"
                >
                  <TbEdit /> Edit
                </Button>
              )}
            </span>
          </div>
        );
      },
    },
  ];

  // Fetch Users Effect
  useEffect(() => {
    const fetchAllUsers = async () => {
      const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`
        );
        setAllUsers(response.data); // Update users state with the latest data
        setInvitationFlag(false); // Reset flag after fetching
      } catch (error) {
        console.error("Error fetching users:", error);
        setInvitationFlag(false);
      }
    };

    fetchAllUsers();
  }, [invitationFlag]);

  return (
    <>
      {loading ? (
        <Spinner size="large" />
      ) : (
        <div>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className="cursor-pointer">Users Management</span>
          </div>
          <div className="dexkor-user-invite-panel">
            <div
              className="flex justify-between items-center py-5 px-5"
              style={{ width: "100%" }}
            >
              <div>
                <Input
                  style={{
                    width: "200%",
                    backgroundColor: "#F6F7FA",
                    height: "45px",
                  }}
                  placeholder="Search Users"
                  prefix={<SearchOutlined style={{ color: "#5F5F5F" }} />}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <div>
                <Button
                  size="large"
                  style={{ backgroundColor: "#2A4DAD", color: "#fff" }}
                  onClick={() => {
                    setOpenInviteUserModal(true); // Open the modal
                    setInvitationFlag(true); // Set the invitation flag
                  }}
                  disabled={
                    hasAccess !== "Admin" && hasAccess !== "Super admin"
                  }
                >
                  Invite User
                </Button>
              </div>
            </div>
            <div className="px-5 dexkor-user-setup-all-user">
              <Form form={form} component={false}>
                <Table
                  columns={columns}
                  bordered
                  dataSource={filteredUsers}
                  pagination={{
                    position: ["bottomCenter"], // Center pagination at the bottom
                  }}
                />
              </Form>
            </div>
          </div>
          <Drawer
            title="Update User"
            width={720}
            onClose={onClose}
            open={openEditDrawer}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleUpdateUser} type="primary">
                  Update
                </Button>
              </Space>
            }
          >
            <Form form={form} layout="vertical" hideRequiredMark>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="name" label="Full Name">
                    <Input placeholder="Please enter user name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter email",
                      },
                    ]}
                  >
                    <Input
                      // style={{
                      //   width: '100%',
                      // }}
                      placeholder="Please enter email"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Role",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select a Role"
                      style={{ height: "50px" }}
                    >
                      <Option value="Admin">Admin</Option>
                      <Option value="User">User</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[
                      {
                        required: true,
                        message: "Please choose the status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please choose the status"
                      style={{ height: "50px" }}
                    >
                      <Option value="Active">Active</Option>
                      <Option value="Disabled">Disabled</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <div>Resources Action</div>
                <div className="flex justify-center pt-5 w-full">
                  <Table
                    columns={ResourcesColumn}
                    dataSource={allResources}
                    style={{ width: "100%" }}
                  />
                </div>
              </Row>
            </Form>
          </Drawer>
          <Modal
            open={openInviteUserModal}
            title="Invite User"
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}
            validateMessages={validateMessages}
          >
            <Form
              form={form}
              onFinish={handleSendInvite}
              onFinishFailed={(errorInfo) => {
                // Handle validation errors, but don't close the modal
                console.log("Validation failed:", errorInfo);
              }}
              layout="vertical"
              ref={formRef}
              hideRequiredMark
            >
              {/* Customer Email */}
              <Row style={{ alignItems: "center", paddingTop: "10px" }}>
                <Col span={8}>
                  <Form.Item>
                    <p>User Email:</p>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please enter a valid email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="User Email"
                      value={userInvitationData.email}
                      onChange={(e) =>
                        setUserInvitationData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }}>
                <Col span={8} className="mb-5">
                  <p>Role:</p>
                </Col>
                <Col span={16}>
                  <Form.Item name="role">
                    <Select
                      defaultValue={userInvitationData.role}
                      style={{ width: "100%", height: 50 }}
                      onChange={(value) =>
                        setUserInvitationData((prev) => ({
                          ...prev,
                          role: value,
                        }))
                      }
                      options={[
                        { value: "Admin", label: "Admin" },
                        { value: "Agent", label: "Agent" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }}>
                <Col span={8} className="mb-5">
                  <p>Module:</p>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="resources"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one resource!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      className="custom-select"
                      style={{ width: "100%" }}
                      onChange={(values) =>
                        setUserInvitationData((prev) => ({
                          ...prev,
                          resources: values.map((value) => ({
                            name: value,
                            enabled: true,
                          })),
                        }))
                      }
                      options={partnerResources.map((resource) => ({
                        value: resource.name,
                        label: resource.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="flex items-center justify-center pt-5">
                {loading ? (
                  <InviteLoader />
                ) : (
                  <Button size="large" type="primary" htmlType="submit">
                    Send Invite
                  </Button>
                )}
              </div>
            </Form>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AllUsers;
