import { SET_TICKET_STATUSES } from "../actions/ticketStatusAction";

const initialState = {
  DPT1001: {}, //HelpDesk
  DPT1002: {}, //ProductPro
  DPT1003: {}, //DevCore
};

const ticketStatusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TICKET_STATUSES:
      const mappedStatuses = action.payload.reduce((acc, item) => {
        acc[item.departmentCode] = item; // Use departmentCode as the key
        return acc;
      }, {});
      const newState = {
        ...state,
        ...mappedStatuses,
      };
      return newState;

    default:
      return state;
  }
};

export default ticketStatusesReducer;
