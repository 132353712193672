import React, { useEffect, useState } from "react";
import axios from "axios";
import IssueLoaderButton from "./IssueLoaderButton";
import { useDispatch } from "react-redux";
import {
  addCampaignExplorerView,
  updateCampaignExplorerView,
} from "../../../../Redux/actions/campaignExplorerView";
import { useNavigate } from "react-router-dom";

const CampaignForm = ({
  setShowForm,
  selectedContacts,
  openForm,
  filters,
  itemsPerPage,
  columns,
  viewData,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    filters: [],
    allLeads: selectedContacts
  });
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        userId: userData._id,
      }));
    }
    if (filters) {
      const activeFilters = filters.filter((filter) => filter.isActive); // Only include active filters
      setFormData((prevData) => ({
        ...prevData,
        filters: activeFilters,
      }));
    }
  }, [userData, filters]);

  // Fetch user data from localStorage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (data) setUserData(data);

    if (openForm.update) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        title: viewData?.title || "",
        description: viewData?.description || "",
        startDate: viewData?.startDate || "",
        endDate: viewData?.endDate || "",
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userToken = localStorage.getItem("dexkorUserToken");

    try {
      if (openForm.update) {
        const response = await axios.patch(
          `${process.env.REACT_APP_CONVERSATION}/campaign-explorer/update/${viewData._id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              Role: `${userData?.role}`,
              "Content-Type": "application/json",
            },
          }
        );

        setShowForm();
        dispatch(updateCampaignExplorerView(response.data));

        const currentPath = window.location.pathname;
        const newSegment = response.data.title
          .replace(/\s+/g, "-")
          .toLowerCase();
        const updatedPath = currentPath.replace(/\/[^/]*$/, `/${newSegment}`);
        navigate(updatedPath);

        setLoading(false);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/campaign-explorer/create`,
          { ...formData, userId: userData?._id },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              Role: `${userData?.role}`,
              "Content-Type": "application/json",
            },
          }
        );

        setShowForm();
        dispatch(addCampaignExplorerView(response.data));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while submitting the form:", error);
      setLoading(false);
    }
  };

  return (
    <div className="p-4 h-full mt-0 dexkor-scrollable-form">
      <h2 className="dexkor-issue-header">
        {openForm.update ? "Update" : "Create New"} Campaign
      </h2>
      <form className="space-y-5 mt-10" onSubmit={handleSubmit}>
        <div className="dexkor-form-grid">
          {/* Title */}
          <div className="mb-4 dexkor-form-full-width">
            <label
              htmlFor="title"
              className="block mb-1 text-sm font-medium text-gray-900"
            >
              Title
            </label>
            <input
              id="name"
              name="name"
              value={formData.title}
              onChange={handleChange}
              placeholder="Campaign Name"
              className="dexkor-form-input"
              required
            />
          </div>

          {/* Description */}
          <div className="mb-4 dexkor-form-full-width">
            <label
              htmlFor="description"
              className="block mb-1 text-sm font-medium text-gray-900"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Campaign Description"
              className="dexkor-form-input-textarea"
              rows={3}
              required
            />
          </div>

          {/* Start Date */}
          <div className="mb-4 dexkor-form-half-width">
            <label
              htmlFor="startDate"
              className="block mb-1 text-sm font-medium text-gray-900"
            >
              Start Date
            </label>
            <input
              id="startDate"
              name="startDate"
              type="date"
              value={formData.startDate}
              onChange={handleChange}
              className="dexkor-form-input"
              required
            />
          </div>

          {/* End Date */}
          <div className="mb-4 dexkor-form-half-width">
            <label
              htmlFor="endDate"
              className="block mb-1 text-sm font-medium text-gray-900"
            >
              End Date
            </label>
            <input
              id="endDate"
              name="endDate"
              type="date"
              value={formData.endDate}
              onChange={handleChange}
              className="dexkor-form-input"
              required
            />
          </div>
        </div>
        <p>
          Selected Leads: {selectedContacts.length}
        </p>

        <div className="flex justify-end space-x-6">
          {loading ? (
            <IssueLoaderButton />
          ) : (
            <>
              <button
                onClick={() => setShowForm()}
                className="dexkor-issue-cancel-button flex items-center justify-center"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="dexkor-issue-submit-button flex items-center justify-center"
              >
                Submit
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default CampaignForm;
