import axios from "axios";

export const SET_PARTNER_SETUP_DETAILS = "SET_PARTNER_SETUP_DETAILS";
export const SET_PARTNER_DETAILS = "SET_PARTNER_DETAILS";

export const setPartnerSetupDetails = (details) => ({
  type: SET_PARTNER_SETUP_DETAILS,
  payload: details,
});

export const setPartnerDetails = (partnerDetails) => ({
  type: SET_PARTNER_DETAILS,
  payload: partnerDetails,
});

export const fetchPartnerSetupDetails =
  (uniquePartnerId) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CONVERSATION}/partners/setup/${uniquePartnerId}`
      );

      const details = response.data.data || {};
      const partnerDetails = response.data.partnerDetails || {};
      dispatch(setPartnerSetupDetails(details));
      dispatch(setPartnerDetails(partnerDetails));
    } catch (error) {
      console.error("Error fetching ticket explorer views:", error);
    }
  };
