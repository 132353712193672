import React from "react";
import { Route } from "react-router-dom";
import { SelectedTicketProvider } from "../Ticket/SelectedTicketContext";
import AppBar from "../AppBar/AppBar";
import Register from "../RegisterPage/Register";
import Home from "../Home/Home";
import CustomerOTP from "../RegisterPage/CustomerOTP";
import Login from "../Login with OTP/Login";
import MenuBar from "../MenuBar/Menu";
import CustomerPrivateRoute from "../../Private Route/CustomerPrivateRoute";
import Ticket from "../Ticket/Ticket";
import NewTicket from "../New Ticket Create/NewTicket";
import BadPage from "../BadPage/BadPage";
import Profile from "../Profile/Profile";

const CustomerPortalRoute = () => {
  return (
    <>
      <Route path="/customer-portal-setup/:token" element={<Register />} />
      <Route path="/portal/login" element={<Login />} />
      <Route path="/portal" element={<CustomerPrivateRoute><AppBar /></CustomerPrivateRoute>}> 
        <Route path="home" element={<SelectedTicketProvider><MenuBar /></SelectedTicketProvider>}>
          <Route index element={<Home />} />
          <Route path="new-ticket-create" element={<NewTicket />} />
          
          <Route path=":ticketId" element={<Home />} />
          <Route path=":ticketId/:ticketNumber" element={<Ticket />} />
        </Route>
      </Route>
      <Route path="/portal" element={<CustomerPrivateRoute><AppBar /></CustomerPrivateRoute>}> 
      <Route path="home/profile" element={<Profile />} />
      </Route>
      <Route path="*" element={<BadPage />} />
      <Route path="/portal/otp-verify" element={<CustomerOTP />} />
    </>
  );
};

export default CustomerPortalRoute;
