import React from "react";
import Frame from "../Images/Frame.jpg";

const TalentPulse = () => {
  return (
    <div style={{ height: "calc(100vh - 10%)" }}>
      <div
        style={{ paddingTop: "10%", display: "flex", justifyContent: "center" }}
      >
        <img src={Frame} alt="frame-img" />
      </div>
      <div>
        <p
          style={{
            fontSize: "23px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
            fontFamily: "roboto",
          }}
        >
          Introducing Your DexKor TalentPulse: Coming Soon...
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            textAlign: "center",
            paddingTop: "10px",
            marginLeft: "auto",
            fontFamily: "roboto",
            marginRight: "auto",
            width: "60%", // Adjust this value to control the width of the paragraph
          }}
        >
          A dynamic people management module offering HR departments valuable
          insights into employee performance and engagement, helping to reduce
          turnover and improve workforce management.
        </p>
      </div>
      {/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
    <Button style={{fontFamily: 'roboto'}} type='primary' size='large' onClick={() => navig('/help-desk/inbox')}>Get Started</Button>
</div> */}
    </div>
  );
};

export default TalentPulse;
