import React, { useEffect, useState } from "react";
import axios from "axios";
import IssueLoaderButton from "./IssueLoaderButton";
import { useDispatch } from "react-redux";
import {
    addLeadExplorerView,
  updateLeadExplorerView,
} from "../../../../Redux/actions/leadExplorerViewAction";
import { useNavigate } from "react-router-dom";

const LeadExplorerViewForm = ({
  setShowForm,
  openForm,
  filters,
  itemsPerPage,
  columns,
  viewData,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    isActive: true,
    userId: null, // Initialize with null, updated when userData is set
    filters: [],
    itemsPerPage,
    columns,
  });
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch user data from localStorage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (data) setUserData(data);
    if (openForm.update) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: viewData?.name || "",
        description: viewData?.description || "",
      }));
    }
  }, []);

  // Update formData when userData or filters change
  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        userId: userData._id,
      }));
    }
    if (filters) {
      const activeFilters = filters.filter((filter) => filter.isActive); // Only include active filters
      setFormData((prevData) => ({
        ...prevData,
        filters: activeFilters,
      }));
    }
  }, [userData, filters]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (openForm.update) {
      setLoading(true);
      const userToken = localStorage.getItem("dexkorUserToken");
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_CONVERSATION}/lead-explorer/update/${viewData._id}`,
          { name: formData.name, description: formData.description },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              Role: `${userData.role}`,
              "Content-Type": "application/json",
            },
          }
        );

        setShowForm();
        // Dispatch the add action
        dispatch(updateLeadExplorerView(response.data));
        const currentPath = window.location.pathname; // Get the current path
        const newSegment = response.data.name
          .replace(/\s+/g, "-")
          .toLowerCase(); // Transform the new segment

        // Replace the last segment of the URL
        const updatedPath = currentPath.replace(/\/[^/]*$/, `/${newSegment}`);

        navigate(updatedPath); // Navigate to the updated path
        setLoading(false);
      } catch (error) {
        console.error("Error occurred while submitting form:", error);
        setLoading(false);
      }
    } else {
      setLoading(true);
      const userToken = localStorage.getItem("dexkorUserToken");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/lead-explorer/create`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              Role: `${userData.role}`,
              "Content-Type": "application/json",
            },
          }
        );

        setShowForm();
        // Dispatch the add action
        dispatch(addLeadExplorerView(response.data));
        setLoading(false);
      } catch (error) {
        console.error("Error occurred while submitting form:", error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="p-4 h-full mt-0 dexkor-scrollable-form">
      <h2 className="dexkor-issue-header">{openForm.update ? "Update" : "Create New"} Lead Explorer View</h2>
      <form className="space-y-5 mt-10" onSubmit={handleSubmit}>
        <div className="dexkor-form-grid">
          {/* Full Name */}
          <div className="mb-4 dexkor-form-full-width">
            <label
              for="name"
              class="block mb-1 text-sm font-medium text-gray-900"
            >
              Name
            </label>
            <input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name of the view"
              className="dexkor-form-input"
              required
            />
          </div>

          {/* Description (Full-width) */}
          <div className="mb-4 dexkor-form-full-width">
            <label
              for="name"
              class="block mb-1 text-sm font-medium text-gray-900"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Write description."
              className="dexkor-form-input-textarea"
              rows={2}
              required
            />
          </div>
        </div>

        <div className="flex justify-end space-x-6">
          {loading ? (
            <IssueLoaderButton />
          ) : (
            <>
              <button
                onClick={() => {
                  setShowForm();
                }}
                className="dexkor-issue-cancel-button flex items-center justify-center"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="dexkor-issue-submit-button flex items-center justify-center"
              >
                Submit
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default LeadExplorerViewForm;
