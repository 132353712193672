import axios from "axios";

export const SET_TICKET_STATUSES = "SET_TICKET_STATUSES";

export const setTicketStatuses = (statuses) => ({
  type: SET_TICKET_STATUSES,
  payload: statuses,
});

export const fetchTicketStatuses =
  (userRole, userToken) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/tickets/get/status`,
        {
          departmentCodes: ["DPT1001", "DPT1002", "DPT1003"],
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Role: `${userRole}`,
            "Content-Type": "application/json",
          },
        }
      );
      const statuses = response.data || [];
      dispatch(setTicketStatuses(statuses));
    } catch (error) {
      console.error("Error fetching ticket statuses:", error);
    }
  };
