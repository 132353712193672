import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import LeadViewer from "./components/LeadViewer/LeadViewer";
import { RxCross1 } from "react-icons/rx";
import { RiChatNewFill } from "react-icons/ri";
import useNotification from "../../components/snackbars/SnackBar";
import { MdSnooze, MdOutlineEdit } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import ContactForm from "./components/Form/NewContactForm";
import LeadExplorerViewForm from "./components/Form/leadExplorerForm";
import CampaignForm from "./components/Form/newCampaignForm";

function LeadExplorer({ viewData }) {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [addFlag, setAddFlag] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [filterReady, setFilterReady] = useState(false); // New state to track filter readiness
  const [keyword, setKeyword] = useState("");
  const [openForm, setOpenForm] = useState({ open: false, update: false });
  const [openTicketForm, setOpenTicketForm] = useState(false);
  const [openCampaignForm, setOpenCampaignForm] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showSaveButton, setShowSaveButton] = useState(null); // New state to control save button visibility
  const [showFilters, setShowFilters] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Define how many items per page
  const [showColumnDropDown, setShowColumnDropDown] = useState(false);
  const [showActionsDropDown, setShowActionsDropDown] = useState(false);
  const [showOwnerDropdown, setShowOwnerDropDown] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropDown] = useState(false);
  const [page, setPage] = useState(1); // Track the current page
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true); // Whether more users are available
  const [limit, setLimit] = useState(4); // Number of users per page
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOwner, setSelectedOwner] = useState({ name: "Select" });
  const [selectedAssignee, setSelectedAssignee] = useState({ name: "Select" });
  const [userData, setUserData] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(
    Math.ceil(contacts.length / itemsPerPage)
  );
  const [totalCount, setTotalCount] = useState(contacts.length);
  const [debouncedKeyword, setDebouncedKeyword] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [columns, setColumns] = useState(viewData?.columns);
  const navigate = useNavigate();

  const { ticketNumber } = useParams(); // Access the ticket number from the URL

  const subStages = [
    { key: "unaware", value: "Unaware" },
    { key: "problem-aware", value: "Problem Aware" },
    { key: "solution-aware", value: "Solution Aware" },
    { key: "brand-aware", value: "Brand Aware" },
    { key: "engaged", value: "Engaged" },
    { key: "nurtured-lead", value: "Nurture Lead" },
    { key: "product-aware", value: "Product Aware" },
    { key: "qualified-lead", value: "Qualified Lead" },
  ];

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allContacts = contacts.map((contact) => contact);
      setSelectedContacts(allContacts);
    } else {
      setSelectedContacts([]);
    }
  };

  const handleCheckboxChange = (contact) => {
    setSelectedContacts((prevSelected) => {
      if (prevSelected.includes(contact)) {
        return prevSelected.filter((item) => item !== contact);
      }
      return [...prevSelected, contact];
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyword.trim() !== "") {
        // Replace multiple spaces with a single space and trim any leading/trailing spaces
        setDebouncedKeyword(keyword.trim().replace(/\s+/g, " "));
      } else {
        setDebouncedKeyword(""); // Clear if the input is empty or just spaces
      }
    }, 500); // 1-second debounce delay

    return () => {
      clearTimeout(timer); // Cleanup timer on unmount or keyword change
    };
  }, [keyword]);

  const showModal = () => {
    setOpenForm((prevState) => ({
      ...prevState, // Preserve the other keys in the object
      update: prevState.open ? false : prevState.update,
      open: !prevState.open, // Toggle the `open` key
    }));
  };

  const showTicketForm = () => {
    setOpenTicketForm(!openTicketForm);
  };

  const showCampaignForm = () => {
    setOpenCampaignForm(!openCampaignForm);
  };

  const [filters, setFilters] = useState({});
  // Helper function to initialize showFilters based on viewData.filters
  const initializeFilters = (defaultFilters, viewFilters) => {
    return defaultFilters.map((filter) => {
      // Check if a matching filter (ignoring case) exists in viewFilters
      const match = viewFilters.find(
        (viewFilter) =>
          viewFilter.name.toLowerCase() === filter.name.toLowerCase()
      );

      // If a match is found, set `isActive` to true and copy the value
      return match ? { ...filter, isActive: true, value: match.value } : filter;
    });
  };

  // Helper function to compare initial viewData.filters with current filters
  const areFiltersDifferent = (
    initialFilters,
    currentFilters,
    initialColumns,
    currentColumns
  ) => {
    // Create a map of initialFilters with case-insensitive keys (lowercase names)
    const initialFilterValues = initialFilters.reduce((acc, filter) => {
      // Use toLowerCase for both name and value to ensure case-insensitive comparison
      acc[filter.name.toLowerCase()] = filter.value
        ? filter.value.toLowerCase()
        : null; // Store null if value is null
      return acc;
    }, {});

    // Create a map of currentFilters with case-insensitive keys (lowercase names)
    const currentFilterValues = Object.keys(currentFilters).reduce(
      (acc, key) => {
        // Normalize key to lowercase for case-insensitive comparison
        // If the key is 'slaBreaches', treat it as 'sla'
        const normalizedKey = key === "slaBreached" ? "sla" : key.toLowerCase();
        // Normalize value to lowercase and store null if value is null
        acc[normalizedKey] = currentFilters[key]
          ? currentFilters[key].toLowerCase()
          : null;
        return acc;
      },
      {}
    );

    // Compare both maps to check for any differences
    for (const key in initialFilterValues) {
      if (initialFilterValues[key] !== currentFilterValues[key]) {
        return true; // Found a difference
      }
    }

    // Also check if there are extra filters in currentFilters that don't exist in initialFilters
    if (filterCount !== viewData.filters.length) return true;

    // Filter columns based on isActive
    const activeInitialColumns = initialColumns.filter((col) => col.isActive);
    const activeCurrentColumns = currentColumns.filter((col) => col.isActive);

    // Check if the number of active columns is different
    if (activeInitialColumns.length !== activeCurrentColumns.length) {
      return true;
    }

    // Compare active columns for differences
    for (let i = 0; i < activeInitialColumns.length; i++) {
      if (activeInitialColumns[i].name !== activeCurrentColumns[i].name) {
        return true; // Found a difference in active column names
      }
    }

    return false; // No differences found
  };

  // Effect to set visibility of the save button based on filter changes
  useEffect(() => {
    const isDifferent = areFiltersDifferent(
      viewData.filters || [],
      filters,
      viewData.columns || [],
      columns
    );
    setShowSaveButton(isDifferent);
  }, [filters, viewData.filters, filterCount, columns]);

  // Inside the component
  const defaultFilters = [
    { name: "Stages", isActive: false, value: null },
    { name: "Sub Stages", isActive: false, value: null },
    { name: "Lead owner", isActive: false, value: null },
  ];

  useEffect(() => {
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setItemsPerPage(viewData.itemsPerPage);
    setColumns(viewData?.columns);
    setSelectedAssignee({ name: "Select" });
    setSelectedOwner({ name: "Select" });
  }, [viewData]);

  // Transform viewData.filters to set initial filters state, setting null values to ""
  const setInitialFilters = () => {
    // Reset filters to an empty state
    setFilters({});
    setFilterReady(true);
  };

  useEffect(() => {
    setInitialFilters();
  }, [viewData]);

  // Pagination logic
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentContacts = contacts.slice(firstIndex, lastIndex);

  useEffect(() => {
    const getAllContact = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

        let response;

        if (debouncedKeyword === "") {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/search/${userInfo.uniquePartnerId}?page=${currentPage}&limit=${itemsPerPage}`,
            { filters }
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/search/${userInfo.uniquePartnerId}?keyword=${debouncedKeyword}&page=${currentPage}&limit=${itemsPerPage}`,
            { filters }
          );
        }
        setLoading(false);
        const data = response.data.contacts;
        setContacts(data);
        setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
        setTotalCount(response.data.totalCount);
        setAddFlag(false);
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 404) {
          setContacts([]);
          setTotalPages(0);
          setTotalCount(0);
        }
        console.error(error);
      }
    };

    if (filterReady) {
      getAllContact();
    }
  }, [
    debouncedKeyword,
    currentPage,
    filters,
    itemsPerPage,
    filterReady,
    addFlag,
  ]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFilters = (name, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (value === null || value === "") {
        // If value is null or an empty string, remove the filter
        const { [name]: removed, ...remainingFilters } = newFilters;
        return remainingFilters; // Return the filters without the removed one
      }

      // Otherwise, update the filter state
      newFilters[name] = value;
      return newFilters;
    });

    setShowFilters((prevFilters) =>
      prevFilters.map((f) => {
        // Normalize the name to handle case-insensitive comparison
        const normalizedName = f.name.toLowerCase();
        const normalizedInputName =
          name === "slaBreached" ? "sla" : name.toLowerCase();

        // Update the filter if names match (considering case insensitivity)
        return normalizedName === normalizedInputName
          ? { ...f, value: value }
          : f;
      })
    );
  };

  const [rotate, setRotate] = useState(false); // State to track rotation

  const handleClick = () => {
    setRotate(!rotate); // Toggle rotation on every click
    // setFilters({});
    setInitialFilters();
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setKeyword("");
    setColumns(viewData.columns);
  };

  const handleItemsPerpage = (value) => {
    if (value === "" || value === null) {
      setItemsPerPage(25);
    } else setItemsPerPage(value);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const scrollDropDownRef = useRef(null);
  const columnDropdownRef = useRef(null);
  const actionsDropdownRef = useRef(null);
  const ownerDropdownRef = useRef(null);
  const assigneDropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    // Define an async function to filter all users
    const searchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${loggedInUserData.uniquePartnerId}`,
          {
            params: { query: searchQuery }, // Pass the query as a query parameter
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call the fetch function
    if (searchQuery !== "") {
      searchUsers();
    }
  }, [searchQuery]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionsDropdownRef.current &&
        !actionsDropdownRef.current.contains(event.target)
      ) {
        setShowActionsDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showActionsDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showActionsDropDown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setShowColumnDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnDropdownRef.current &&
        !columnDropdownRef.current.contains(event.target)
      ) {
        setShowColumnDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showColumnDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColumnDropDown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ownerDropdownRef.current &&
        !ownerDropdownRef.current.contains(event.target)
      ) {
        setShowOwnerDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showOwnerDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOwnerDropdown]);

  const handleToggle = (index) => {
    // Create a new array to avoid direct mutation
    const updatedColumns = columns.map((col, idx) =>
      idx === index ? { ...col, isActive: !col.isActive } : col
    );
    setColumns(updatedColumns); // Update state
  };

  // Fetch users based on page and limit
  const fetchUsers = async (reset = false) => {
    if (!hasMore || !loggedInUserData) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/chunks/${loggedInUserData.uniquePartnerId}?page=${page}&limit=${limit}`
      );
      const fetchedUsers = response.data.users;

      if (response.status === 204) {
        setHasMore(false); // Stop fetching when no more users are available
      } else {
        setUsers((prevUsers) =>
          reset ? fetchedUsers : [...prevUsers, ...fetchedUsers]
        );
        setPage((prevPage) => prevPage + 1); // Increment the page
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  // Fetch users when the dropdown is shown for the first time
  useEffect(() => {
    if (showOwnerDropdown || showAssigneeDropdown) {
      fetchUsers(true); // Fetch users for the first time or reset if dropdown is reopened
    }
  }, [showOwnerDropdown, showAssigneeDropdown]);

  // Infinite scroll logic for dropdown
  useEffect(() => {
    const dropdownElement = scrollDropDownRef.current; // Reference the dropdown element
    const handleScroll = () => {
      if (
        dropdownElement &&
        dropdownElement.scrollTop + dropdownElement.clientHeight >=
          dropdownElement.scrollHeight &&
        !loading
      ) {
        fetchUsers(); // Fetch more users on scroll
      }
    };

    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, page]);

  // Fetch logged-in user data
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userData) setLoggedInUserData(userData);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [viewData]);

  const [conf, setConf] = useNotification();

  return (
    <section className="bg-gray-50 py-1">
      <div className="px-1 mx-auto max-w-screen-2xl">
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <div className="flex flex-col px-2 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
            <div className="flex items-start">
              <h5 className="flex">
                <p
                  onClick={() => {
                    if (viewData?.userId !== userData?._id) {
                      // Check if the IDs match
                      setOpenForm((prevOpenForm) => ({
                        ...prevOpenForm,
                        update: true,
                      }));
                      showModal();
                    }
                  }}
                  className="flex items-center justify-center space-x-1 text-gray-500 justify-center cursor-pointer hover:bg-blue-600 hover:text-white px-2 py-1 text-xs font-medium rounded-lg border rounded-lg"
                >
                  <span>{viewData.name}</span>{" "}
                  {viewData?.userId !== userData?._id && <MdOutlineEdit />}{" "}
                </p>
                <span className="p-1">
                  {" : "}
                  {totalCount}
                </span>
              </h5>
            </div>
            {selectedContact && (
              <LeadViewer
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
              />
            )}

            {/* Middle container for the search input and dropdown actions */}
            <div className="flex flex-1 justify-center items-center h-8">
              <div className="flex items-center space-x-4 relative">
                <div className="w-full">
                  <input
                    type="text"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    id="simple-search"
                    className="bg-gray-50 z-0 border h-8 border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 pr-16"
                    placeholder="Search lead here"
                    style={{
                      fontSize: "13px",
                      minWidth: "5rem",
                      borderRadius: "100px",
                    }}
                    required=""
                  />
                </div>
                <div className="absolute right-2">
                  <CiSearch />
                </div>
              </div>
            </div>

            <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
              <button
                type="button"
                className={`flex items-center justify-center px-2 py-1 text-xs font-medium rounded-lg  text-white ${
                  showSaveButton
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "cursor-not-allowed text-white bg-blue-400 hover:bg-blue-400"
                }`}
                onClick={() => showModal()}
                disabled={!showSaveButton}
              >
                Save view
              </button>

              <button
                onClick={showTicketForm}
                className="text-white font-medium text-xs px-2 py-1 bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-lg text-center inline-flex items-center"
                type="button"
              >
                <RiChatNewFill className="mr-2" /> Create New Lead{" "}
              </button>
              <button
                onClick={showCampaignForm}
                className={`text-white font-medium text-xs px-2 py-1 ${
                  selectedContacts && selectedContacts.length > 0
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "bg-gray-400 cursor-not-allowed"
                } focus:outline-none rounded-lg text-center inline-flex items-center`}
                type="button"
                disabled={!selectedContacts || selectedContacts.length === 0}
              >
                <RiChatNewFill className="mr-2" /> Create Campaign
              </button>
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex space-x-2 pl-2" style={{ width: "70%" }}>
              {showFilters?.map((filter) => {
                if (filter.name === "Stages") {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p className="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className="whitespace-nowrap h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Stages
                        </p>
                        <label htmlFor="stage" className="sr-only">
                          Choose Stages
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("stage", e.target.value || null)
                          } // Handle select change
                          value={filters?.stage || ""} // Ensure correct binding to filters.stage
                        >
                          <option value="">Select</option>
                          <option value="awareness">Awareness</option>
                          <option value="interest">Interest</option>
                          <option value="consideration">Consideration</option>
                        </select>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Sub Stages" && filter.isActive) {
                  return (
                    <div className="flex w-48" key={filter.name}>
                      <p class="relative inline-flex p-0 text-center">
                        <p
                          id="states-button"
                          data-dropdown-toggle="dropdown-states"
                          className="whitespace-nowrap h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Sub Stages
                        </p>
                        <label for="states" className="sr-only">
                          Choose Sub Stages
                        </label>
                        <select
                          id="states"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
                          onChange={(e) =>
                            handleFilters("subStage", e.target.value || null)
                          } // Handle select change
                          value={filters.subStage || ""}
                        >
                          <option value="" selected>
                            select
                          </option>
                          {subStages.map((stage) => (
                            <option key={stage.key} value={stage.key}>
                              {stage.value}
                            </option>
                          ))}
                        </select>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("subStages", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                if (filter.name === "Lead owner" && filter.isActive) {
                  return (
                    <div className="flex" key={filter.name}>
                      <p class="relative inline-flex p-0">
                        <p
                          id="source-button"
                          data-dropdown-toggle="dropdown-states"
                          className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                          type="button"
                        >
                          Lead Owner{" "}
                        </p>
                        <div ref={ownerDropdownRef}>
                          <div
                            className="cursor-pointer h-8 rounded-e-lg text-xs text-center border inline-flex items-center p-2 bg-gray-100"
                            onClick={() => {
                              setShowOwnerDropDown(!showOwnerDropdown);
                              setPage(1);
                              setShowAssigneeDropDown(false);
                            }}
                          >
                            {selectedOwner?.name?.split(" ")[0]}
                            <svg
                              className="w-2 h-1.5 ms-3 tex-xs"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </div>

                          {showOwnerDropdown && !showAssigneeDropdown && (
                            <div className="flex justify-end">
                              <div className="absolute z-20 left-0 top-8 bg-white rounded-lg shadow w-60">
                                <ul
                                  id="dropdownUsers"
                                  className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                  aria-labelledby="dropdownUsersButton"
                                  ref={scrollDropDownRef}
                                >
                                  {users.length > 0 &&
                                    users.map((user, index) => (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          handleFilters(
                                            "leadOwner",
                                            user?._id || null
                                          );
                                          setShowOwnerDropDown(false);
                                          setSelectedOwner(user);
                                        }}
                                      >
                                        <p className="flex px-4 py-2 hover:bg-gray-100 text-wrapper">
                                          {user?.image ? (
                                            <img
                                              className="w-6 h-6 me-2 mb-3 rounded-full"
                                              src={user.image}
                                              alt="User image"
                                            />
                                          ) : (
                                            <div className="w-6 h-6 me-2 mb-3 rounded-full bg-gray-300 flex items-center justify-center text-white">
                                              {user?.name?.charAt(0)}
                                            </div>
                                          )}
                                          <span className="flex-1 overflow-hidden">
                                            <p className="text-sm font-medium text-gray-800 truncate">
                                              {user.name}
                                            </p>
                                            <span className="text-xs text-gray-600 truncate">
                                              {user.email}
                                            </span>
                                          </span>
                                        </p>
                                      </li>
                                    ))}
                                  {loading && (
                                    <li className="text-center">Loading...</li>
                                  )}
                                </ul>
                                <div className="flex items-center p-2 text-xs text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                                  <input
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Search user..."
                                    className="w-full h-6 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            setShowFilters((prevFilters) =>
                              prevFilters.map((f) =>
                                f.name === filter.name
                                  ? { ...f, isActive: !f.isActive }
                                  : f
                              )
                            );
                            setFilterCount(filterCount - 1);
                            handleFilters("leadOwner", null);
                          }}
                          class="absolute cursor-pointer inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-gray-400 p-0.5 border-2 border-white rounded-full -top-1.5 -end-1.5"
                        >
                          <RxCross1 />
                        </div>
                      </p>
                    </div>
                  );
                }
                return null;
              })}

              {filterCount !== showFilters.length && (
                <div className="flex" ref={dropdownRef}>
                  <div className="flex relative group w-full">
                    <button
                      id="dropdownBgHoverButton"
                      onClick={toggleDropdown}
                      className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-xl hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                      type="button"
                    >
                      +
                    </button>
                    <span className="absolute top-[-15px] left-7 w-full transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                      Add
                    </span>
                  </div>

                  {/* Dropdown menu, visible only if isDropdownOpen is true */}
                  {isDropdownOpen && (
                    <div
                      id="dropdownBgHover"
                      className="absolute z-20 w-60 mt-2 bg-white rounded-lg shadow"
                    >
                      <ul
                        className="p-3 space-y-1 text-sm text-gray-700"
                        aria-labelledby="dropdownBgHoverButton"
                      >
                        {showFilters.map((filter) => {
                          if (!filter.isActive) {
                            return (
                              <li>
                                <div
                                  onClick={() => {
                                    setShowFilters((prevFilters) =>
                                      prevFilters.map((f) =>
                                        f.name === filter.name
                                          ? { ...f, isActive: !f.isActive }
                                          : f
                                      )
                                    );
                                    setFilterCount(filterCount + 1);
                                  }}
                                  className="flex items-center p-2 rounded hover:bg-gray-100"
                                >
                                  <label
                                    htmlFor="checkbox-item-5"
                                    className="w-full ms-2 text-xs font-medium text-gray-900 rounded"
                                  >
                                    {filter.name}
                                  </label>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              <div
                className="flex items-center relative group mb-2 cursor-pointer"
                onClick={handleClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`size-5 transition-transform duration-500 ease-in-out ${
                    rotate ? "rotate-[-180deg]" : "rotate-0"
                  }`} // Rotate counterclockwise on click
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                {/* Label that appears with hover */}
                <span className="absolute top-[-15px] left-3 transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                  Reset
                </span>
              </div>
            </div>
            <div className="flex ml-auto mr-2 mb-2">
              <div className="w-36 h-8 ml-auto">
                <label for="underline_select" class="sr-only">
                  Choose tickets per page
                </label>
                <select
                  id="underline_select"
                  class="block px-2 py-1 w-full text-xs text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  onChange={(e) => handleItemsPerpage(e.target.value || null)} // Handle select change
                  value={itemsPerPage || 25}
                >
                  {viewData?.itemsPerPage !== 50 &&
                    viewData?.itemsPerPage !== 100 &&
                    viewData?.itemsPerPage !== 25 && (
                      <option value={viewData?.itemsPerPage} selected>
                        {viewData?.itemsPerPage} Ticket per page
                      </option>
                    )}
                  <option value={25} selected>
                    25 Tickets per page
                  </option>
                  <option value={50}>50 Tickets per page</option>
                  <option value={100}>100 Tickets per page</option>
                </select>
              </div>
              <div ref={columnDropdownRef}>
                <button
                  id="dropdownHelperButton"
                  data-dropdown-toggle="dropdownHelper"
                  className="text-white font-medium text-xs px-2 py-1 bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-lg text-center inline-flex items-center"
                  type="button"
                  onClick={() => setShowColumnDropDown(!showColumnDropDown)}
                >
                  Columns{" "}
                  <svg
                    class="w-2 h-2 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {/* <!-- Dropdown menu --> */}
                {showColumnDropDown && (
                  <div
                    id="dropdownHelper"
                    className="absolute z-20 w-60 mt-2 right-2 top-20 bg-white rounded-lg shadow divide-y divide-gray-100"
                    style={{ maxHeight: "300px", overflowY: "auto" }} // Set a fixed height and make it scrollable
                  >
                    <ul
                      className="p-3 space-y-1 text-sm text-gray-700"
                      aria-labelledby="dropdownHelperButton"
                    >
                      {columns?.map((col, index) => (
                        <li key={index} onClick={() => handleToggle(index)}>
                          <div className="flex p-2 rounded hover:bg-gray-100">
                            <div className="flex items-center h-5">
                              <input
                                id={`helper-checkbox-${index}`}
                                aria-describedby={`helper-checkbox-text-${index}`}
                                type="checkbox"
                                value=""
                                checked={col.isActive}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                              />
                            </div>
                            <div className="ms-2 text-sm">
                              <label
                                htmlFor={`helper-checkbox-${index}`}
                                className="font-medium text-gray-900"
                              >
                                <div>{col.name}</div>
                              </label>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {contacts.length > 0 ? (
            <>
              <div className="overflow-x-auto overflow-y-auto max-h-[80vh]">
              <table className="w-full text-sm text-left text-gray-500">
  <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 z-10">
    <tr>
      <th className="px-4 py-3 sticky left-0 z-20 bg-gray-50">
        <input
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
          onChange={(e) => handleSelectAll(e.target.checked)}
          checked={
            contacts.length > 0 &&
            selectedContacts.length === contacts.length
          }
        />
      </th>
      {columns
        .filter((col) => col.isActive)
        .map((col, index) => (
          <th
            key={index}
            className={`px-4 py-3 whitespace-nowrap ${
              col.name.toLowerCase() === "contact name"
                ? "sticky left-[40px] z-10 bg-gray-50"
                : ""
            }`}
          >
            {col.name}
          </th>
        ))}
    </tr>
  </thead>
  <tbody>
    {!loading &&
      contacts.length > 0 &&
      contacts.map((contact, index) => (
        <tr
          key={index}
          className={`border-b bg-white hover:bg-gray-100 ${
            selectedContacts.includes(contact) ? "bg-blue-50" : ""
          }`}
        >
          <td className="px-4 py-2 sticky left-0 z-10 bg-white hover:bg-gray-100">
            <input
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              checked={selectedContacts.includes(contact)}
              onChange={() => handleCheckboxChange(contact)}
            />
          </td>
          {columns
            .filter((col) => col.isActive)
            .map((col, colIndex) => (
              <td
                key={colIndex}
                onClick={() => {
                  setSelectedContact(contact);
                  setTimeout(() => {
                    navigate(`${contact?.contactId}`, {
                      state: { selectedContact: contact },
                    });
                  }, 0);
                }}
                className={`px-4 py-2 whitespace-nowrap cursor-pointer ${
                  col.name.toLowerCase() === "contact name"
                    ? "sticky left-[40px] z-0 bg-white hover:bg-gray-100 group-hover:bg-gray-100"
                    : ""
                }`}
              >
                {col.name.toLowerCase() === "contact name"
                  ? `${contact.firstName || ""} ${
                      contact.lastName || ""
                    }`.trim() || "---"
                  : col.name.toLowerCase() === "tags"
                  ? Array.isArray(contact[col.apiField])
                    ? contact[col.apiField].join(", ")
                    : "---"
                  : contact[col.apiField] || "---"}
              </td>
            ))}
        </tr>
      ))}
  </tbody>
</table>

              </div>
              {loading && (
                <div className="animate-pulse w-full p-4 overflow-y-auto max-h-[70vh]">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
              <nav
                className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500">
                  Showing{" "}
                  <span className="font-semibold text-gray-900">
                    {firstIndex + 1}-{Math.min(lastIndex, totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900">
                    {totalCount}
                  </span>
                </span>
                <ul className="inline-flex items-stretch -space-x-px">
                  {/* Previous Button */}
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handlePrevPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>

                  {/* Page Numbers */}
                  {(() => {
                    const paginationItems = [];
                    const delta = 2; // Range around the current page
                    const lastPage = totalPages;
                    const addPageItem = (page) => {
                      paginationItems.push(
                        <li className="cursor-pointer" key={page}>
                          <p
                            onClick={() => handlePageChange(page)}
                            className={`py-2 px-3 text-sm font-medium ${
                              currentPage === page
                                ? "bg-blue-600 text-white"
                                : "text-gray-500 bg-white hover:bg-gray-100"
                            } border border-gray-300`}
                          >
                            {page}
                          </p>
                        </li>
                      );
                    };

                    // Always show the first page
                    addPageItem(1);

                    // Add dots if there's a gap between first page and the start of middle range
                    if (currentPage - delta > 2) {
                      paginationItems.push(
                        <li key="left-dots">
                          <p className="py-2 px-3 text-sm text-gray-500">...</p>
                        </li>
                      );
                    }

                    // Add pages around the current page
                    for (
                      let i = Math.max(2, currentPage - delta);
                      i <= Math.min(lastPage - 1, currentPage + delta);
                      i++
                    ) {
                      addPageItem(i);
                    }

                    // Add dots if there's a gap between the end of middle range and last page
                    if (currentPage + delta < lastPage - 1) {
                      paginationItems.push(
                        <li key="right-dots">
                          <p className="py-2 px-3 text-sm text-gray-500">...</p>
                        </li>
                      );
                    }

                    // Always show the last page
                    if (lastPage > 1) addPageItem(lastPage);

                    return paginationItems;
                  })()}

                  {/* Next Button */}
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handleNextPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              {!loading && (
                <section className="bg-white">
                  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                      <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600">
                        Oops!
                      </h1>
                      <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
                        No Contacts Found
                      </p>
                      <p className="mb-4 text-lg font-light text-gray-500">
                        Sorry, we couldn't find any contacts matching your
                        search terms or filters. Try adjusting your filters or
                        search terms.
                      </p>
                      <p
                        onClick={handleClick}
                        className="inline-flex text-blue-500 bg-primary-600 cursor-pointer font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
                      >
                        clear filters
                      </p>
                    </div>
                  </div>
                </section>
              )}

              {loading && (
                <div className="animate-pulse w-full p-4">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {openForm.open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black z-50 bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <LeadExplorerViewForm
              setShowForm={showModal}
              openForm={openForm}
              filters={showFilters}
              columns={columns}
              itemsPerPage={itemsPerPage}
              viewData={viewData}
            />
          </div>
        </div>
      )}

      {openTicketForm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <ContactForm
              setShowForm={showTicketForm}
              setConversations={setContacts}
              setAddFlag={setAddFlag}
              addFlag={addFlag}
            />
          </div>
        </div>
      )}
      {openCampaignForm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <CampaignForm
              setShowForm={showCampaignForm}
              selectedContacts={selectedContacts}
              setAddFlag={setAddFlag}
              openForm={openForm}
              addFlag={addFlag}
              filters={showFilters}
              columns={columns}
              itemsPerPage={itemsPerPage}
              viewData={viewData}
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default LeadExplorer;
