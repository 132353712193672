import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { useSelector } from "react-redux";

const StatusDropDown = ({
  departmentCode,
  handleStatusChange,
  selectedConversation,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  useEffect(() => {
    if (selectedConversation) {
      setSelectedStatus(selectedConversation?.ticketId?.status);
    }
  }, [selectedConversation]);
  const ticketStatuses = useSelector(
    (state) => state.ticketStatuses[departmentCode]
  );

  const statusOptions = ticketStatuses?.groups?.map((group) => {
    return {
      key: group?._id,
      type: "group",
      label: group?.groupName,
      children: group?.statuses?.map((status) => {
        return {
          key: status?._id,
          label: status?.name,
          onClick: () => handleStatusChange(status?.name),
        };
      }),
    };
  });

  const items = statusOptions;

  return (
    <Dropdown
      menu={{
        items,
      }}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full p-2 mr-0 rounded-e-lg border-s-gray-100 border-s-2 focus:ring-blue-500 focus:border-blue-500 block"
      overlayClassName="max-h-[50vh] overflow-y-auto scrollbar-hide"
    >
      <a>
        <Space>
          {selectedStatus ? selectedStatus : "Select Status"}{" "}
          {/* Show the selected status */}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};
export default StatusDropDown;
