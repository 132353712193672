import React, { useState, useEffect } from "react";
import axios from "axios";
import useNotification from "../../../../components/snackbars/SnackBar";

const UpdateCoreValues = ({ onSelectCoreValues }) => {
  const [userData, setUserData] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [conf, setConf] = useNotification();
  const [coreValuesOptions, setCoreValuesOptions] = useState([
    { id: 1, name: "Integrity", background: "#E0EFFF", color: "#1A3D7A" }, // Blue
    { id: 2, name: "Innovation", background: "#FFE8E6", color: "#A94442" }, // Red
    { id: 3, name: "Teamwork", background: "#FFE6E6", color: "#A94442" }, // Red
    { id: 4, name: "Customer Focus", background: "#D4EDDA", color: "#155724" }, // Green
    { id: 5, name: "Collaboration", background: "#FFF3CD", color: "#856404" }, // Yellow
    { id: 6, name: "Excellence", background: "#D6D8FB", color: "#4A4A9F" }, // Indigo
    { id: 7, name: "Accountability", background: "#F1D4E5", color: "#800080" }, // Purple
    { id: 8, name: "Respect", background: "#FDE2E8", color: "#B565A7" }, // Pink
    { id: 9, name: "Sustainability", background: "#EAEAEA", color: "#444444" }, // Gray
  ]);

  const [newCoreValue, setNewCoreValue] = useState("");
  const [colorChoice, setColorChoice] = useState("#FFD966"); // Default to Yellow
  const [customColor, setCustomColor] = useState("");

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  const handleValueChange = (value) => {
    setSelectedValues((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const handleAddCoreValue = () => {
    if (newCoreValue.trim() !== "") {
      const newOption = {
        id: coreValuesOptions.length + 1,
        name: newCoreValue,
        background: colorChoice === "custom" ? customColor : colorChoice,
        color:
          colorChoice === "custom"
            ? adjustColorBrightness(customColor, -0.3)
            : adjustColorBrightness(colorChoice, -0.3),
      };
      setCoreValuesOptions((prevOptions) => [...prevOptions, newOption]);
      setNewCoreValue("");
      setColorChoice("#FFD966");
      setCustomColor("");
      setSelectedValues((prev) => [...prev, newOption.name]);
    }
  };

  const adjustColorBrightness = (color, percent) => {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent * 100);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
        .toUpperCase()
    );
  };

  const handleDeleteCoreValue = (id) => {
    const updatedOptions = coreValuesOptions.filter((value) => value.id !== id);
    setCoreValuesOptions(updatedOptions);
    setSelectedValues((prev) => prev.filter((value) => value !== id));
  };

  const updateCoreValuesInDatabase = async () => {
    const structuredValues = coreValuesOptions
      .filter((value) => selectedValues.includes(value.name))
      .map((value) => ({
        name: value.name,
        color: value.color,
        background: value.background,
      }));

    try {
      await axios.post(
        `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/setCoreValues`,
        {
          coreValues: structuredValues,
          partnerId: userData.uniquePartnerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setConf({
        msg: "Core Values updated successfully",
        variant: "success",
      });
      console.log("Core values updated successfully");
    } catch (error) {
      console.error("Error updating core values:", error);
    }
  };

  return (
    <div className="update-core-values p-6 bg-white rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Select Core Values
      </h2>
      <div className="values-list flex flex-wrap gap-4 mb-4">
        {coreValuesOptions.map((value) => (
          <div key={value.id} className="relative">
            <label
              className="value-option text-black font-medium p-3 rounded-md cursor-pointer flex items-center transition duration-200 transform hover:scale-105"
              style={{
                backgroundColor: value.background,
                color: value.color,
              }}
            >
              <input
                type="checkbox"
                value={value.name}
                checked={selectedValues.includes(value.name)}
                onChange={() => handleValueChange(value.name)}
                className="mr-2 rounded text-gray-800 focus:ring-offset-0 focus:ring-0"
              />
              {value.name}
            </label>
            <button
              onClick={() => handleDeleteCoreValue(value.id)}
              className="absolute -top-1 -right-2 bg-transparent hover:bg-transparent text-black rounded-full w-5 h-5 flex items-center justify-center text-xs"
            >
              ✕
            </button>
          </div>
        ))}
      </div>

      <div className="add-core-value-form bg-gray-100 p-4 rounded-lg mb-4">
        <input
          type="text"
          placeholder="New Core Value"
          value={newCoreValue}
          onChange={(e) => setNewCoreValue(e.target.value)}
          className="p-2 border border-gray-300 rounded-md w-full mb-2"
        />

        <select
          value={colorChoice}
          onChange={(e) => setColorChoice(e.target.value)}
          className="p-2 border border-gray-300 rounded-md w-full mb-2"
        >
          <option value="#FFD966">Soft Yellow</option>
          <option value="#FF8C69">Soft Red</option>
          <option value="#5D9BC6">Soft Blue</option>
          <option value="#FFB84D">Soft Orange</option>
          <option value="#66CCCC">Soft Cyan</option>
          <option value="#6A4C9C">Soft Indigo</option>
          <option value="#FF66B2">Soft Pink</option>
          <option value="#9B59B6">Soft Purple</option>
          <option value="#B0B0B0">Soft Gray</option>
          <option value="custom">Custom Color</option>
        </select>

        {colorChoice === "custom" && (
          <input
            type="color"
            value={customColor}
            onChange={(e) => setCustomColor(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        )}

        <button
          onClick={handleAddCoreValue}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md mt-2 hover:bg-blue-600 transition"
        >
          Add Core Value
        </button>
      </div>

      <button
        onClick={updateCoreValuesInDatabase}
        className="w-full bg-sky-800 text-white py-2 px-4 rounded-md mt-2 hover:bg-sky-600 transition"
      >
        Update Core Values
      </button>
    </div>
  );
};

export default UpdateCoreValues;
