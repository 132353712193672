import {
  SET_TICKET_EXPLORER_VIEWS,
  UPDATE_TICKET_EXPLORER_VIEW,
  ADD_TICKET_EXPLORER_VIEW,
} from "../actions/ticketExplorerViewActions";

const getDefaultViews = (currentUserInfo) => [
  {
    _id: 123445,
    name: "Sub Ticket Viewer",
    description: "View for All tickets",
    columns: [
      { name: "ticket", isActive: true },
      { name: "title", isActive: false },
      { name: "department", isActive: true },
      { name: "status", isActive: true },
      { name: "owner", isActive: true },
      { name: "assignee", isActive: true },
      { name: "last update", isActive: false },
      { name: "customer code", isActive: false },
      { name: "company name", isActive: false },
      { name: "customer name", isActive: false },
      { name: "customer email", isActive: false },
      { name: "created date", isActive: true },
      { name: "resolved date", isActive: false },
      { name: "first response due", isActive: false },
      { name: "resolution due", isActive: false },
      { name: "first response date", isActive: false },
      { name: "sla breached", isActive: false },
      { name: "type", isActive: false },
      { name: "source", isActive: false },
      { name: "priority", isActive: false },
      { name: "category", isActive: false },
      { name: "tags", isActive: false },
    ],
    filters: [],
    itemsPerPage: 25,
    isActive: true,
    isSubticketView: true,
    isDefault: false,
  },
  {
    _id: 123444,
    name: "All tickets",
    description: "View for All tickets",
    columns: [
      { name: "ticket", isActive: true },
      { name: "title", isActive: true },
      { name: "status", isActive: true },
      { name: "owner", isActive: true },
      { name: "assignee", isActive: true },
      { name: "last update", isActive: false },
      { name: "customer code", isActive: false },
      { name: "company name", isActive: false },
      { name: "customer name", isActive: false },
      { name: "customer email", isActive: false },
      { name: "created date", isActive: true },
      { name: "resolved date", isActive: false },
      { name: "first response due", isActive: false },
      { name: "resolution due", isActive: false },
      { name: "first response date", isActive: false },
      { name: "sla breached", isActive: false },
      { name: "type", isActive: false },
      { name: "source", isActive: false },
      { name: "priority", isActive: false },
      { name: "category", isActive: false },
      { name: "tags", isActive: false },
    ],
    filters: [
      {
        name: "Status",
        value: null, // The status filter with value UnAssigned
      },
    ],
    itemsPerPage: 25,
    isActive: true,
    isDefault: true,
  },
  {
    _id: 123443,
    name: "Unassigned tickets",
    description: "View for unassigned tickets",
    columns: [
      { name: "ticket", isActive: true },
      { name: "title", isActive: true },
      { name: "status", isActive: true },
      { name: "owner", isActive: true },
      { name: "assignee", isActive: true },
      { name: "last update", isActive: false },
      { name: "customer code", isActive: false },
      { name: "company name", isActive: false },
      { name: "customer name", isActive: false },
      { name: "customer email", isActive: false },
      { name: "created date", isActive: true },
      { name: "resolved date", isActive: false },
      { name: "first response due", isActive: false },
      { name: "resolution due", isActive: false },
      { name: "first response date", isActive: false },
      { name: "sla breached", isActive: false },
      { name: "type", isActive: false },
      { name: "source", isActive: false },
      { name: "priority", isActive: false },
      { name: "category", isActive: false },
      { name: "tags", isActive: false },
    ],
    filters: [
      {
        name: "Status",
        value: "UnAssigned", // The status filter with value UnAssigned
      },
    ],
    itemsPerPage: 25,
    isActive: true,
    isDefault: true,
  },
  {
    _id: 123442,
    name: "Assigned tickets",
    description: "View for unassigned tickets",
    columns: [
      { name: "ticket", isActive: true },
      { name: "title", isActive: true },
      { name: "status", isActive: true },
      { name: "owner", isActive: true },
      { name: "assignee", isActive: true },
      { name: "last update", isActive: false },
      { name: "customer code", isActive: false },
      { name: "company name", isActive: false },
      { name: "customer name", isActive: false },
      { name: "customer email", isActive: false },
      { name: "created date", isActive: true },
      { name: "resolved date", isActive: false },
      { name: "first response due", isActive: false },
      { name: "resolution due", isActive: false },
      { name: "first response date", isActive: false },
      { name: "sla breached", isActive: false },
      { name: "type", isActive: false },
      { name: "source", isActive: false },
      { name: "priority", isActive: false },
      { name: "category", isActive: false },
      { name: "tags", isActive: false },
    ],
    filters: [
      {
        name: "assignee",
        value: currentUserInfo?._id || null, // The status filter with value UnAssigned
      },
    ],
    itemsPerPage: 25,
    isActive: true,
    isDefault: true,
  },
  {
    _id: 123441,
    name: "All Convos",
    description: "View for All Convos",
    columns: [
      { name: "ticket", isActive: true },
      { name: "title", isActive: false },
      { name: "status", isActive: true },
      { name: "owner", isActive: false },
      { name: "assignee", isActive: false },
      { name: "last update", isActive: false },
      { name: "customer code", isActive: false },
      { name: "company name", isActive: false },
      { name: "customer name", isActive: false },
      { name: "customer email", isActive: false },
      { name: "created date", isActive: false },
      { name: "resolved date", isActive: false },
      { name: "first response due", isActive: true },
      { name: "resolution due", isActive: true },
      { name: "first response date", isActive: false   },
      { name: "sla breached", isActive: true },
      { name: "type", isActive: false },
      { name: "source", isActive: false },
      { name: "priority", isActive: false },
      { name: "category", isActive: false },
      { name: "tags", isActive: false },
    ],
    filters: [
      {
        name: "Status",
        value: null, // The status filter with value UnAssigned
      },
    ],
    itemsPerPage: 25,
    isActive: true,
    isDefault: true,
    subSource: "convo",
  },
];

const initialState = {
  views: getDefaultViews(JSON.parse(localStorage.getItem("dexkorUserData"))), // Initialize with default views
};

const ticketExplorerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TICKET_EXPLORER_VIEWS:
      return {
        ...state,
        views: [
          ...getDefaultViews(
            JSON.parse(localStorage.getItem("dexkorUserData"))
          ),
          ...action.payload,
        ], // Combine default views and the new payload
      };

    case UPDATE_TICKET_EXPLORER_VIEW:
      return {
        ...state,
        views: state.views.map((view) =>
          view._id === action.payload._id ? action.payload : view
        ),
      };

    case ADD_TICKET_EXPLORER_VIEW:
      return {
        ...state,
        views: [...state.views, action.payload], // Add the new view to the end of the array
      };

    default:
      return state;
  }
};

export default ticketExplorerReducer;
