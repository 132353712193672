import React from "react";
import { Route } from "react-router-dom";
import Index from "..";

const KnowledgeBaseRoute = () => {
  return (
    <>
      <Route path="/knowledgebase" element={<Index />} />
    </>
  );
};

export default KnowledgeBaseRoute;
