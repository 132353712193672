import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const TopLeadersList = () => {
  const [beerFundRanks, setBeerFundRanks] = useState([]);
  const [rankDataLoading, setRankDataLoading] = useState(false);
  const [page, setPage] = useState(1); // For pagination
  const [hasMore, setHasMore] = useState(true); // Check if more data is available
  const dropdownRef = useRef(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userData) {
      fetchBeerFundRanks(userData);
    }
  }, []);

  const fetchBeerFundRanks = async (userData) => {
    try {
      setRankDataLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_CONVERSATION}/starboard/beer-funds/${userData.uniquePartnerId}`
      );
      if (response.data.success) {
        setBeerFundRanks((prevRanks) => [...prevRanks, ...response.data.data]);
        if (response.data.data.length === 0) setHasMore(false);
      }
      setRankDataLoading(false);
    } catch (error) {
      console.error(`Error fetching beer fund: ${error}`);
      setRankDataLoading(false);
    }
  };

  const handleScroll = () => {
    if (dropdownRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 5 &&
        hasMore &&
        !rankDataLoading
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    if (page > 1) {
      const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
      if (userData) fetchBeerFundRanks(userData);
    }
  }, [page]);

  //Handling the Profile Pics of the Users
  const userPhoto = null;
  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  return (
    <div className="relative p-2" style={{ width: "95%" }}>
      <div
        ref={dropdownRef}
        onScroll={handleScroll}
        className="absolute z-10 w-full py-2 overflow-y-auto mt-2 bg-white border border-blue-300 rounded-md shadow-lg"
        style={{ maxHeight: "45vh" }}
      >
        <div className="px-4 py-2 border-b text-lg font-semibold text-gray-900">
          Top Leaders
        </div>
        <ul className="p-2 space-y-3">
          {beerFundRanks.length > 0 &&
            beerFundRanks.map((beerFundRank, index) => (
              <li
                key={index}
                className="flex items-center space-x-4 p-2 bg-gray-50 rounded-md"
              >
                <div className="flex-shrink-0 align-middle">
                  {userPhoto ? (
                    <img
                      className="w-8 h-8 mt-4 rounded-full object-cover mr-4 shadow"
                      src={userPhoto}
                      alt={`${beerFundRank.userDetails?.name} avatar`}
                    />
                  ) : (
                    <div className="w-8 h-8 mt-4 rounded-full object-cover mr-4 shadow flex items-center justify-center bg-blue-300 text-gray-800 font-bold">
                      {getInitial(beerFundRank.userDetails?.name)}
                    </div>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {beerFundRank.userDetails?.name}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {beerFundRank.userDetails?.email}
                  </p>
                </div>
                <div className="text-base font-semibold text-gray-900">
                  {index + 1}
                </div>
              </li>
            ))}
        </ul>

        {/* Loading Spinner */}
        {rankDataLoading && (
          <div className="text-center p-4">
            <span>Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopLeadersList;
