import React from "react";
import { useState } from "react";
import LandingPage from "./components/LandingPage";
import Header from "./components/Header";
import GettingStarted from "./components/GettingStarted";
import Footer from "./components/Footer";
import DexKorSuite from "./components/DexKorSuite";

function Index() {
  const [view, setView] = useState("LandingPage");
  return (
    <>
      <>
        {view === "LandingPage" ? (
          <LandingPage setView={setView} />
        ) : (
          <>
            <Header setView={setView} view={view} />
            {view === "GettingStarted" && <GettingStarted setView={setView} />}
            {view === "DexKorSuite" && <DexKorSuite />}
          </>
        )}
      </>
      <Footer />
    </>
  );
}

export default Index;
