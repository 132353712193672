import React, { createContext, useState, useContext, useEffect } from 'react';

// Create context for selectedTicket
const SelectedTicketContext = createContext();

// Custom hook to use selected ticket
export const useSelectedTicket = () => useContext(SelectedTicketContext);

// Provider component to wrap your app
export const SelectedTicketProvider = ({ children }) => {
  // Retrieve the persisted ticket from localStorage or initialize with null
  const [selectedTicket, setSelectedTicket] = useState(() => {
    const savedTicket = localStorage.getItem('selectedTicket');
    return savedTicket ? JSON.parse(savedTicket) : null;
  });

  // Save the selected ticket to localStorage whenever it changes
  useEffect(() => {
    if (selectedTicket) {
      localStorage.setItem('selectedTicket', JSON.stringify(selectedTicket));
    } else {
      localStorage.removeItem('selectedTicket');
    }
  }, [selectedTicket]);

  return (
    <SelectedTicketContext.Provider value={{ selectedTicket, setSelectedTicket }}>
      {children}
    </SelectedTicketContext.Provider>
  );
};
