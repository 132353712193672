import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CouponPopUp.css";
const CouponPopUp = ({ onClose, userBalance, hrEmails }) => {
  const [userData, setUserData] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [userDetails, setUserDetails] = useState({
    name: "",
    address: "",
    email: "",
  });
  const [viewOption, setViewOption] = useState("all"); // 'all' or 'user'

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  useEffect(() => {
    const fetchCoupons = async () => {
      if (userData && userData.uniquePartnerId) {
        try {
          let url = `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/get-all-coupons/${userData.uniquePartnerId}`;
          if (viewOption === "user") {
            // Fetch only the coupons related to the specific user
            url = `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/get-user-coupons/${userData.uniquePartnerId}`;
          }
          const response = await axios.get(url);
          setCoupons(response.data.data);
        } catch (error) {
          console.error("Error fetching coupons:", error);
        }
      }
    };
    fetchCoupons();
  }, [userData, viewOption]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/notify-hr`, {
        ...userDetails,
        coupon: selectedCoupon,
        hrEmails,
      });
      alert("Details sent to HR");
      setUserDetails({ name: "", address: "", email: "" });
      setSelectedCoupon(null);
      onClose();
    } catch (error) {
      console.error("Error sending details to HR:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full relative"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-lg font-bold mb-4">All Coupons</h2>

        {/* Toggle Options: All Coupons vs. User Coupons */}
        <div className="mb-4">
          <button
            onClick={() => setViewOption("all")}
            className={`px-4 py-2 mr-2 rounded ${
              viewOption === "all" ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
          >
            All Coupons
          </button>
          <button
            onClick={() => setViewOption("user")}
            className={`px-4 py-2 rounded ${
              viewOption === "user" ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
          >
            Your Coupons
          </button>
        </div>

        {/* Displaying Coupons as Cards with max 3 per row and scrollable container */}
        <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 overflow-y-auto"
          style={{ maxHeight: "400px" }} // Adjust height as needed
        >
          {coupons.map((coupon) => (
            <div
              key={coupon._id}
              className="border border-gray-300 rounded-lg shadow-md p-4 bg-white relative"
            >
              <div className="mb-4">
                {coupon.couponImage ? (
                  <img
                    src={coupon.couponImage}
                    alt="Coupon"
                    className="w-full h-32 object-cover rounded-lg mb-4"
                  />
                ) : (
                  <div className="w-full h-32 bg-gray-200 flex items-center justify-center rounded-lg mb-4 text-lg font-semibold text-gray-700">
                    {coupon.couponName}
                  </div>
                )}
              </div>
              <p className="font-semibold">Name: {coupon.couponName}</p>
              <p>Redeem Status: {coupon.redeemStatus}</p>
              <p>Price: {coupon.price} Points</p>
              <div className="mt-2">
                {userBalance >= coupon.price && (
                  <button
                    onClick={() => setSelectedCoupon(coupon)}
                    className="bg-blue-500 text-white px-2 py-1 rounded"
                  >
                    Redeem Now
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        {selectedCoupon && (
          <div className="form-popup-overlay">
            <div className="form-popup-content">
              <h3 className="text-lg font-semibold mb-3">
                Redeem {selectedCoupon.couponName}
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label className="block font-medium">Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={userDetails.name}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div className="mb-2">
                  <label className="block font-medium">Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={userDetails.address}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div className="mb-2">
                  <label className="block font-medium">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={userDetails.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    type="submit"
                    className="bg-blue-700 text-white px-3 py-1 rounded"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => setSelectedCoupon(null)}
                    className="bg-gray-300 text-black px-3 py-1 rounded"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CouponPopUp;
