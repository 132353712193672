import React, { useState, useEffect } from "react";
import "./Profile.css";
import { Row, Col, Button, Avatar, Input, Upload, Form, Space } from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const Profile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    image: null,
  });
  const [tempImage, setTempImage] = useState(null); // Temporary image state

  useEffect(() => {
    const getUser = async () => {
      const userData = JSON.parse(localStorage.getItem("customerDetails"));
      const uniquePartnerId = localStorage.getItem("uniqueId");

      if (userData && uniquePartnerId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/portal/get-customer/${uniquePartnerId}/${userData.email}`
          );
          const { name, email, phone, image } = response.data;
          setCustomerData(response.data);
          setFormData({ name, email, phone, image });
        } catch (error) {
          console.error("Failed to fetch customer data:", error);
        }
      }
    };

    getUser();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleImageChange = (file) => {
    // Validate file size (e.g., max 2MB) and type
    const isValidSize = file.size / 1024 / 1024 < 10; // 2MB max size
    const isValidType = ["image/jpeg", "image/png"].includes(file.type);
  
    if (!isValidSize) {
      console.error("Image must be smaller than 2MB!");
      return false;
    }
    if (!isValidType) {
      console.error("Only JPG/PNG images are allowed!");
      return false;
    }
  
    // Create a temporary URL for immediate preview
    const previewUrl = URL.createObjectURL(file);
    setTempImage(previewUrl); // Update temp image state
  
    // Store the file in formData for later submission
    setFormData((prev) => ({ ...prev, image: file }));
  
    return false; // Prevent Upload component from auto-uploading
  };
  const handleSave = async () => {
    try {
      console.log("Temp Image:", tempImage);
      console.log("Form Data Before Save:", formData);
  
      const uniquePartnerId = localStorage.getItem("uniqueId");
      const dataToSave = {
        ...formData,
        image: tempImage || formData.image, // Use tempImage if available
      };
  
      console.log("Data to Save:", dataToSave);
  
      await axios.put(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/portal/update-customer/${uniquePartnerId}`,
        dataToSave
      );
  
      setCustomerData(dataToSave);
      setTempImage(null);
      setIsEdit(false);
    } catch (error) {
      console.error("Failed to update customer data:", error);
    }
  };
  
  const handleCancel = () => {
    setIsEdit(false);
    setTempImage(null); // Discard temporary image
    setFormData(customerData); // Revert form data to original state
  };

  const getAvatarContent = () => {
    const imageToShow = tempImage || formData.image; // Show temp image if available
    if (imageToShow) {
      return <Avatar size={164} src={imageToShow} />;
    }

    const fullName = formData.name || "N/A";
    const initial = fullName.charAt(0).toUpperCase();
    return (
      <Avatar size={164} style={{ backgroundColor: "#87d068" }}>
        {initial}
      </Avatar>
    );
  };

  return (
    <div className="dexkor-customer-portal-profile-page">
      <div className="flex justify-between items-center">
        <div>
          <p>My Profile</p>
        </div>
        <div>
          {!isEdit && <Button onClick={() => setIsEdit(true)}>Edit</Button>}
          {isEdit && (
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
            </Space>
          )}
        </div>
      </div>
      <div className="pt-10">
        <Row gutter={16}>
          <Col span={8}>
            {getAvatarContent()}
            <div className="pt-10">
            {isEdit && (
             <Upload
             beforeUpload={handleImageChange} // Validate and store image before upload
             showUploadList={false} // Prevent default upload behavior
           >
             <Button  icon={<UploadOutlined />}>Upload Image</Button>
           </Upload>
           
            )}
            </div>
          </Col>
          <Col span={16} className="pl-5">
            <Form layout="vertical">
              <Form.Item label="Full Name">
                {isEdit ? (
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>{customerData?.name}</p>
                )}
              </Form.Item>
              <Form.Item label="Email">
                {isEdit ? (
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled
                  />
                ) : (
                  <p>{customerData?.email}</p>
                )}
              </Form.Item>
              <Form.Item label="Phone">
                {isEdit ? (
                  <Input
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>{customerData?.phone}</p>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Profile;
