import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  updateDonationName,
  setDonationPointsForUser,
} from "../../../../Redux/Slice/starboardBeerDonationPostsSlice";

const UpdateDonation = () => {
  const [userData, setUserData] = useState("");
  const dispatch = useDispatch();
  const donationNameOptions = ["Points", "Stars", "Cheers", "Beers", "Coins"];
  const [selectedDonationName, setSelectedDonationName] = useState("points");
  const [points, setPoints] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`
      );

      const data = await response.json();
      setUsers((prevUsers) => [...prevUsers, ...data.users]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, searchTerm]);

  const handleScroll = () => {
    if (dropdownRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleUserSelection = (user) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.some((u) => u.id === user.id)
        ? prevSelected.filter((u) => u.id !== user.id)
        : [...prevSelected, user]
    );
  };

  const handleDonationNameChange = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/setupPointsName`,
        {
          pointsName: selectedDonationName,
          uniquePartnerId: userData.uniquePartnerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(updateDonationName(selectedDonationName));
      console.log("Donation name updated successfully in the database");
    } catch (error) {
      console.error("Error updating donation name:", error);
    }
  };

  const handleDonationPointsChange = () => {
    if (selectedUsers.length > 0 && points) {
      dispatch(
        setDonationPointsForUser({
          users: selectedUsers.map((user) => user.id),
          points: Number(points),
        })
      );
      setSelectedUsers([]);
      setPoints("");
    } else {
      alert("Please select at least one user and enter points");
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 m-6 font-sans bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold text-center mb-6">
        Update Donation Settings
      </h2>

      {/* Donation Name Selection */}
      <div className="mb-6">
        <label className="block font-medium mb-2">Donation Name:</label>
        <select
          className="w-full p-3 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={selectedDonationName}
          onChange={(e) => setSelectedDonationName(e.target.value)}
        >
          {donationNameOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <button
          onClick={handleDonationNameChange}
          className="w-1/2 p-3 bg-blue-700 text-white font-medium rounded-md hover:bg-blue-500"
        >
          Update Donation Name
        </button>
      </div>

      {/* User Selection with Infinite Scroll */}
      <div className="mb-6">
        <label className="block font-medium mb-2">Select Users:</label>
        <div className="relative">
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
              setUsers([]);
            }}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div
            ref={dropdownRef}
            onScroll={handleScroll}
            className="absolute z-10 w-full max-h-40 overflow-y-auto mt-2 bg-white border border-gray-300 rounded-md shadow-lg"
          >
            {users.map((user) => (
              <div
                key={user.id}
                onClick={() => handleUserSelection(user)}
                className={`p-2 cursor-pointer hover:bg-blue-100 ${
                  selectedUsers.some((u) => u.id === user.id)
                    ? "bg-blue-200"
                    : ""
                }`}
              >
                {user.name}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4 flex flex-wrap">
          {selectedUsers.map((user) => (
            <span
              key={user.id}
              className="p-1 px-2 mr-2 mb-2 bg-blue-200 text-blue-800 rounded-full cursor-pointer"
              onClick={() => handleUserSelection(user)}
            >
              {user.name} &times;
            </span>
          ))}
        </div>
      </div>

      {/* Donation Points Input */}
      <div className="mb-6">
        <label className="block font-medium mb-2">Donation Points:</label>
        <input
          type="number"
          className="w-full p-3 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={points}
          onChange={(e) => setPoints(e.target.value)}
        />
        <button
          onClick={handleDonationPointsChange}
          className="w-1/2 p-3 bg-blue-700 text-white font-medium rounded-md hover:bg-blue-500"
        >
          Set Donation Points
        </button>
      </div>
    </div>
  );
};

export default UpdateDonation;
