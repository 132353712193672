import React from "react";

const Celebration = () => {
  return (
    <div>
      <img
        width="30"
        height="30"
        src="https://img.icons8.com/3d-fluency/94/confetti.png"
        alt="confetti"
      />
    </div>
  );
};

export default Celebration;
