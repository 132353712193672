import React, { useEffect, useRef, useState } from "react";
import logo from "../../../Images/Logo icon.png";
import googleLogo from "../../../Images/google-logo.png";
import portalLogo from "../../../Images/portalLogo.png";
import Phone from "../../../Images/Phone.png";
import internalLogo from "../../../Images/internalLogo.png";
import TicketDetails from "../../TicketDetails";
import "./SubTicketViewer.css";
import { Tabs, Col, Divider } from "antd";
import { RiAlarmLine } from "react-icons/ri";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import SLADetails from "../../SLADetails";
import Emails from "../../Emails/Emails";
import Tickets from "../../Tickets/Tickets";
import DateTimePicker from "../../DateTimePicker/DateTimePicker";
import useNotification from "../../snackbars/SnackBar";
import axios from "axios";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function TicketViewer({
  toggleDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  convo,
  setConversations,
  conversations,
  setSelectedConvo,
  parentConvo,
  setParentConvo,
  setParentConversations,
}) {
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [snoozeDateTime, setSnoozeDateTime] = useState(null);
  const [departmentCode, setDepartmentCode] = useState(null);
  const [conf, setConf] = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    let Code;
    if (convo?.ticketId?.departmentAssigned === "DevCore") {
      Code = "DPT1003";
    } else if (convo?.ticketId?.departmentAssigned === "ProductPro") {
      Code = "DPT1002";
    } else if (convo?.ticketId?.departmentAssigned === "HelpDesk") {
      Code = "DPT1001";
    } else {
      Code = convo?.ticketId?.departmentAssigned || "DPT1001";
    }
    setDepartmentCode(Code);
  }, [convo]);

  useEffect(() => {
    if (snoozeDateTime) {
      const selectedDate = new Date(snoozeDateTime); // Convert snoozeDateTime to a Date object
      const currentDate = new Date(); // Get the current date and time

      // Check if snoozeDateTime is in the future
      if (selectedDate < currentDate) {
        setConf({
          msg: "snooze date and time must be in the future.",
          variant: "error",
        });
        setShowDateTimePicker(true);
      } else {
        snoozeTickets();
      }
    }
  }, [snoozeDateTime]);

  const snoozeTickets = async () => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    const userToken = localStorage.getItem("dexkorUserToken");
    const ticketIds = [convo.ticketId._id];
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/tickets/snooze`,
        {
          snoozeDateTime,
          ticketIds,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Role: `${userData.role}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setSnoozeDateTime(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Close the drawer when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const drawerElement = document.getElementById("drawer-form");
      if (
        isDrawerOpen &&
        drawerElement &&
        !drawerElement.contains(event.target)
      ) {
        setIsDrawerOpen(false);
      }
    };
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isDrawerOpen, toggleDrawer]);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [convo?.messages]);

  const logoMapping = {
    widget: logo,
    portal: portalLogo,
    email: googleLogo,
    servetel: Phone,
    internal: internalLogo,
    Internal: internalLogo,
  };

  return (
    <>
      <div
        className={`fixed top-[2.3rem] w-full right-0 bg-gray-100 p-4 overflow-y-auto z-50
          ${isDrawerOpen ? "animate-slideIn" : "animate-slideOut"}`}
      >
        <div className="flex space-x-2 items-center">
          <FaArrowCircleLeft
            onClick={() => {
              const currentPath = window.location.pathname; // Get the current path
              // Replace the last segment of the URL
              const updatedPath = currentPath.replace(/\/[^/]*$/, ``);

              navigate(updatedPath); // Navigate to the updated path
              setIsDrawerOpen(false);
            }}
            className="size-7 hover:text-blue-600 hover:text-blue-600"
          />
          <img
            src={logoMapping[convo.ticketId.source] || logo} // Fallback to defaultLogo if source is not matched
            alt="source image"
            className="w-auto h-8"
          />
          <h1 className="text-xl font-bold">
            {convo.ticketId.ticketNumber || "---"}
            {" - "}
            {convo?.ticketId?.subject}
          </h1>

          <div style={{ marginLeft: "auto" }}>
            <div className="flex items-center space-x-2">
              <div className="relative group">
                <RiAlarmLine
                  className="size-4 cursor-pointer text-blue-600"
                  onClick={() => setShowDateTimePicker(true)}
                />
                <span className="absolute top-[-15px] left-1/2 transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
                  Snooze
                </span>
              </div>
            </div>
            {showDateTimePicker && (
              <DateTimePicker
                setDateTime={setSnoozeDateTime}
                dateTime={snoozeDateTime}
                setShowDateTimePicker={setShowDateTimePicker}
              />
            )}
          </div>
        </div>
        <div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: "Messages",
                key: "1",
                children: (
                  <Col style={{ height: "100vh" }}>
                    <div
                      style={{
                        justifyContent: "center",
                        backgroundColor: "#F6F7F9",
                      }}
                    >
                      {parentConvo &&
                        parentConvo.ticketId.source !== "email" && (
                          <div
                            ref={chatContainerRef}
                            style={{
                              overflow: "auto",
                            }}
                          >
                            <Tickets
                              selectedConversation={convo}
                              setSelectedConversation={setSelectedConvo}
                              setConversations={setConversations}
                              conversations={conversations}
                              isSubticket={true}
                              parentConversation={parentConvo}
                              setParentConversation={setParentConvo}
                              setParentConversations={setParentConversations}
                              departmentCode={departmentCode}
                            />
                          </div>
                        )}

                      {parentConvo &&
                        parentConvo.ticketId.source === "email" && (
                          <Emails
                            selectedConversation={convo}
                            setSelectedConversation={setSelectedConvo}
                            setConversations={setConversations}
                            conversations={conversations}
                            isSubticket={true}
                            parentConversation={parentConvo}
                            setParentConversation={setParentConvo}
                            setParentConversations={setParentConversations}
                            departmentCode={departmentCode}
                          />
                        )}
                    </div>
                  </Col>
                ),
              },
              {
                label: "Details",
                key: "2",
                children: (
                  <div
                    style={{
                      height: "100vh",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {convo && (
                      <div>
                        {convo && (
                          <TicketDetails
                            selectedConversation={convo}
                            setConversations={setConversations}
                            setSelectedConvo={setSelectedConvo}
                          />
                        )}

                        <Divider />
                        {convo && <SLADetails selectedConversation={convo} />}
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default TicketViewer;
