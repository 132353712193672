import React from "react";
import { Route } from "react-router-dom";
import StarBoard from "../StarBoard";
import PrivateRoute from "../../Private Route/PrivateRoute";
import CouponPopUp from "../CouponPopUp/CouponPopUp";
import Admin from "../Admin/Admin";

const StarBoardRoutes = () => {
  return (
    <>
      <Route
        path="/star-board"
        element={
          <PrivateRoute>
            <StarBoard></StarBoard>
          </PrivateRoute>
        }
      >
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
      </Route>
    </>
  );
};

export default StarBoardRoutes;
