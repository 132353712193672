import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./GeneralSetup.css";
import { Button, Card, Space, Modal, Row, Col, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

const GeneralSetup = () => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const navig = useNavigate();

  const items = [
    { key: "1", label: "Status", path: "/setting/general-setup/status-setup" },
    { key: "2", label: "Import Export Setup" },
    { key: "3", label: "Application Setup" },
    { key: "4", label: "User Setup" },
  ];

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (
              item.path &&
              !e.target.classList.contains("ant-menu-submenu-arrow")
            ) {
              navig(item.path);
            }
          }}
          className="dexkor-sales-hub-menu-item"
        >
          {item.label}
          {item.number !== undefined && <span>{item.number}</span>}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items
      .flatMap((item) => item.children || [item])
      .find((item) => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navig(selectedItem.path);
    }
    if (e.key === "2") {
      setOpenKeys([e.key]);
      setSelectedKey("21"); // Select first child
      navig("/sales-hub/assigned-sales"); // Navigate to first child's path
    } else {
      setSelectedKey(e.key);
    }
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);

    if (keys.includes("2")) {
      const firstChild = items.find((item) => item.key === "2").children[0];
      if (firstChild && firstChild.path) {
        setSelectedKey(firstChild.key);
        navig(firstChild.path);
      }
    }
  };

  return (
    <div>
      <Row>
        <Col span={4}>
          {" "}
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className="cursor-pointer">General Setup</span>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            onOpenChange={onOpenChange}
            className="dexkor-sales-hub-nav-bar-menu-list"
            items={getMenuItems(items)}
            openKeys={openKeys}
          />
        </Col>
        <Col span={20}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default GeneralSetup;
