import React from "react";

const CakeIcon = () => {
  return (
    <div>
      <img
        width="30"
        height="30"
        src="https://img.icons8.com/color-glass/48/birthday.png"
        alt="birthday"
      />
    </div>
  );
};

export default CakeIcon;
