import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import Otp from './Otp';
import LoaderButton from './pageLoaders/LoaderButton';
import './Login.css';
import axios from 'axios';
import AppBar from '../AppBar/AppBar';
import useNotification from '../../components/snackbars/SnackBar';
import Password from './Password';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [setupDetails, setSetupDetails] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [appBar, setAppBar] = useState(false)
  const [loginViaPassword, setLoginViaPassword] = useState(false);
  const [conf, setConf] = useNotification();
  
  useEffect(() => {
    const getPartnerDetails = async () => {
        // const accessURL = window.location.origin ;
        const accessURL = window.location.origin;
  
        if (accessURL) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_CONVERSATION}/partners/verify-login`,
              { accessUrl: accessURL }
            );       
            setSetupDetails(response.data.setup)
            localStorage.setItem("uniqueId", response.data.setup.uniquePartnerId)
          } catch (error) {
            console.error('Error verifying partner login:', error);
          }
        }
      };
  
    getPartnerDetails();
  }, [])

  const onLogin = async (value) => {
    console.log(customerData);
  } 

  const onFinish = async (values = customerData) => {
    setLoading(true);
    setCustomerData(values);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/portal/check-or-create-portal-customer`,
        values
      );

      if (response.status === 200) {
        await axios.post(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/portal/identify-portal-customer`,
          values
        );
       setConf({msg: "OTP sent successfully", variant: "success"})
        setShowOtp(true)
      } else {
        setConf({msg: "User not available with this email", variant: "error"})
      }
    } catch (error) {
      message.error('An error occurred. Please try again.');
      console.error('API Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    {appBar && (
        <AppBar 
        isUserLoggedIn={isUserLoggedIn}
        />
    )}
    {!showOtp && (
    <div className="dexkor-portal-login-body">
      <div className="login-box">
        <h2>Login to Customer Portal</h2>
        <Form name="loginForm" onFinish={onFinish} layout="vertical">
          <Form.Item
            name="email"
            label="Username or Email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item>
          <div>
                {loading && setupDetails ? (
                  <LoaderButton setupDetails={setupDetails} />
                ) : (
                  <button
                    type="submit"
                    aria-label="submit"
                    className="dexkor-form-submit-button"
                    style={{
                      background: `linear-gradient(90deg, ${setupDetails?.widgetCustomizations?.secondaryColor} 0%, ${setupDetails?.widgetCustomizations?.primaryColor} 100%)`,
                    }}
                  >
                    Verify and Send OTP
                  </button>
                )}
              </div>
          </Form.Item>
        </Form>
      </div>
    </div>
    )}
     {showOtp && !loginViaPassword && (
        <Otp
          customerData={customerData} // Pass customer data to Otp component
          setShowOtp={setShowOtp}
        //   setToken={setToken}
          setCustomerData={setCustomerData}
          resendOTP={onFinish}
          setupDetails={setupDetails}
          setIsUserLoggedIn={setIsUserLoggedIn}
          setLoginViaPassword={setLoginViaPassword}
          loginViaPassword={loginViaPassword}
        />
      )}
      {
        showOtp && loginViaPassword && (
          <div className="dexkor-portal-login-body">
          <div className="login-box">
            <h2>Login to Customer Portal</h2>
            <Form name="loginForm" onFinish={onLogin} layout="vertical">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Enter your password' },
                  {  message: 'Enter your password' },
                ]}
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>
              <Form.Item>
              <div>
                    {loading && setupDetails ? (
                      <LoaderButton setupDetails={setupDetails} />
                    ) : (
                      <button
                        type="submit"
                        aria-label="submit"
                        className="dexkor-form-submit-button"
                        style={{
                          background: `linear-gradient(90deg, ${setupDetails?.widgetCustomizations?.secondaryColor} 0%, ${setupDetails?.widgetCustomizations?.primaryColor} 100%)`,
                        }}
                      >
                        Login
                      </button>
                    )}
                  </div>
              </Form.Item>
              <div className="flex justify-between flex-row">
            <div
                  className="dexkor-resend-link cursor-pointer"
                  onClick={() => setLoginViaPassword(false)}
                  style={{
                    color: `${setupDetails?.widgetCustomizations?.secondaryColor}`,
                  }}
                >
                 <p> Login Via OTP </p>
                </div>
                <div
                  className="dexkor-resend-link cursor-pointer"
                  onClick={() => setLoginViaPassword(false)}
                  style={{
                    color: `${setupDetails?.widgetCustomizations?.secondaryColor}`,
                  }}
                >
                <p>  Reset Password </p>
                </div>
            </div>
            </Form>
            
          </div>
        </div>
        )
      }
      </>
  );
};

export default Login;
