import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

function Header({ setView, view }) {
  return (
    <div>
      <div className="bg-blue-900 p-8">
        <div className="relative mt-5 w-full mx-auto max-w-xl mb-4">
          <input
            type="text"
            placeholder="Search"
            className="text-sm rounded-lg px-4 py-3.5 w-full placeholder-gray-700"
          />
          <div className="absolute inset-y-0 right-4 flex items-center">
            <MagnifyingGlassIcon
              className="w-5 h-5 cursor-pointer"
              strokeWidth={2}
            />
          </div>
        </div>
      </div>
      <div className="pl-8 py-4 border-b flex gap-x-3 items-center">
        <div
          className="font-semibold cursor-pointer inline-block hover:text-blue-800"
          onClick={() => setView("LandingPage")}
        >
          Home
        </div>

        {view === "DexKorSuite" && (
          <>
            <div>/</div>
            <div
              className="font-semibold cursor-pointer inline-block hover:text-blue-800"
              onClick={() => setView("GettingStarted")}
            >
              GettingStarted
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
