import React, { useEffect, useState, useRef } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Row, Col, Checkbox, Button, Input } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelectedTicket } from "./SelectedTicketContext";
import {
  PaperClipIcon,
  PaperAirplaneIcon,
  CameraIcon,
  VideoCameraIcon,
  MicrophoneIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import html2canvas from "html2canvas";
import { RiSpeakLine } from "react-icons/ri";
import Conversation from "../Common/Conversation";
import ReactQuillBox from "../../AccountCare/Components/ReactQuill/ReactQuill";
import io from "socket.io-client";
import axios from "axios";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const Ticket = ({ setupDetails }) => {
  const navigate = useNavigate();
  const { selectedMenu } = useOutletContext();
  const { selectedTicket, setSelectedTicket } = useSelectedTicket();
  const [ticketEdit, setTicketEdit] = useState(false);
  const [isEscalated, setIsEscalated] = useState(false);
  const [escalatedReason, setEscalatedReason] = useState(selectedTicket?.ticketId?.escalatedReason)
  const [escalatedFlag, setEscalatedFlag] = useState(false);
  const [message, setMessage] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [attachmentType, setAttachmentType] = useState(null);
  const fileInputRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [recording, setRecording] = useState(false);
  const [transcribing, setTranscribing] = useState(false);
  const recognitionRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [conversationData, setConversationData] = useState(selectedTicket);
  const [originalTicket, setOriginalTicket] = useState(null);

  // Set the initial value of `isEscalated` when `selectedTicket` changes
  useEffect(() => {
    if (selectedTicket) {
      setIsEscalated(selectedTicket?.ticketId?.escalated || false);
      setEscalatedFlag(false);
    }
  }, [selectedTicket, escalatedFlag]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [conversationData]);

  useEffect(() => {
    if (selectedTicket) {
      setOriginalTicket({
        escalated: selectedTicket.ticketId.escalated || false,
        escalatedReason: selectedTicket.ticketId.escalatedReason || "",
      });
      setIsEscalated(selectedTicket.ticketId.escalated || false);
      setEscalatedReason(selectedTicket.ticketId.escalatedReason || "");
    }
  }, [selectedTicket]);

  const updateTicket = async() => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_CONVERSATION}/tickets/${selectedTicket.ticketId.ticketNumber}/cc-bcc`,{
        escalated: isEscalated,
        escalatedReason: escalatedReason
      })
      setTicketEdit(false);
      setOriginalTicket({ escalated: isEscalated, escalatedReason });
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    // Revert to original values
    if (originalTicket) {
      setIsEscalated(originalTicket.escalated);
      setEscalatedReason(originalTicket.escalatedReason);
    }
    setTicketEdit(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachment(reader.result);
        setAttachmentType(file.type);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeQuotedText = (message) => {
    const quotedTextPattern =
      /(?:^On .+ wrote:|^From: .+|^\s*>.*(?:\n|$)|^>.*(?:\n|$))/gms;
    return message.replace(quotedTextPattern, "").trim();
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const captureScreenshot = async () => {
    try {
      const chatWindow = document.querySelector(".dexkor-chat-widget");

      if (chatWindow) {
        chatWindow.style.visibility = "hidden"; // Hide the chat window
      }

      // Wait for the next animation frame to ensure the DOM is updated
      await new Promise((resolve) => requestAnimationFrame(resolve));

      // Create a canvas element to capture the current viewport
      const canvas = await html2canvas(document.body, {
        x: window.scrollX, // Offset for horizontal scroll
        y: window.scrollY, // Offset for vertical scroll
        width: window.innerWidth, // Width of the viewport
        height: window.innerHeight, // Height of the viewport
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        useCORS: true, // Enable CORS to handle cross-origin images
      });

      // Convert the canvas to a data URL (image)
      const screenshot = canvas.toDataURL("image/png");

      if (chatWindow) {
        chatWindow.style.visibility = ""; // Restore the chat window
      }

      setAttachment(screenshot);
      setAttachmentType("image/png");
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  useEffect(() => {
    if (attachment && attachmentType) {
      sendMessage();
    }
  }, [attachment, attachmentType]);

  const sendMessage = () => {
    const inputMessage = message?.trim();
    if (inputMessage || (attachment && attachmentType)) {
      const msg = {
        room: selectedTicket.roomId,
        sender: "Customer",
        // senderId: customerDetails._id,
        message: inputMessage === "" ? null : inputMessage,
        attachment,
        timestamp: new Date(),
        attachmentType,
      };
      socket.emit("message", msg);
      setMessage("");
      setAttachment(null);
      setAttachmentType(null);

      setConversationData((prev) => ({
        ...prev,
        messages: [...prev.messages, msg],
      }));
    }
  };

  const startScreenRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm; codecs=vp9",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "video/webm" });
        const reader = new FileReader();
        let result;
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("video/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();

      setTimeout(() => {
        mediaRecorderRef.current.stop();
        stream.getTracks().forEach((track) => track.stop());
      }, 8000); // Stop recording after 8 seconds
    } catch (error) {
      console.error("Error starting screen recording:", error);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("audio/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  let touchTimer;

  const startTranscription = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition is not supported in this browser.");
      return;
    }

    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = "en-US";

    let finalTranscript = "";

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }
      setMessage(finalTranscript + interimTranscript);
    };

    recognitionRef.current.start();
    setTranscribing(true);
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setTranscribing(false);
    }
  };

  const handleTouchStart = () => {
    // Clear any existing timer
    clearTimeout(touchTimer);

    // Start transcription with a slight delay
    touchTimer = setTimeout(() => {
      startTranscription();
    }, 100); // 100ms delay to ensure touch events are processed correctly
  };

  const handleTouchEnd = () => {
    // Clear the timer if touch ends before the delay
    clearTimeout(touchTimer);

    stopTranscription();
  };

  const handleTouchCancel = () => {
    stopTranscription();
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "#F1F3F6" }}
        className="flex cursor-pointer px-6 py-3 items-center gap-2"
        onClick={() => navigate(`/portal/home/${selectedMenu}`)}
      >
        <FaArrowLeft /> Back
      </div>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 110px)",
        }}
      >
        <Row>
          <Col span={8}>
            <div
              className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md"
              style={{ height: "calc(100vh - 115px)" }}
            >
              <div className="flex justify-between">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
                  Ticket Details
                </h2>
                <div>
                  {!ticketEdit && (
                    <Button
                      onClick={() => {
                        setTicketEdit(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {ticketEdit && (
                    <>
                     <Button onClick={handleCancel}>Cancel</Button>
                      <Button onClick={updateTicket}>Update</Button>
                    </>
                  )}
                </div>
              </div>
              <div className="p-4 bg-gray-100 dark:bg-gray-900 rounded-lg">
                <Row className="mb-3">
                  <Col
                    span={12}
                    className="text-gray-700 dark:text-gray-300 font-medium"
                  >
                    Ticket Number
                  </Col>
                  <Col
                    span={12}
                    className="text-right text-gray-900 dark:text-white font-semibold"
                  >
                    {selectedTicket?.ticketId?.ticketNumber}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col
                    span={12}
                    className="text-gray-700 dark:text-gray-300 font-medium"
                  >
                    Status
                  </Col>
                  <Col
                    span={12}
                    className="text-right text-gray-900 dark:text-white font-semibold"
                  >
                    {selectedTicket?.ticketId?.status}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col
                    span={12}
                    className="text-gray-700 dark:text-gray-300 font-medium"
                  >
                    Priority
                  </Col>
                  <Col
                    span={12}
                    className="text-right text-gray-900 dark:text-white font-semibold"
                  >
                    {selectedTicket?.ticketId?.priority}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col
                    span={12}
                    className="text-gray-700 dark:text-gray-300 font-medium"
                  >
                    Escalated
                  </Col>
                  <Col
                    span={12}
                    className="text-right text-gray-900 dark:text-white font-semibold"
                  >
                    <Checkbox
                      checked={isEscalated}
                      disabled={!ticketEdit}
                      onChange={(e) => setIsEscalated(e.target.checked)}
                    />
                  </Col>
                </Row>

                {/* Conditionally render the Escalation Reason row */}
                {isEscalated && (
                  <Row className="mb-3">
                    <Col
                      span={12}
                      className="text-gray-700 dark:text-gray-300 font-medium"
                    >
                      Escalation Reason
                    </Col>
                    <Col
                      span={12}
                      className="text-right text-gray-900 dark:text-white font-semibold"
                    >
                      <Input
        value={escalatedReason} // Use local state for controlled input
        onChange={(e) => setEscalatedReason(e.target.value)}
        disabled={!ticketEdit} // Disable input if editing is not allowed
      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
          <Col span={16}>
            <div
              ref={chatContainerRef}
              className="px-5"
              style={{ height: "calc(100vh - 230px)", overflow: "auto" }}
            >
              <Conversation conversation={conversationData} />
            </div>
            <div className="px-5 py-2">
              {selectedTicket?.ticketId?.status !== "closed" &&
                selectedTicket?.ticketId?.status !== "resolved" && (
                  <div
                    className="dexkor-message-input-container p-0 border-gray-200 rounded-lg"
                    style={{
                      border: `1px solid ${
                        setupDetails?.widgetCustomizations.primaryColor ||
                        "#4C73DE"
                      }`,
                    }}
                  >
                    <input
                      placeholder="Type/record your message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                      className="flex-1 mt-1 w-full h-auto py-2 px-3 mb-3 border-none text-sm focus:outline-none focus:ring-0  block bg-gray-100"
                      rows={1}
                    />
                    <div className="flex justify-between items-center p-1">
                      <div>
                        <div className="dexkor-message-input-options relative flex items-center">
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            className="hidden"
                          />
                          <button
                            type="button"
                            onClick={handleIconClick}
                            className="text-gray-500 hover:text-gray-700 ml-3"
                          >
                            <PaperClipIcon className="h-4 w-4" />
                          </button>
                          <button
                            type="button"
                            onClick={captureScreenshot}
                            className="text-gray-500 hover:text-gray-700 ml-3"
                          >
                            <CameraIcon className="h-4 w-4" />
                          </button>
                          <button
                            type="button"
                            onClick={() => startScreenRecording()}
                            className="text-gray-500 hover:text-gray-700 ml-3"
                          >
                            <VideoCameraIcon className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <button
                          className={`bg-transparent pl-0 text-gray-500 ${
                            transcribing
                              ? "hover:text-red-500"
                              : "hover:text-gray-700"
                          } ${transcribing ? "text-red-500" : ""}`}
                          onMouseDown={startTranscription}
                          onMouseUp={stopTranscription}
                          onTouchStart={handleTouchStart}
                          onTouchEnd={handleTouchEnd}
                          onTouchCancel={handleTouchCancel}
                        >
                          <RiSpeakLine className="h-4 w-4" />
                        </button>
                        <button
                          className={`text-gray-500 ${
                            recording
                              ? "hover:text-green-500"
                              : "hover:text-gray-700"
                          } ml-2 ${recording ? "text-green-500" : ""}`}
                          onClick={recording ? stopRecording : startRecording}
                        >
                          <MicrophoneIcon
                            className={`h-4 w-4 ${
                              recording ? "animate-pulse" : "text-gray-500"
                            }`}
                          />
                        </button>
                        <button
                          className="bg-blue-500 text-white p-2 rounded-full ml-2 hover:bg-blue-600"
                          style={{
                            background: `${
                              setupDetails?.widgetCustomizations.primaryColor ||
                              "#4C73DE"
                            }`,
                          }}
                          onClick={sendMessage}
                        >
                          <PaperAirplaneIcon className="h-2 w-2" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Ticket;
