// BadPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BadPage.css'; // Create a CSS file for custom styles

const BadPage = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/portal/home'); // Navigate to the home page
  };

  return (
    <div className="bad-page-container">
      <div className="error-message">
        <h1 className="error-code">404</h1>
        <p className="error-text">Oops! The page you are looking for doesn't exist.</p>
        <button className="back-button" onClick={handleBackToHome}>Go Back to Home</button>
      </div>
    </div>
  );
};

export default BadPage;
