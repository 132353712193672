import React from "react";
import { AcademicCapIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

function GettingStarted({ setView }) {
  return (
    <div className="overflow-auto">
      <div className="flex flex-col items-center mt-10">
        <AcademicCapIcon className="h-16 w-16 pb-2" />
        <h5 className="text-center font-bold text-4xl pb-4">Getting started</h5>
        <p className="text-center font-semibold text-lg">
          Set yourself up for success with onboarding guides
        </p>
      </div>

      {/* ArticlesSection */}
      <div className="mt-16 mx-auto max-w-6xl mb-7">
        <div
          className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow"
          onClick={() => setView("DexKorSuite")}
        >
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting Started with DexKor Suite</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting started with Support</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting started with email</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting started with DexKor AI</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting started with messaging</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting Started with DexKor workforce management</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting started with Explore</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Getting started with AI Agents (Ultimate)</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
      </div>
    </div>
  );
}

export default GettingStarted;
