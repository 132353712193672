import React, { useState, useEffect } from "react";
import "./Index.css";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Menu, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaLink } from "react-icons/fa6";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Index = () => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  // Get the Ticket Explorer views from the Redux store
  const ticketExplorerViews = useSelector(
    (state) => state.ticketExplorer.views
  );

  // Dynamically create "Ticket Explorer" children based on ticketExplorerViews
  const defaultViews = ticketExplorerViews
    .filter((view) => view.isDefault === true)
    .map((view) => ({
      key: `ticketExplorer-${view._id}`, // Unique key for each view
      label: view.name, // Assuming each view has a `name` field
      path: `/product-pro/ticket-explorer/${view.name
        .replace(/\s+/g, "-")
        .toLowerCase()}`, // Assuming each view has a unique `_id` to use in the path
    }));
  const customViews = ticketExplorerViews
    .filter(
      (view) => view.isDefault !== true && view?.department === "ProductPro"
    )
    .map((view) => ({
      key: `ticketExplorer-${view._id}`, // Unique key for each view
      label: view.name, // Assuming each view has a `name` field
      path: `/product-pro/custom-views/${view.name
        .replace(/\s+/g, "-")
        .toLowerCase()}`, // Assuming each view has a unique `_id` to use in the path
    }));

  const items = [
    {
      key: "5",
      label: "Ticket Explorer",
      children: [...defaultViews],
    },
    {
      key: "6",
      label: "Custom Views",
      children: [...customViews],
    },
  ];

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (
              item.path &&
              !e.target.classList.contains("ant-menu-submenu-arrow")
            ) {
              navigate(item.path);
            }
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {item.label}
          {item.number !== undefined && (
            <span style={{ marginLeft: "auto" }}>{item.number}</span>
          )}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items
      .flatMap((item) => item.children || [item])
      .find((item) => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(selectedItem.path);
    }
    setSelectedKey(e.key);
  };

  const getParentKeys = (childKey, menuItems, parents = []) => {
    for (let item of menuItems) {
      if (item.key === childKey) {
        return parents; // If the current item is the target, return accumulated parent keys
      }
      if (item.children) {
        const result = getParentKeys(childKey, item.children, [
          ...parents,
          item.key,
        ]);
        if (result) return result;
      }
    }
    return null;
  };

  useEffect(() => {
    const currentPath = location.pathname;

    const flattenMenuItems = (menuItems) =>
      menuItems.flatMap((item) =>
        item.children ? [item, ...flattenMenuItems(item.children)] : [item]
      );

    const currentItem = flattenMenuItems(items).find(
      (item) => item.path === currentPath
    );

    if (currentItem) {
      setSelectedKey(currentItem.key);

      // Retrieve all parent keys for the current item
      const parentKeys = getParentKeys(currentItem.key, items) || [];
      setOpenKeys(parentKeys);
    }
  }, [location, ticketExplorerViews]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const pathSegments = location.pathname.split("/").filter(Boolean); // Split and remove empty segments

  // Handle navigation when a segment is clicked
  const handleSegmentClick = (index) => {
    // Construct the new path up to the clicked segment
    const newPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
    navigate(newPath);
  };

  const [copied, setCopied] = useState(false); // Track whether the URL is copied

  const handleCopyUrl = () => {
    const url = `${window.location}`; // Get the current URL
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true); // Set copied state to true
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "#eff3f5" }}
        className="top-0 fixed w-full flex items-center p-2"
      >
        <div>
          <GiHamburgerMenu
            onClick={toggleSidebar}
            className={`mr-1 size-6 rounded-full ${
              isExpanded
                ? "bg-blue-600 text-white"
                : "hover:bg-gray-200 bg-transparent text-gray-500"
            } cursor-pointer p-1 z-10`}
            style={{ userSelect: "none" }} // Prevent text selection
          />
        </div>
        <div className="text-md font-semibold text-gray-500 flex items-center justify-left p-1">
          {pathSegments.map((segment, index) => (
            <React.Fragment key={index}>
              <span
                onClick={() => handleSegmentClick(index)}
                className={`capitalize hover:text-blue-500 hover:underline cursor-pointer ${
                  index === pathSegments.length - 1 && "text-blue-500 underline"
                }`}
              >
                {segment}
              </span>
              {index !== pathSegments.length - 1 && (
                <MdKeyboardArrowRight className="size-5" />
              )}
            </React.Fragment>
          ))}
          <div className="ml-2 relative group" onClick={handleCopyUrl}>
            <FaLink className="size-4 cursor-pointer " />
            <span className="absolute top-[-12px] left-2 transform -translate-x-1/2 text-[10px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
              {copied ? "Copied!" : "Copy"}
            </span>
          </div>
        </div>
      </div>
      <Row style={{ height: "100vh" }} className="mt-10">
        <div
          className={`transition-all duration-300 mt-1 ${
            isExpanded ? "w-[15%]" : "w-0"
          }`}
          style={{ backgroundColor: "#eff3f5" }}
        >
          <div style={{ width: "100%", padding: "0px" }} className="relative">
            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              onOpenChange={onOpenChange}
              style={{
                height: "calc(95vh - 3px)",
                overflowY: "auto",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "600",
              }}
              className="dexkor-help-desk-left-bar rounded-lg"
              items={getMenuItems(items)}
              openKeys={openKeys}
            />
            <button
              onClick={toggleSidebar}
              className="absolute -right-[3px] bottom-24 hover:bg-blue-600 hover:text-white text-gray-500 p-1 rounded-full z-50 bg-gray-100"
              aria-label={isExpanded ? "Collapse sidebar" : "Expand sidebar"}
            >
              {isExpanded ? (
                <FaChevronLeft className="w-4 h-4" />
              ) : (
                <FaChevronRight className="w-4 h-4" />
              )}
            </button>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#eff3f5" }}
          className={`transition-all duration-300 ${
            isExpanded ? "w-[85%]" : "w-full"
          }`}
        >
          <Outlet />
        </div>
      </Row>
    </div>
  );
};

export default Index;
