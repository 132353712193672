import axios from "axios";

// Action Types
export const SET_LEAD_EXPLORER_VIEWS = "SET_LEAD_EXPLORER_VIEWS";
export const UPDATE_LEAD_EXPLORER_VIEW = "UPDATE_LEAD_EXPLORER_VIEW";
export const ADD_LEAD_EXPLORER_VIEW = "ADD_LEAD_EXPLORER_VIEW";

// Action Creators
export const setLeadExplorerViews = (views) => ({
  type: SET_LEAD_EXPLORER_VIEWS,
  payload: views,
});

export const updateLeadExplorerView = (updatedView) => ({
  type: UPDATE_LEAD_EXPLORER_VIEW,
  payload: updatedView,
});

export const addLeadExplorerView = (newView) => ({
  type: ADD_LEAD_EXPLORER_VIEW,
  payload: newView,
});

// Async Thunk to Fetch Lead Explorer Views
export const fetchLeadExplorerViews = (userId, userRole, userToken) => async (dispatch) => {
  if (!userId || !userRole || !userToken) {
    console.error("Missing required parameters: userId, userRole, or userToken");
    return;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CONVERSATION}/lead-explorer/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          Role: userRole,
          "Content-Type": "application/json",
        },
      }
    );    

    const views = response.data || [];  
    dispatch(setLeadExplorerViews(views));
  } catch (error) {
    console.error("Error fetching lead explorer views:", {
      message: error.message,
      stack: error.stack,
      response: error.response?.data,
    });
  }
};
