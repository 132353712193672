import React from "react";

function Footer() {
  return (
    <div className="mt-auto">
      <p className="text-xs text-center">
        <span className="text-gray-500 mr-1">Powered by</span>
        <a
          href="https://dexkor.com/"
          className="font-bold text-blue-800"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>DexKor</span>
        </a>
      </p>
    </div>
  );
}

export default Footer;
