import React, { useState, useEffect } from "react";
import axios from "axios";

const CouponsController = () => {
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [formData, setFormData] = useState({
    couponName: "",
    description: "",
    expirationDate: "",
    price: 0,
    couponImage: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("dexkorUserData")));
  }, []);

  useEffect(() => {
    fetchCoupons();
  }, [userData]);

  const fetchCoupons = async () => {
    if (!userData) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/get-all-coupons/${userData.uniquePartnerId}`
      );
      console.log("coupons fetched successfully");
      setCoupons(response.data.data);
    } catch (error) {
      console.error("Error in fetching coupons:", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    // Call the fetch function
    fetchUsers();
  }, []);

  const generateCouponCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    const length = Math.floor(Math.random() * 3) + 6;
    for (let i = 0; i < length; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newCoupon = {
      uniquePartnerId: userData.uniquePartnerId,
      userId: userData.userId,
      ...formData,
      couponCode: generateCouponCode(),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/create-coupons`,
        newCoupon
      );
      setCoupons([...coupons, response.data]);
      setShowForm(false);
      setFormData({
        couponName: "",
        description: "",
        expirationDate: "",
        price: 0,
        couponImage: "",
      });
      fetchCoupons();
    } catch (error) {
      console.error("Error creating coupon:", error);
    }
  };

  const handleDelete = async (couponId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/delete-coupon/${couponId}`
      );
      setCoupons(coupons.filter((coupon) => coupon._id !== couponId));
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/get-Hr/${userData.uniquePartnerId}`
        );
        setSelectedUsers(response.data.admins.map((admin) => admin._id)); // Assuming response data contains admin IDs
      } catch (error) {
        console.error("Error fetching admins:", error);
      } finally {
        setLoading(false);
      }
    };

    // Call the fetch function
    fetchAdmins();
  }, []);

  const handleCheckboxChange = async (userId) => {
    const isAdding = !selectedUsers.includes(userId);
    try {
      setLoading(true);
      if (isAdding) {
        // Add admin to database
        await axios.post(
          `${process.env.REACT_APP_CHAT_SERVER}/api/v1/starboard/add-Hr`,
          { userId }
        );
        setSelectedUsers((prev) => [...prev, userId]);
      } else {
        // Remove admin from database
        await axios.delete(`/api/admins/${userId}`);
        setSelectedUsers((prev) => prev.filter((id) => id !== userId));
      }
    } catch (error) {
      console.error(`Error ${isAdding ? "adding" : "removing"} admin:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveSelectedUser = async (userId) => {
    try {
      setLoading(true);
      // Remove admin from database
      await axios.delete(`/api/admins/${userId}`);
      setSelectedUsers((prev) => prev.filter((id) => id !== userId));
    } catch (error) {
      console.error("Error removing admin:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="p-6 bg-gray-100 h-full overflow-y-auto"
      style={{
        maxHeight: "100vh",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <h2 className="text-2xl font-bold mb-6 text-center">Your Coupons</h2>
      <div
        className="coupon-list grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-6 overflow-y-auto"
        style={{ maxHeight: "60vh" }}
      >
        {coupons.length === 0 ? (
          <p className="text-gray-600 text-center">Loading Coupons.</p>
        ) : (
          coupons.map((coupon) => (
            <div
              key={coupon._id}
              className="max-w-xs mx-auto p-4 border border-gray-300 rounded-lg shadow-md bg-white relative"
            >
              {coupon.couponImage ? (
                <img
                  src={coupon.couponImage}
                  alt="Coupon"
                  className="w-full h-32 object-cover rounded-t-lg mb-4"
                />
              ) : (
                <div className="w-full h-32 bg-gray-200 flex items-center justify-center rounded-t-lg mb-4 text-lg font-semibold text-gray-700">
                  {coupon.couponName}
                </div>
              )}
              <div>
                <p>
                  <strong className="font-semibold">Name:</strong>{" "}
                  {coupon.couponName}
                </p>
                <p>
                  <strong className="font-semibold">Description:</strong>{" "}
                  {coupon.description}
                </p>
                <p>
                  <strong className="font-semibold">Expiration Date:</strong>{" "}
                  {coupon.expirationDate}
                </p>
                <p>
                  <strong className="font-semibold">Coupon Code:</strong>{" "}
                  {coupon.couponCode}
                </p>
                <p>
                  <strong className="font-semibold">Price:</strong>{" "}
                  {coupon.price} Points
                </p>
              </div>
              <button
                onClick={() => handleDelete(coupon._id)}
                className="absolute top-2 right-2 bg-transparent text-blue-600 hover:bg-transparent hover:text-blue-950"
                title="Delete Coupon"
              >
                ✕
              </button>
            </div>
          ))
        )}
      </div>
      <hr className="my-6" />
      {/* Button for Creating Coupon */}
      <button
        onClick={() => setShowForm(!showForm)}
        className="w-full md:w-1/2 py-2 px-4 text-white font-semibold hover:bg-blue-800rounded-lg mb-6 mx-auto block"
        style={{
          borderRadius: "5px",
          background: "linear-gradient(90deg, #4c73de 0%, #2a4dad 100%)",
          boxShadow: "0px 4px 25px 0px #2a4dad40",
          color: "white",
        }}
      >
        {showForm ? "Cancel" : "Create Coupon"}
      </button>

      {/* Form for Creating Coupon */}
      {showForm && (
        <form
          onSubmit={handleSubmit}
          className="bg-white p-6 rounded-lg shadow-md w-full md:w-1/2 mx-auto space-y-4"
        >
          <div>
            <label className="block font-semibold mb-1">Coupon Name:</label>
            <input
              type="text"
              name="couponName"
              value={formData.couponName}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Description:</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Expiration Date:</label>
            <input
              type="date"
              name="expirationDate"
              value={formData.expirationDate}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">Price (Points):</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div>
            <label className="block font-semibold mb-1">
              Image URL (optional):
            </label>
            <input
              type="url"
              name="couponImage"
              value={formData.couponImage}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="https://example.com/image.jpg"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 text-white font-semibold rounded-lg"
            style={{
              borderRadius: "5px",
              background: "linear-gradient(90deg, #4c73de 0%, #2a4dad 100%)",
              boxShadow: "0px 4px 25px 0px #2a4dad40",
              color: "white",
            }}
          >
            Submit
          </button>
        </form>
      )}

      <hr className="my-6" />

      {/* HR Email Management */}
      <h3 className="text-xl font-semibold mb-4 text-center">
        Add admins for Starboard
      </h3>
      <div className="flex">
        {/* User List */}
        <div className="flex-1 space-y-2">
          <h3 className="text-xl font-semibold mb-4 text-center">
            Add admins for Starboard
          </h3>
          <div className="p-1 pl-3 mt-1 h-48 overflow-hidden overflow-y-auto">
            <ul className="mt-2 max-w-md divide-y divide-gray-300">
              {users.length > 0 &&
                users.map((user) => (
                  <li key={user._id} className="pt-2 pb-2 sm:pb-4">
                    <div className="flex items-center space-x-4 rtl:space-x-reverse">
                      <div className="flex-shrink-0 pt-0 items-center justify-center">
                        <input
                          id={`checkbox-${user._id}`}
                          type="checkbox"
                          checked={selectedUsers.includes(user._id)}
                          className="text-blue-600 bg-gray-100 border-gray-300 rounded"
                          onChange={() => handleCheckboxChange(user._id)}
                        />
                      </div>
                      <div className="flex-shrink-0">
                        <img
                          className="w-6 h-6 rounded-full"
                          src="https://pbs.twimg.com/media/BcINeMVCIAABeWd.jpg:large"
                          alt={user.name}
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {user.name}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {user.email}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {/* Selected Users */}
        <div className="flex-1 ml-4">
          <h3 className="text-lg font-semibold mb-4 text-center">
            Selected Admins
          </h3>
          <div className="space-y-2 border rounded p-4 h-48 overflow-y-auto">
            {loading ? (
              <p className="text-center text-gray-500">Loading...</p>
            ) : selectedUsers.length > 0 ? (
              selectedUsers.map((userId) => {
                const user = users.find((u) => u._id === userId);
                return (
                  <div
                    key={userId}
                    className="flex items-center justify-between px-2 py-1 bg-blue-100 rounded"
                  >
                    <span className="text-sm font-medium text-blue-900">
                      {user?.name || "Unknown User"}
                    </span>
                    <button
                      className="text-red-500 font-bold"
                      onClick={() => handleRemoveSelectedUser(userId)}
                    >
                      ×
                    </button>
                  </div>
                );
              })
            ) : (
              <p className="text-sm text-gray-500 text-center">
                No admins selected.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponsController;
