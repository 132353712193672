import React, { useEffect, useRef, useState } from "react";
import RenderAttachment from "../../components/RenderAttachment";
import DOMPurify from "dompurify";
import { Divider } from "antd";

const Conversation = ({ conversation }) => {
  const [userData, setUserData] = useState("");
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(userInfo);
  }, [conversation]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const removeQuotedText = (message) => {
    const quotedTextPattern =
      /(?:^On .+ wrote:|^From: .+|^\s*>.*(?:\n|$)|^>.*(?:\n|$))/gms;
    return message.replace(quotedTextPattern, "").trim();
  };

  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [conversation?.messages]);

  const findAgentDetails = (senderId) => {
    return conversation?.members?.find((member) => member._id === senderId);
  };

  // Define an array of background colors
  const backgroundColors = [
    "bg-red-300",
    "bg-green-300",
    "bg-blue-300",
    "bg-yellow-300",
    "bg-purple-300",
    "bg-pink-300",
    "bg-indigo-300",
    "bg-orange-300",
    "bg-teal-300",
    "bg-cyan-300",
    "bg-lime-300",
    "bg-amber-300",
    "bg-emerald-300",
    "bg-fuchsia-300",
    "bg-rose-300",
    "bg-sky-300",
    "bg-violet-300",
    "bg-gray-300",
    "bg-stone-300",
    "bg-amber-400",
    "bg-cyan-400",
    "bg-blue-400",
    "bg-indigo-400",
    "bg-orange-400",
    "bg-teal-400",
    "bg-pink-400",
    "bg-lime-400",
    "bg-purple-400",
    "bg-rose-400",
    "bg-sky-400",
    "bg-green-400",
    "bg-red-400",
    "bg-violet-400",
  ];
  const getColorForOwner = (name) => {
    if (!name) return backgroundColors[0]; // default color if no name
    const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return backgroundColors[hash % backgroundColors.length];
  };

  return (
    <div ref={chatContainerRef}>
      {conversation ? (
        conversation?.messages?.length > 0 ? (
          conversation?.messages.map((msg, index) => {
            const currentMessageDate = new Date(
              msg.timestamp
            ).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });

            const previousMessageDate =
              index > 0
                ? new Date(
                    conversation.messages[index - 1].timestamp
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : null;

            const agentDetails =
              msg.sender === "Agent" ? findAgentDetails(msg.senderId) : null;

            return (
              <div key={msg.id}>
                {currentMessageDate !== previousMessageDate && (
                  <div
                    style={{
                      textAlign: "center",
                      margin: "1rem 0",
                      fontWeight: "bold",
                    }}
                  >
                    <Divider>
                      <p
                        style={{
                          fontFamily: "roboto",
                          fontSize: "12px",
                          color: "#A0A7AF",
                        }}
                      >
                        {currentMessageDate}
                      </p>
                    </Divider>
                  </div>
                )}
                <div>
                  {msg.sender === "Customer" ? (
                    <div
                      key={index}
                      className="flex justify-end mb-4 items-center"
                    >
                      <div>
                        <div className="pt-3 flex">
                          <div
                            className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                              agentDetails?.name
                            )}`}
                          >
                            {agentDetails?.name
                              ? agentDetails?.name
                                  .split(" ") // Split the name into words
                                  .filter((word) => word.trim().length > 0) // Ensure no empty strings are included
                                  .reduce(
                                    (acc, word, idx, arr) =>
                                      idx === 0 || idx === arr.length - 1
                                        ? acc + word[0]
                                        : acc, // Take the first letter of the first and last words
                                    ""
                                  )
                                  .toUpperCase() // Convert to uppercase
                              : "--"}
                          </div>
                          {msg.message && (
                            <div className="block max-w-96 bg-gray-200 text-black rounded-lg p-2">
                              <div className="flex gap-x-6 ">
                                <span
                                  style={{
                                    fontWeight: "700",
                                    fontFamily: "roboto",
                                  }}
                                >
                                  {agentDetails?.name || "Unknown Agent"}
                                </span>
                                <span className="ml-auto text-xs text-gray-500">
                                  {new Date(msg?.timestamp).toLocaleString(
                                    "en-US",
                                    {
                                      hour: "numeric",
                                      minute: "2-digit",
                                      hour12: true,
                                    }
                                  )}
                                </span>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(msg.message),
                                }}
                              />
                            </div>
                          )}
                          {msg.attachment && (
                            <div
                              className={`flex max-w-96 bg-gray-500 text-white ${
                                msg.attachmentType.includes("audio")
                                  ? "rounded-full"
                                  : "rounded-lg"
                              } p-1 gap-3`}
                            >
                              <RenderAttachment
                                attachment={msg.attachment}
                                attachmentType={msg.attachmentType}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className="flex mb-4 cursor-pointer">
                      <div>
                        <div className="pt-1 flex items-center gap-2"></div>
                        <div className="pt-3 flex">
                          <div
                            className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                              conversation?.ticketId?.name
                            )}`}
                          >
                            {conversation.ticketId?.name
                              ? conversation.ticketId?.name
                                  .split(" ") // Split the name into words
                                  .filter((word) => word.trim().length > 0) // Ensure no empty strings are included
                                  .reduce(
                                    (acc, word, idx, arr) =>
                                      idx === 0 || idx === arr.length - 1
                                        ? acc + word[0]
                                        : acc, // Take the first letter of the first and last words
                                    ""
                                  )
                                  .toUpperCase() // Convert to uppercase
                              : "--"}
                          </div>
                          {msg.message && (
                            <div className="block max-w-96 bg-blue-200 rounded-lg p-2">
                              <div className="flex gap-x-6">
                                <span
                                  style={{
                                    fontWeight: "700",
                                    fontFamily: "roboto",
                                  }}
                                >
                                  {conversation?.ticketId?.name}
                                </span>

                                <span className="ml-auto text-xs text-gray-500">
                                  {new Date(msg?.timestamp).toLocaleString(
                                    "en-US",
                                    {
                                      hour: "numeric",
                                      minute: "2-digit",
                                      hour12: true,
                                    }
                                  )}
                                </span>
                              </div>
                              <div
                                className="text-gray-900"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    removeQuotedText(msg.message)
                                  ),
                                }}
                              />
                            </div>
                          )}
                          {msg.attachment && (
                            <div
                              className={`flex max-w-96 bg-blue-500 ${
                                msg.attachmentType.includes("audio")
                                  ? "rounded-full"
                                  : "rounded-lg"
                              } p-1 gap-3`}
                            >
                              <RenderAttachment
                                attachment={msg.attachment}
                                attachmentType={msg.attachmentType}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <section
            style={{
              backgroundColor: "#F6F7F9",
            }}
          >
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-3xl tracking-tight font-extrabold lg:text-5xl text-blue-600">
                  No Chat Here.
                </h1>
                <p className="mb-4 text-xl text-gray-400 tracking-tight md:text-xl">
                  It seems that no chat has taken place in here.
                </p>
              </div>
            </div>
          </section>
        )
      ) : (
        <p>Pick a ticket to start chatting</p>
      )}
    </div>
  );
};

export default Conversation;
