import React from "react";

const Event = () => {
  return (
    <div>
      <img
        width="24"
        height="24"
        src="https://img.icons8.com/3d-fluency/94/today.png"
        alt="today"
      />
    </div>
  );
};

export default Event;
