import React, { useState, useEffect } from "react";
import { Button, Input, Select, Form, Card, Row, Col } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const Details = () => {
  const location = useLocation();
  const initialContact = location.state?.selectedContact;
  const [isEditing, setIsEditing] = useState(false);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();

  const userData = JSON.parse(localStorage.getItem("dexkorUserData"));

  // Fetch user data for Lead Owner dropdown
  const fetchUsers = async () => {
    if (userData) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/all/${userData.uniquePartnerId}`
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Fetch contact details and autopopulate the form
  const fetchSelectedContact = async () => {
    if (userData) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/get-contact/${userData.uniquePartnerId}/${initialContact.contactId}`
        );
        form.setFieldsValue(response.data); // Set form fields with API response
      } catch (error) {
        console.error("Error fetching selected contact:", error);
      }
    }
  };

  useEffect(() => {
    fetchSelectedContact();
  }, []);



  // Handle form submission
  const handleUpdate = async (values) => {
    setIsEditing(false);
    try {
      await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/update-contact/${userData.uniquePartnerId}/${initialContact.contactId}`,
        values
      );
    } catch (error) {
      console.error("Error updating contact:", error);
    }
  };

  // Handle cancel editing
  const handleCancel = () => {
    setIsEditing(false);
    fetchSelectedContact(); // Reset form to initial API values
  };

  return (
    <Card className="p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Contact Details</h2>
        {!isEditing ? (
          <Button type="primary" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        ) : (
          <div className="space-x-2">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit(); // Trigger form submission
              }}
            >
              Update
            </Button>
          </div>
        )}
      </div>

      {/* Form */}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleUpdate}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.subStage !== undefined) {
            // Define the mapping of subStage to stage
            const subStageToStageMap = {
              "unaware": "Awareness",
              "problem-aware": "Awareness",
              "solution-aware": "Awareness",
              "brand-aware": "Awareness",
              "engaged": "Interest",
              "nurtured-lead": "Interest",
              "product-aware": "Interest",
              "researching-option": "Consideration",
              "qualified-lead": "Consideration",
              "": null,
            };
      
            // Update the stage based on the subStage value
            const updatedStage = subStageToStageMap[changedValues.subStage] || allValues.stage;
      
            // Set the updated stage value in the form
            form.setFieldsValue({ stage: updatedStage });
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "First Name is required" }]}
            >
              <Input disabled={!isEditing} placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Last Name is required" }]}
            >
              <Input disabled={!isEditing} placeholder="Enter last name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Company Name"
              name="companyName"
            >
              <Input disabled={!isEditing} placeholder="Enter company name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Title"
              name="title"
            >
              <Select disabled={!isEditing} placeholder="Select title">
                <Option value="Manager">Manager</Option>
                <Option value="Director">Director</Option>
                <Option value="Executive">Executive</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Sub Stage"
              name="subStage"
              rules={[{ required: true, message: "Sub Stage is required" }]}
            >
              <Select disabled={!isEditing} placeholder="Select sub stage">
                <Option value="unaware">Unaware</Option>
                <Option value="solution-aware">Solution Aware</Option>
                <Option value="brand-aware">Brand Aware</Option>
                <Option value="engaged">Engaged</Option>
                <Option value="nurtured-lead">Nurtured lead</Option>
                <Option value="researching-option">Researching Option</Option>
                <Option value="qualified-lead">Qualified Lead</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Lead Owner"
              name="leadOwner"
            >
              <Select disabled={!isEditing} placeholder="Select lead owner">
                {users.map((user) => (
                  <Option key={user.id} value={user.name}>
                    {user.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Phone is required" }]}
            >
              <Input disabled={!isEditing} placeholder="Enter phone number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Lead Source"
              name="leadSource"
            >
              <Input disabled={!isEditing} placeholder="Enter lead source" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Website"
              name="website"
            >
              <Input disabled={!isEditing} placeholder="Enter website URL" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Tag"
              name="tags"
            >
             <Select
    mode="tags"
    disabled={!isEditing}
    placeholder="Select or create tags (e.g., Marketing, Sales)"
    tokenSeparators={[","]}
  >
    {/* Predefined options */}
    <Option value="Marketing">Marketing</Option>
    <Option value="Sales">Sales</Option>
    <Option value="Lead">Lead</Option>
    <Option value="Support">Support</Option>
  </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
      <Form.Item name="stage" style={{ display: "none" }}>
        <Input />
      </Form.Item>
    </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Details;
