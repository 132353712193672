import React, { useState, useEffect } from "react";
import { Drawer, Button, Modal, Input, Form, Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Notes = () => {
  const location = useLocation();
  const initialContact = location.state?.selectedContact;
  const [selectedContact, setSelectedContact] = useState(initialContact);
  const [notes, setNotes] = useState(selectedContact?.notes || []);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedNoteComments, setSelectedNoteComments] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingNote, setEditingNote] = useState(null);

  const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
  const user = userData?.email || null;

  const handleShowComments = (comments) => {
    setSelectedNoteComments(comments);
    setDrawerVisible(true);
  };

  const fetchSelectedContact = async () => {
    if(userData){
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/get-contact-notes/${initialContact.contactId}`
      );      
      setNotes(response.data || []);
    } catch (error) {
      console.error("Error fetching selected contact:", error);
    }
  }
  };

  useEffect(() => {
    fetchSelectedContact();
  }, []);

  const handleCreateNote = async (values) => {
    try {
      const newNote = {
        ...values,
        createdBy: user,
        contactId: initialContact?.contactId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/create-contact-notes`,
        newNote
      );

      setNotes((prevNotes) => [...prevNotes, response.data]);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error("Error creating note:", error);
    }
  };

  const handleEditNote = (note) => {
    setEditingNote(note);
    setIsEditModalVisible(true);
  };

  const handleUpdateNote = async (values) => {
    try {
      const updatedNote = { ...editingNote, ...values };

      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/update-contact-notes/${editingNote.contactId}/${editingNote.notesId}`,
        updatedNote
      );

      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.notesId === editingNote.notesId ? response.data.updatedContactNotes : note
        )
      );
      setIsEditModalVisible(false);
      setEditingNote(null);
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  const menu = (note) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEditNote(note)}>
        Edit
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="p-4">
      {/* Navbar */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">
          Notes ({notes?.length})
        </h2>
        <Button type="primary" onClick={() => setIsCreateModalVisible(true)}>
          Create Note
        </Button>
      </div>

      {/* Notes Body */}
      <div className="grid grid-cols-1 gap-4">
        {notes.map((note) => (
          <div
            key={note?.notesId}
            className="bg-white shadow-md rounded-lg p-4 w-full"
          >
            <div className="flex justify-between items-center">
              <h3 className="text-md font-semibold">{note?.subject}</h3>
              {/* Dropdown */}
              {note?.createdBy === user && (
                <Dropdown overlay={menu(note)} trigger={["click"]}>
                  <EllipsisOutlined
                    className="text-gray-500 cursor-pointer"
                    style={{ fontSize: "20px" }}
                  />
                </Dropdown>
              )}
            </div>
            <p className="text-sm text-gray-600 mt-2">{note.description}</p>
            {/* Comments */}
            <div className="mt-4">
              <div
                className="text-blue-600 cursor-pointer"
                onClick={() => handleShowComments(note.comments)}
              >
                Comments ({note.comments?.length || 0})
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Comments Drawer */}
      <Drawer
        title="Comments"
        placement="right"
        width={800}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        {selectedNoteComments?.length > 0 ? (
          selectedNoteComments.map((comment, index) => (
            <div
              key={index}
              className="border-b border-gray-200 pb-2 mb-2 text-gray-700"
            >
              <p className="text-sm">{comment.text}</p>
              <p className="text-xs text-gray-500">
                {comment.authorEmail} -{" "}
                {new Date(comment.date).toLocaleString()}
              </p>
            </div>
          ))
        ) : (
          <p>No comments available</p>
        )}
      </Drawer>

      {/* Create Note Modal */}
      <Modal
        title="Create Note"
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleCreateNote}>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: "Please enter the subject" }]}
          >
            <Input placeholder="Enter note subject" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Enter note description" />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => setIsCreateModalVisible(false)}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </div>
        </Form>
      </Modal>

      {/* Edit Note Modal */}
      <Modal
        title="Edit Note"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          initialValues={{
            subject: editingNote?.subject,
            description: editingNote?.description,
          }}
          onFinish={handleUpdateNote}
        >
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: "Please enter the subject" }]}
          >
            <Input placeholder="Enter note subject" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Enter note description" />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => setIsEditModalVisible(false)}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Notes;
