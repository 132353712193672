import React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import AdSuite from "../AdSuite";
import Index from "../Index";
import LeadExplorer from "../LeadExplorer/LeadExplorer";
import PrivateRoute from "../../Private Route/PrivateRoute";
import LeadViewer from "../LeadExplorer/components/LeadViewer/LeadViewer";
import CampaignViewer from "../CampaignExplorer/components/Campaign Viewer/campaignViewer";
import { useSelector } from "react-redux";
import CampaignExplorer from "../CampaignExplorer/CampaignExplorer";

const AdSuiteRoute = () => {
  const leadExplorerViews = useSelector((state) => state.leadExplorer?.views);
  const campaignExplorerViews = useSelector((state) => state.campaignExplorer?.views);

  // Generate routes for Lead Explorer
  const renderLeadExplorerRoutes = () =>
    leadExplorerViews?.length > 0 ? (
      leadExplorerViews?.map((view) => (
        <Route
          key={view._id}
          path={view.name.replace(/\s+/g, "-").toLowerCase()}
          element={
            <PrivateRoute>
              <LeadExplorer viewData={view} />
            </PrivateRoute>
          }
        />
      ))
    ) : (
      <Route path="*" element={<div>No views available for Lead Explorer</div>} />
    );

  // Generate routes for Campaign Explorer
  const renderCampaignExplorerRoutes = () =>
    campaignExplorerViews?.length > 0 ? (
      campaignExplorerViews?.map((view) => (
        <Route
          key={view._id}
          path={view.name.replace(/\s+/g, "-").toLowerCase()}
          element={
            <PrivateRoute>
              <CampaignExplorer />
            </PrivateRoute>
          }
        />
      ))
    ) : (
      <Route path="*" element={<div>No views available for Campaign Explorer</div>} />
    );

  return (
 
      <Route
        path="/adsuite"
        element={
          <PrivateRoute>
            <Index />
          </PrivateRoute>
        }
      >
        {/* Lead Explorer Routes */}
        <Route path="lead-explorer">
          {renderLeadExplorerRoutes()}
          <Route
            path=":viewName/:id"
            element={
              <PrivateRoute>
                <LeadViewer />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="custom-view-explorer">
          {renderLeadExplorerRoutes()}
          <Route
            path=":viewName/:id"
            element={
              <PrivateRoute>
                <LeadViewer />
              </PrivateRoute>
            }
          />
        </Route>

        {/* Campaign Routes */}
        <Route path="campaign">
          {/* Displaying the Campaign Explorer for general listing */}
          <Route path="" element={<CampaignExplorer />} />

          {/* Displaying a specific campaign based on :id */}
          <Route
            path=":id"
            element={
              <PrivateRoute>
                <CampaignViewer />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>

  );
};

export default AdSuiteRoute;
