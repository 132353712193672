import React, { useEffect, useState, useRef } from "react";
import "./AppBar.css";
import axios from "axios";
import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  Typography,
  Input,
  Button,
  Tooltip,
} from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router-dom";

const { Header } = Layout;

const AppBar = () => {
  const navig = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  const [setupDetails, setSetupDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null)
  const searchInputRef = useRef(null); // Create a ref for the search input field

  useEffect(() => {
    const getPartnerDetails = async () => {
        const accessURL = window.location.origin ;
  
        if (accessURL) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_CONVERSATION}/partners/verify-login`,
              { accessUrl: accessURL }
            );         
            setSetupDetails(response.data.setup)
            localStorage.setItem("uniqueId", response.data.setup.uniquePartnerId)
          } catch (error) {
            console.error('Error verifying partner login:', error);
            console.log(error);
            
          }
        }
      };

      const getCustomer = async() => {
        const data = JSON.parse(localStorage.getItem("customerDetails"))
        const uniqueId = localStorage.getItem("uniqueId")
        if(data && uniqueId){
          try {
            const response = await axios.get(`${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/portal/get-customer/${uniqueId}/${data.email}`)
            setUserDetails(response.data); 
          } catch (error) {
            console.error('Error verifying partner login:', error);
          }
        }
      }
  
    getPartnerDetails();
    getCustomer();
  }, [])

  const handleMenuClick = ({ key }) => {
    if (key === "profile") {
      navig("/portal/home/profile")
    } else if (key === "logout") {
      localStorage.removeItem("customerDetails");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiry");
      navig("/portal/login");
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Focus the search input when it becomes visible
  useEffect(() => {
    if (searchVisible && searchInputRef.current) {
      searchInputRef.current.focus(); // Focus the input when it is displayed
    }
  }, [searchVisible]);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        My Profile
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} danger>
        Logout
      </Menu.Item>
    </Menu>
  );

  const handleCreateTicket = () => {
    navig("/portal/home/new-ticket-create")
  }

  // Fallback logic for user image (use first letter of name if image is not available)
  const avatarContent = userDetails?.image || (userDetails?.name ? userDetails.name[0] : 'U');

  return (
    <>
      <Header
        style={{ backgroundColor: "#2A4DAD" }}
        className="flex justify-between items-center px-6 shadow-md"
      >
        <div className="flex items-center">
          {!isMobileView && (
            <img
              src={setupDetails?.widgetCustomizations?.logoUrl || `https://via.placeholder.com/40/0000FF/808080?text=${userDetails?.name?.[0]}`}
              alt="Company Logo"
              style={{ width: "40px", height: "40px" }}
              className="mr-3"
            />
          )}
          {!isMobileView && (
            <Typography.Title
              level={4}
              className="m-0"
              style={{
                color: "white",
                marginLeft: isMobileView ? "30px" : "0px",
                cursor: "pointer"
              }}
              onClick={() => navig("/portal/home/all-tickets")}
            >
             {setupDetails?.widgetCustomizations?.partnerName || "Partner Name"} 
            </Typography.Title>
          )}
        </div>

        <div className="flex items-center">
          {isMobileView ? (
            <>
              <Tooltip title="Search">
                <Button
                  type="text"
                  icon={<SearchOutlined style={{ color: "white" }} />}
                  onClick={() => setSearchVisible(!searchVisible)} // Toggle search visibility
                  style={{ marginRight: 8 }}
                />
              </Tooltip>
              <Tooltip title="Create New Ticket">
                <Button
                  type="text"
                  icon={<PlusOutlined style={{ color: "white" }} />}
                  onClick={handleCreateTicket}
                />
              </Tooltip>
              {searchVisible && (
                <Input
                  ref={searchInputRef} // Attach the ref to the input
                  placeholder="Search tickets"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "60px", // Added left margin for expanded view
                    width: "50%",
                    transition: "width 0.3s",
                  }}
                  onBlur={() => setSearchVisible(false)} // Hide on blur
                />
              )}
            </>
          ) : (
            <>
              <Input
                placeholder="Search tickets"
                style={{ width: "25vw", display: 'flex', alignItems: "center", justifyContent: "center" }}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleCreateTicket}
              >
                Create Ticket
              </Button>
            </>
          )}
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={["click"]}
            arrow
          >
            <Avatar
              size="large"
              src={userDetails?.image || undefined} // If image is not available, fallback to the text avatar
              style={{ cursor: "pointer", marginLeft: 16 }}
            >
              {avatarContent}
            </Avatar>
          </Dropdown>
        </div>
      </Header>

      <div>
        <Outlet context={{ searchQuery, setupDetails, userDetails }} />
      </div>
    </>
  );
};

export default AppBar;
