import React, { useEffect, useRef, useState } from "react";
import { List, Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "axios";
import useNotification from "./snackbars/SnackBar";
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

function TicketDetails({
  selectedConversation,
  setConversations,
  setSelectedConvo,
}) {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const data = [selectedConversation];
  const [conf, setConf] = useNotification();
  const [searchQuery, setSearchQuery] = useState(null);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropDown] = useState(false);
  const [showOwnerDropdown, setShowOwnerDropDown] = useState(false);
  const [page, setPage] = useState(1);
  const dropdownRef = useRef();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true); // Whether more users are available
  const [limit, setLimit] = useState(4); // Number of users per page
  const [loggedInUserData, setLoggedInUserData] = useState(null);

  // Fetch logged-in user data
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userData) setLoggedInUserData(userData);
  }, []);

  // Fetch users based on page and limit
  const fetchUsers = async (reset = false) => {
    if (loading || !hasMore || !loggedInUserData) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/chunks/${loggedInUserData.uniquePartnerId}?page=${page}&limit=${limit}`
      );
      const fetchedUsers = response.data.users;

      if (response.status === 204) {
        setHasMore(false); // Stop fetching when no more users are available
      } else {
        setUsers((prevUsers) =>
          reset ? fetchedUsers : [...prevUsers, ...fetchedUsers]
        );
        setPage((prevPage) => prevPage + 1); // Increment the page
      }
    } catch (error) {
      setConf({ msg: "Error fetching users!", variant: "error" });
      console.error("Error fetching users: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch users when the dropdown is shown for the first time
  useEffect(() => {
    if (showOwnerDropdown || showAssigneeDropdown) {
      fetchUsers(true); // Fetch users for the first time or reset if dropdown is reopened
    }
  }, [showOwnerDropdown, showAssigneeDropdown]);

  // Infinite scroll logic for dropdown
  useEffect(() => {
    const dropdownElement = dropdownRef.current; // Reference the dropdown element
    const handleScroll = () => {
      if (
        dropdownElement &&
        dropdownElement.scrollTop + dropdownElement.clientHeight >=
          dropdownElement.scrollHeight &&
        !loading
      ) {
        fetchUsers(); // Fetch more users on scroll
      }
    };

    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, page]);

  useEffect(() => {
    // Define an async function to filter all users
    const searchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${loggedInUserData.uniquePartnerId}`,
          {
            params: { query: searchQuery }, // Pass the query as a query parameter
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call the fetch function
    if (searchQuery) {
      searchUsers();
    }
  }, [searchQuery]);

  const updateAssigneeOrOwner = (
    assignee,
    ownerId,
    ownerName,
    assigneeName
  ) => {
    socket.emit(
      "updateConversationDetails",
      {
        conversationId: selectedConversation._id,
        assignee,
        ownerId,
      },
      (response) => {
        if (response.success) {
          // Check if the logged-in user is the owner of the conversation
          const isOwner = selectedConversation?.owner?._id === ownerId;
          setConversations((prevConversations) => {
            const updatedConversations = prevConversations.map(
              (conversation) =>
                conversation._id === response.conversation._id
                  ? response.conversation // Replace with the updated conversation
                  : conversation // Keep the existing conversation
            );

            // Set the selected conversation to the updated conversation
            setSelectedConvo(response.conversation);
            return updatedConversations; // Return the updated list
          });
          setShowAssigneeDropDown(false);
          setShowOwnerDropDown(false);
          if (!isOwner) {
            setConf({
              msg: `Ticket ownership transfered to ${ownerName}`,
              variant: "success",
            });
          } else {
            setConf({
              msg: `Ticket assigned to ${assigneeName}`,
              variant: "success",
            });
          }
        } else {
          setConf({
            msg: "error occurred while changing the ticket details",
            variant: "error",
          });
          console.error("Error assigning ticket:", response.message);
        }
      }
    );
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
      const response = await axios.get(
        `${process.env.REACT_APP_CONVERSATION}/company/search/${userInfo.uniquePartnerId}?keyword=${searchQuery}`
      );
      setCustomers(response.data);
    };
    fetchCustomers();
  }, [searchQuery]);

  const handleUpdateCustomer = async (companyData) => {
    const companyId = companyData._id;
    const assigneeId = companyData.customerAssignedTo;

    if (!assigneeId) {
      setConf({
        msg: "This customer does not have an account manager.",
        variant: "warning",
      });
      return;
    }
    try {
      const status = "Assigned";
      const token = localStorage.getItem("dexkorUserToken");
      const response = await axios.put(
        `${process.env.REACT_APP_CONVERSATION}/tickets/${selectedConversation?.ticketId?._id}/cc-bcc`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
            Role: "user", // Add the Role header
            "Content-Type": "application/json", // Optional: set content type
          },
        }
      );

      const res = await axios.patch(
        `${process.env.REACT_APP_CONVERSATION}/conversations/update/${selectedConversation._id}`,
        { assigneeId, companyId }
      );

      const fullName = selectedConversation?.ticketId?.name || "---";
      const nameParts = fullName.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(" ") || "---";

      const values = {
        firstName,
        lastName,
        email: selectedConversation?.ticketId?.email || "---",
        phone: selectedConversation?.ticketId?.number || "---",
        location: "---",
        designation: "---",
        userType: "Point Of Contact",
      };
      const res2 = await axios.post(
        `${process.env.REACT_APP_CONVERSATION}/company/${companyData.companyID}/addPoc`,
        values // Use the values from the form
      );

      if (res2.status === 200) {
        console.log({ companyData });
        setConversations((prevConversations) =>
          prevConversations.map((conversation) =>
            conversation._id === selectedConversation._id
              ? {
                  ...conversation,
                  customerDetails: companyData,
                  ticketId: {
                    ...conversation.ticketId, // Spread the existing ticketId object
                    status: "Assigned", // Update the status field
                  },
                }
              : conversation
          )
        );
        setSelectedConvo((prevConversation) => ({
          ...prevConversation,
          customerDetails: companyData,
          ticketId: {
            ...prevConversation.ticketId, // Spread the existing ticketId object
            status: "Assigned", // Update the status field
          },
        }));
        setShowCompanyDropdown(false);
      }
    } catch (error) {
      console.error("Error occurred while adding poc details:", error);
    }
  };

  return (
    <div>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          backgroundColor: "white",
        }}
        items={[
          {
            key: "1",
            label: (
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: "roboto",
                }}
              >
                {" "}
                Ticket Details{" "}
              </div>
            ),
            children: (
              <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.roomId}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Subject: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.subject || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Description: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.description || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Status: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.status || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Ticket Type: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.type || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Priority: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.priority || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Category: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId?.category || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    {item.owner && (
                      <>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontFamily: "roboto",
                              fontWeight: "400",
                            }}
                          >
                            <span style={{ color: "#A0A7AF" }}>owner: </span>

                            <span style={{ color: "#2A4DAD" }}>
                              <div>
                                <div
                                  className="cursor-pointer font-medium rounded-lg text-sm text-center inline-flex items-center"
                                  onClick={() => {
                                    setShowOwnerDropDown(!showOwnerDropdown);
                                    setPage(1);
                                    setShowAssigneeDropDown(false);
                                  }}
                                >
                                  {item?.owner?.name || "---"}{" "}
                                  <svg
                                    className="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="m1 1 4 4 4-4"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </span>
                          </div>{" "}
                          {/* <!-- Owner Dropdown menu --> */}
                          {showOwnerDropdown && !showAssigneeDropdown && (
                            <div className="flex justify-end">
                              <div className="absolute z-10 bg-white rounded-lg shadow w-60">
                                <ul
                                  id="dropdownUsers"
                                  className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                  aria-labelledby="dropdownUsersButton"
                                  ref={dropdownRef}
                                >
                                  {users.length > 0 &&
                                    users.map((user, index) => (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          updateAssigneeOrOwner(
                                            item?.assignee?._id || null,
                                            user._id,
                                            user.name,
                                            item?.assignee?.name || null
                                          )
                                        }
                                      >
                                        <a
                                          href="#"
                                          className="flex items-center px-4 py-2 hover:bg-gray-100 text-wrapper"
                                        >
                                          {user?.image ? (
                                            <img
                                              className="w-6 h-6 me-2 mb-3 rounded-full"
                                              src={user.image}
                                              alt="User image"
                                            />
                                          ) : (
                                            <div className="w-6 h-6 me-2 mb-3 rounded-full bg-gray-300 flex items-center justify-center text-white">
                                              {user?.name?.charAt(0)}
                                            </div>
                                          )}
                                          <span className="flex-1 overflow-hidden">
                                            <p className="text-sm font-medium text-gray-800 truncate">
                                              {user.name}
                                            </p>
                                            <span className="text-xs text-gray-600 truncate">
                                              {user.email}
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    ))}
                                  {loading && (
                                    <li className="text-center">Loading...</li>
                                  )}
                                </ul>
                                <div className="flex items-center p-2 text-xs font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                                  <input
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Search user..."
                                    className="w-full px-1 py-0.5 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <br />
                        </div>
                        <br />{" "}
                      </>
                    )}
                    {item.assignee && (
                      <>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontFamily: "roboto",
                              fontWeight: "400",
                            }}
                          >
                            <span style={{ color: "#A0A7AF" }}>Assignee: </span>

                            <span style={{ color: "#2A4DAD" }}>
                              <div>
                                <div
                                  className="cursor-pointer font-medium rounded-lg text-sm text-center inline-flex items-center"
                                  onClick={() => {
                                    setShowAssigneeDropDown(
                                      !showAssigneeDropdown
                                    );
                                    setPage(1);
                                    setShowOwnerDropDown(false);
                                  }}
                                >
                                  {item?.assignee?.name || "---"}{" "}
                                  <svg
                                    className="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="m1 1 4 4 4-4"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </span>
                          </div>{" "}
                          {/* <!-- Owner Dropdown menu --> */}
                          {!showOwnerDropdown && showAssigneeDropdown && (
                            <div className="flex justify-end">
                              <div className="absolute z-10 bg-white rounded-lg shadow w-60">
                                <ul
                                  id="dropdownUsers"
                                  className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                  aria-labelledby="dropdownUsersButton"
                                  ref={dropdownRef}
                                >
                                  {users.length > 0 &&
                                    users.map((user, index) => (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          updateAssigneeOrOwner(
                                            user._id,
                                            item?.owner?._id || null,
                                            item?.owner?.name || null,
                                            user.name
                                          )
                                        }
                                      >
                                        <a
                                          href="#"
                                          className="flex items-center px-4 py-2 hover:bg-gray-100 text-wrapper"
                                        >
                                          {user?.image ? (
                                            <img
                                              className="w-6 h-6 me-2 mb-3 rounded-full"
                                              src={user.image}
                                              alt="User image"
                                            />
                                          ) : (
                                            <div className="w-6 h-6 me-2 mb-3 rounded-full bg-gray-300 flex items-center justify-center text-white">
                                              {user?.name?.charAt(0)}
                                            </div>
                                          )}
                                          <span className="flex-1 overflow-hidden">
                                            <p className="text-sm font-medium text-gray-800 truncate">
                                              {user.name}
                                            </p>
                                            <span className="text-xs text-gray-600 truncate">
                                              {user.email}
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                    ))}
                                  {loading && (
                                    <li className="text-center">Loading...</li>
                                  )}
                                </ul>
                                <div className="flex items-center p-2 text-xs font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                                  <input
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Search user..."
                                    className="w-full px-1 py-0.5 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                  />
                                </div>
                              </div>
                              <ul
                                id="dropdownUsers"
                                className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                aria-labelledby="dropdownUsersButton"
                                ref={dropdownRef}
                              >
                                {customers.length > 0 &&
                                  customers.map((customer, index) => (
                                    <li
                                      className="cursor-pointer"
                                      key={index}
                                      onClick={() =>
                                        handleUpdateCustomer(customer)
                                      }
                                    >
                                      <p className="flex items-center px-4 py-2 hover:bg-gray-100 text-wrapper">
                                        <span className="flex-1 overflow-hidden">
                                          <p className="text-sm font-medium text-gray-800 truncate">
                                            {customer.companyName}
                                          </p>
                                          <span className="text-xs text-gray-600 truncate">
                                            {customer.customerCode}
                                          </span>
                                        </span>
                                      </p>
                                    </li>
                                  ))}
                                {loading && (
                                  <li className="text-center">Loading...</li>
                                )}
                              </ul>
                            </div>
                          )}
                          <br />
                        </div>
                        <br />
                      </>
                    )}
                    {item.ticketId?.parentTicketNumber && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "roboto",
                            fontWeight: "400",
                          }}
                        >
                          <span style={{ color: "#A0A7AF" }}>
                            Parent Ticket Number:{" "}
                          </span>
                          <span style={{ color: "#2A4DAD" }}>
                            {item.ticketId.parentTicketNumber || "---"}
                          </span>
                        </div>{" "}
                        <br />
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Tags: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.tags || "---"}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            ),
            style: panelStyle,
          },
        ]}
      />
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          backgroundColor: "white",
        }}
        items={[
          {
            key: "1",
            label: (
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: "roboto",
                }}
              >
                {" "}
                Customer Details{" "}
              </div>
            ),
            children: (
              <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.roomId}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Company Name: </span>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "roboto",
                            fontWeight: "400",
                          }}
                        >
                          <span style={{ color: "#2A4DAD" }}>
                            <div>
                              <div
                                className="cursor-pointer font-medium rounded-lg text-sm text-center inline-flex items-center"
                                onClick={() => {
                                  setShowCompanyDropdown(!showCompanyDropdown);
                                  setPage(1);
                                }}
                              >
                                <span style={{ color: "#2A4DAD" }}>
                                  {item?.customerDetails?.companyName || "---"}
                                </span>{" "}
                                <svg
                                  className="w-2.5 h-2.5 ms-3"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 10 6"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m1 1 4 4 4-4"
                                  />
                                </svg>
                              </div>
                            </div>
                          </span>
                        </div>{" "}
                        {/* <!-- Owner Dropdown menu --> */}
                        {showCompanyDropdown && (
                          <div className="flex justify-end">
                            <div className="absolute z-10 bg-white rounded-lg shadow w-60">
                              <div className="flex items-center p-2 text-xs font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
                                <input
                                  value={searchQuery}
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  }
                                  type="text"
                                  placeholder="Search company..."
                                  className="w-full px-1 py-0.5 text-xs bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                />
                              </div>
                              <ul
                                id="dropdownUsers"
                                className="max-h-48 py-2 overflow-y-auto text-gray-700"
                                aria-labelledby="dropdownUsersButton"
                                ref={dropdownRef}
                              >
                                {customers.length > 0 &&
                                  customers.map((customer, index) => (
                                    <li
                                      className="cursor-pointer"
                                      key={index}
                                      onClick={() =>
                                        handleUpdateCustomer(customer)
                                      }
                                    >
                                      <p className="flex items-center px-4 py-2 hover:bg-gray-100 text-wrapper">
                                        <span className="flex-1 overflow-hidden">
                                          <p className="text-sm font-medium text-gray-800 truncate">
                                            {customer.companyName}
                                          </p>
                                          <span className="text-xs text-gray-600 truncate">
                                            {customer.customerCode}
                                          </span>
                                        </span>
                                      </p>
                                    </li>
                                  ))}
                                {loading && (
                                  <li className="text-center">Loading...</li>
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                        <br />
                      </div>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Customer code: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item?.customerDetails?.customerCode || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Name: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.name || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>Email: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.email || "---"}
                      </span>
                    </div>{" "}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "roboto",
                        fontWeight: "400",
                      }}
                    >
                      <span style={{ color: "#A0A7AF" }}>phone: </span>
                      <span style={{ color: "#2A4DAD" }}>
                        {item.ticketId.number || "---"}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            ),
            style: panelStyle,
          },
        ]}
      />
    </div>
  );
}

export default TicketDetails;
