import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const FAQ = ({ onClose }) => {
  const donationName = useSelector(
    (state) => state.starboardPointsDonationPosts?.donationName || "Stars"
  );

  // Sample FAQs
  const faqs = [
    {
      question: `What are ${donationName}?`,
      answer: `${donationName} are like a currency that you can send to and receive from other members of the organization.`,
    },
    {
      question: `How can the ${donationName} be used for redeeming?`,
      answer: `Every user will receive ${donationName} from their colleagues, and if your balance in the Vault allows, you can redeem coupons that match your available amount.`,
    },
    {
      question: `How will new ${donationName} be added?`,
      answer: `Each user will be automatically given a fixed amount of ${donationName} at the start of the month.`,
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div
      className="absolute right-1 top-full mt-2 w-64 max-h-96 overflow-y-auto overflow-hidden bg-white border border-gray-300 rounded-md shadow-lg z-10"
      style={{ scrollbarWidth: "none" }}
    >
      <div className="p-4 border-b text-md font-semibold text-gray-900 text-center">
        FAQs
      </div>
      <ul className="p-2 space-y-3">
        {faqs.map((faq, index) => (
          <li key={index} className="flex flex-col p-2 bg-gray-50 rounded-md">
            <div
              className="flex justify-between items-center rounded-md p-2 bg-gray-200 cursor-pointer"
              onClick={() => toggleFAQ(index)}
            >
              <h3 className="text-base font-semibold text-gray-900">
                {faq.question}
              </h3>
              <span className="text-sm font-medium text-gray-500">
                {openIndex === index ? "▲" : "▼"}
              </span>
            </div>
            {openIndex === index && (
              <p className="mt-1 text-sm  text-gray-600">{faq.answer}</p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
