import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import axios from "axios";
import useNotification from "../../../../components/snackbars/SnackBar";

const ContactForm = ({ setShowForm, setConversations, setAddFlag, addFlag }) => {
  const [loading, setLoading] = useState(false);
  const [conf, setConf] = useNotification();
  const [userData, setUserData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const storedData = localStorage.getItem("dexkorUserData");
    if (storedData) {
      setUserData(JSON.parse(storedData));
    }
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
  
    try {
      if (userData) {
        const { uniquePartnerId } = userData;
  
        if (!uniquePartnerId) {
          setConf({
            msg: "Unable to create contact: Missing Partner ID.",
            variant: "error",
          });
          setLoading(false);
          return;
        }
  
        const response = await axios.post(
          `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/create-contact/${uniquePartnerId}`,
          {
            ...values,
            uniquePartnerId,
          }
        );
  
        setLoading(false);
        setShowForm(false);
        setAddFlag(true);
  
        // Notify success
        setConf({ msg: "Contact Created Successfully", variant: "success" });
  
        // Reset the form
        form.resetFields();
      } else {
        setConf({
          msg: "Failed to create contact: User data not found.",
          variant: "error",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setShowForm(false);
  
      // Extract error message if available
      const errorMessage =
        error.response?.data?.message ||
        error.response?.statusText ||
        "An unknown error occurred.";
  
      setConf({
        msg: `Failed to create contact: ${errorMessage}`,
        variant: "error",
      });
  
      console.error("Error creating contact:", error.response || error.message);
    }
  };
  

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">Create New Lead</h2>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        className="space-y-4"
      >
        <Form.Item
          label="First Name"
          name="firstName"
        >
          <Input placeholder="Enter your first name" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input placeholder="Enter your email address" />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: "Phone number is required" }]}
        >
          <Input placeholder="Enter your phone number" />
        </Form.Item>

        <Form.Item
          label="Company Name"
          name="companyName"
       
        >
          <Input placeholder="Enter company name" />
        </Form.Item>

        <div className="flex justify-end space-x-4">
          <Button onClick={() => setShowForm(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="bg-blue-500"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ContactForm;
