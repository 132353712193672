import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Menu, Drawer, Button, Row, Col } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import './Menu.css';

const Setting = () => {
  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem('selectedMenuKey') || '1'
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [selectedMenuName, setSelectedMenuName] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('all-tickets');
  const { searchQuery, selectedTicket, setupDetails } = useOutletContext();

  const location = useLocation();
  const navig = useNavigate();

  const items = [
    { key: '1', label: 'All Tickets', path: '/portal/home/all-tickets', type: 'allTickets', url: 'all-tickets' },
    { key: '2', label: 'My Open Tickets', path: '/portal/home/my-open-tickets', type: 'myOpenTickets', url: 'my-open-tickets' },
    { key: '3', label: 'My In Progress Tickets', path: '/portal/home/my-inprogress-tickets', type: 'myInProgressTickets', url: 'my-inprogress-tickets' },
    { key: '4', label: 'My On Hold Tickets', path: '/portal/home/my-hold-tickets', type: 'myOnHoldTickets', url: 'my-hold-tickets' },
    { key: '5', label: 'My Closed Tickets', path: '/portal/home/my-closed-tickets', type: 'myClosedTickets', url: 'my-closed-tickets' },
    { key: '6', label: 'Team Open Tickets', path: '/portal/home/team-open-tickets', type: 'teamOpenTickets', url: 'team-open-tickets' },
    { key: '7', label: 'Team In Progress Tickets', path: '/portal/home/team-inprogress-tickets', type: 'teamInProgressTickets', url: 'team-inprogress-tickets' },
    { key: '8', label: 'Team On Hold Tickets', path: '/portal/home/team-hold-tickets', type: 'teamOnHoldTickets', url: 'team-hold-tickets' },
    { key: '9', label: 'Team Closed Tickets', path: '/portal/home/team-closed-tickets', type: 'teamClosedTickets', url: 'team-closed-tickets' },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedItem = items.find((item) => currentPath.startsWith(item.path));
    setSelectedKey(matchedItem ? matchedItem.key : '1');
  }, [location.pathname, items]);

  
  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setSelectedKey(e.key);
      localStorage.setItem('selectedMenuKey', e.key);
      localStorage.setItem('selectedMenuName', selectedItem.type);
      setSelectedMenuName(selectedItem.type);
      setSelectedMenu(selectedItem.url);
      navig(selectedItem.path);
    }
    if (isMobileView) setDrawerVisible(false);
  };

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (item.path && !e.target.classList.contains('ant-menu-submenu-arrow')) {
              navig(item.path);
            }
          }}
          className="dexkor-sales-hub-menu-item"
        >
          {item.label}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  return (
    <Row>
      {isMobileView ? (
        <>
          <Button
            icon={<MenuOutlined />}
            onClick={() => setDrawerVisible(true)}
            className="menu-icon-button"
            style={{ position: 'fixed', top: 15, left: 5, zIndex: 1000 }}
          />
          <Drawer
            title={setupDetails && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  alignItems: 'center',
                }}
              >
               <img
    src={setupDetails?.widgetCustomizations?.logoUrl}
    alt="Partner Logo"
    style={{
      width: '40px',
      height: '40px',
      objectFit: 'contain',
      borderRadius: '5px', // optional, for rounded edges
    }}
    onError={(e) => {
      e.target.src = 'https://via.placeholder.com/40?text=Logo'; // fallback if the image fails to load
    }}
  />
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  {setupDetails?.widgetCustomizations?.partnerName || 'Company Name'}
                </span>
              </div>
      )}
            placement="left"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            width={240}
          >
            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              items={getMenuItems(items)}
            />
          </Drawer>
        </>
      ) : (
        <Col span={4}>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            items={getMenuItems(items)}
            className="dexkor-portal-menu-list"
          />
        </Col>
      )}
      <Col span={isMobileView ? 24 : 20} style={{ paddingLeft: isMobileView ? 0 : 0 }}>
        <Outlet context={{ selectedMenuName, searchQuery, selectedMenu, selectedTicket }} />
      </Col>
    </Row>
  );
};

export default Setting;
