import "./DateTimePicker.css";
import { DatePicker, Modal, Button } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

export default function DateTimePicker({ dateTime, setDateTime, setShowDateTimePicker }) {
  const ref = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dateTime ? new Date(dateTime) : null); // Initialize with parsed dateTime

  useEffect(() => {
    setIsModalVisible(true);
    if (isModalVisible && ref.current) {
      ref.current.focus();
    }
  }, [isModalVisible]);

  const handleOk = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toISOString(); // Convert to MongoDB format
      setDateTime(formattedDate); // Update the parent component's dateTime
    }
    setIsModalVisible(false);
    setShowDateTimePicker(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowDateTimePicker(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date ? date.toDate() : null); // Update local state when date changes
    console.log(date); // Log the date object
  };

  return (
    <div>
      <Modal
        title="Select Date and Time"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <DatePicker
          ref={ref}
          format="YYYY-MM-DD HH:mm:ss" // Change format for display
          onChange={handleDateChange} // Use the new handler here
          showTime={{ use12Hours: true }}
          style={{ width: "100%" }} // Adjusts the DatePicker width
          value={selectedDate ? dayjs(selectedDate) : null} // Set the selected date for display
        />
        <div style={{ marginTop: 16 }}>
          <Button onClick={handleOk} type="primary">
            Save
          </Button>
          <Button onClick={handleCancel} style={{ marginLeft: 8 }}>
            Discard
          </Button>
        </div>
      </Modal>
    </div>
  );
}
