import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import logo from "../../Images/Logo icon.png";
import googleLogo from "../../Images/google-logo.png";
import portalLogo from "../../Images/portalLogo.png";
import internalLogo from "../../Images/internalLogo.png";
import Phone from "../../Images/Phone.png";
import SubTicketViewer from "./SubTicketViewer/SubTicketViewer";
import { RiChatNewFill } from "react-icons/ri";
import TicketForm from "../../components/TicketForm/TicketForm";
import { useNavigate, useParams } from "react-router-dom";

function SubTickets({
  viewData,
  parentConvo,
  setParentConvo,
  setParentConversations,
}) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConvo, setSelectedConvo] = useState(null);
  const [filterReady, setFilterReady] = useState(false); // New state to track filter readiness
  const [keyword, setKeyword] = useState("");
  const [openForm, setOpenForm] = useState({ open: false, update: false });
  const [openTicketForm, setOpenTicketForm] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showSaveButton, setShowSaveButton] = useState(null); // New state to control save button visibility
  const [showFilters, setShowFilters] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Define how many items per page
  const [showColumnDropDown, setShowColumnDropDown] = useState(false);
  const [showActionsDropDown, setShowActionsDropDown] = useState(false);
  const [showOwnerDropdown, setShowOwnerDropDown] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropDown] = useState(false);
  const [page, setPage] = useState(1); // Track the current page
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true); // Whether more users are available
  const [limit, setLimit] = useState(4); // Number of users per page
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOwner, setSelectedOwner] = useState({ name: "Select" });
  const [selectedAssignee, setSelectedAssignee] = useState({ name: "Select" });
  const [userData, setUserData] = useState({});
  const [hoverFlag, setHoverFlag] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(
    Math.ceil(conversations.length / itemsPerPage)
  );
  const [totalCount, setTotalCount] = useState(conversations.length);
  const navigate = useNavigate();

  const { subticketNumber } = useParams(); // Access the ticket number from the URL

  useEffect(() => {
    if (subticketNumber) {
      // Logic to pre-select the ticket or fetch related data
      const selected = conversations.find(
        (convo) => convo?.ticketId?.ticketNumber === subticketNumber
      );
      if (selected) {
        setSelectedConvo(selected);
        setIsDrawerOpen(true);
      } else {
        setKeyword(subticketNumber);
      }
    } else {
      setIsDrawerOpen(false);
    }
  }, [subticketNumber, conversations]);

  const fetchUser = async () => {
    const userIdFilter = viewData.filters.find(
      (filter) => filter.name === "owner"
    );
    const userId = userIdFilter?.value; // Extract the value of the userId filter

    if (userId) {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/batch`,
        { ids: [userId] }
      );
      setSelectedOwner(response.data[0]);
    }

    const assigneeIdFilter = viewData.filters.find(
      (filter) => filter.name === "assignee"
    );
    const assigneeId = assigneeIdFilter?.value;

    if (assigneeId) {
      const res = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/batch`,
        { ids: [assigneeId] }
      );
      setSelectedAssignee(res.data[0]);
    }
  };

  useEffect(() => {
    fetchUser();
    const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userInfo) setUserData(userInfo);
    setCurrentPage(1);
    setKeyword("");
  }, [viewData]);

  const showModal = () => {
    setOpenForm((prevState) => ({
      ...prevState, // Preserve the other keys in the object
      update: prevState.open ? false : prevState.update,
      open: !prevState.open, // Toggle the `open` key
    }));
  };

  const showTicketForm = () => {
    setOpenTicketForm(!openTicketForm);
  };

  const [columns, setColumns] = useState(viewData?.columns);

  const [filters, setFilters] = useState({});
  // Helper function to initialize showFilters based on viewData.filters
  const initializeFilters = (defaultFilters, viewFilters) => {
    return defaultFilters.map((filter) => {
      // Check if a matching filter (ignoring case) exists in viewFilters
      const match = viewFilters.find(
        (viewFilter) =>
          viewFilter.name.toLowerCase() === filter.name.toLowerCase()
      );

      // If a match is found, set `isActive` to true and copy the value
      return match ? { ...filter, isActive: true, value: match.value } : filter;
    });
  };

  // Helper function to compare initial viewData.filters with current filters
  const areFiltersDifferent = (
    initialFilters,
    currentFilters,
    initialColumns,
    currentColumns
  ) => {
    // Create a map of initialFilters with case-insensitive keys (lowercase names)
    const initialFilterValues = initialFilters.reduce((acc, filter) => {
      // Use toLowerCase for both name and value to ensure case-insensitive comparison
      acc[filter.name.toLowerCase()] = filter.value
        ? filter.value.toLowerCase()
        : null; // Store null if value is null
      return acc;
    }, {});

    // Create a map of currentFilters with case-insensitive keys (lowercase names)
    const currentFilterValues = Object.keys(currentFilters).reduce(
      (acc, key) => {
        // Normalize key to lowercase for case-insensitive comparison
        // If the key is 'slaBreaches', treat it as 'sla'
        const normalizedKey = key === "slaBreached" ? "sla" : key.toLowerCase();
        // Normalize value to lowercase and store null if value is null
        acc[normalizedKey] = currentFilters[key]
          ? currentFilters[key].toLowerCase()
          : null;
        return acc;
      },
      {}
    );

    // Compare both maps to check for any differences
    for (const key in initialFilterValues) {
      if (initialFilterValues[key] !== currentFilterValues[key]) {
        return true; // Found a difference
      }
    }

    // Also check if there are extra filters in currentFilters that don't exist in initialFilters
    if (filterCount !== viewData.filters.length) return true;

    // Filter columns based on isActive
    const activeInitialColumns = initialColumns.filter((col) => col.isActive);
    const activeCurrentColumns = currentColumns.filter((col) => col.isActive);

    // Check if the number of active columns is different
    if (activeInitialColumns.length !== activeCurrentColumns.length) {
      return true;
    }

    // Compare active columns for differences
    for (let i = 0; i < activeInitialColumns.length; i++) {
      if (activeInitialColumns[i].name !== activeCurrentColumns[i].name) {
        return true; // Found a difference in active column names
      }
    }

    return false; // No differences found
  };

  // Effect to set visibility of the save button based on filter changes
  useEffect(() => {
    const isDifferent = areFiltersDifferent(
      viewData.filters || [],
      filters,
      viewData.columns || [],
      columns
    );
    setShowSaveButton(isDifferent);
  }, [filters, viewData.filters, filterCount, columns]);

  // Inside the component
  const defaultFilters = [
    { name: "Status", isActive: false, value: null },
    { name: "Source", isActive: false, value: null },
    { name: "Type", isActive: false, value: null },
    { name: "Category", isActive: false, value: null },
    { name: "Priority", isActive: false, value: null },
    { name: "SLA", isActive: false, value: null },
    { name: "owner", isActive: false, value: null },
    { name: "assignee", isActive: false, value: null },
  ];

  useEffect(() => {
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setItemsPerPage(viewData.itemsPerPage);
    setColumns(viewData?.columns);
    setSelectedAssignee({ name: "Select" });
    setSelectedOwner({ name: "Select" });
  }, [viewData]);

  // Transform viewData.filters to set initial filters state, setting null values to ""
  const setInitialFilters = () => {
    if (viewData?.filters) {
      const transformedFilters = viewData.filters.reduce((acc, filter) => {
        const key = filter.name.toLowerCase(); // Convert name to lowercase
        acc[key] = filter.value !== null ? filter.value : ""; // Set value to empty string if null
        return acc;
      }, {});

      setFilters(transformedFilters);
      setFilterReady(true); // Mark filters as ready
    }
  };

  useEffect(() => {
    setInitialFilters();
  }, [viewData]);

  // Pagination logic
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  useEffect(() => {
    const getAllConversation = async () => {
      try {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem("dexkorUserData"));

        let response;

        if (keyword === "") {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/conversations/search/${userInfo.uniquePartnerId}/${parentConvo?.ticketId?._id}?page=${currentPage}&limit=${itemsPerPage}`,
            {}
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_CONVERSATION}/conversations/search/${userInfo.uniquePartnerId}/${parentConvo?.ticketId?._id}?keyword=${keyword}&page=${currentPage}&limit=${itemsPerPage}`,
            {}
          );
        }

        setLoading(false);
        // Sorting tickets in descending order based on createdAt
        const data = response.data.conversations;
        setConversations(data); // Set the filtered conversations
        setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
        setTotalCount(response.data.totalCount);
      } catch (error) {
        setLoading(false); // Handle errors properly
        if (error.response && error.response.status === 404) {
          setConversations([]);
          setTotalPages(0);
          setTotalCount(0);
        }
        console.error(error); // Log error for debugging
      }
    };

    if (filterReady) {
      getAllConversation();
    }
  }, [keyword, currentPage, filters, itemsPerPage, filterReady]);

  // Define an array of background colors
  const backgroundColors = [
    "bg-red-300",
    "bg-green-300",
    "bg-blue-300",
    "bg-yellow-300",
    "bg-purple-300",
    "bg-pink-300",
    "bg-indigo-300",
    "bg-orange-300",
    "bg-teal-300",
    "bg-cyan-300",
    "bg-lime-300",
    "bg-amber-300",
    "bg-emerald-300",
    "bg-fuchsia-300",
    "bg-rose-300",
    "bg-sky-300",
    "bg-violet-300",
    "bg-gray-300",
    "bg-stone-300",
    "bg-amber-400",
    "bg-cyan-400",
    "bg-blue-400",
    "bg-indigo-400",
    "bg-orange-400",
    "bg-teal-400",
    "bg-pink-400",
    "bg-lime-400",
    "bg-purple-400",
    "bg-rose-400",
    "bg-sky-400",
    "bg-green-400",
    "bg-red-400",
    "bg-violet-400",
  ];

  // Hash function to generate a consistent index based on the owner's name
  const getColorForOwner = (name) => {
    if (!name) return backgroundColors[0]; // default color if no name
    const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return backgroundColors[hash % backgroundColors.length];
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFilters = (name, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (value === null || value === "") {
        // If value is null or an empty string, remove the filter
        const { [name]: removed, ...remainingFilters } = newFilters;
        return remainingFilters; // Return the filters without the removed one
      }

      // Otherwise, update the filter state
      newFilters[name] = value;
      return newFilters;
    });

    setShowFilters((prevFilters) =>
      prevFilters.map((f) => {
        // Normalize the name to handle case-insensitive comparison
        const normalizedName = f.name.toLowerCase();
        const normalizedInputName =
          name === "slaBreached" ? "sla" : name.toLowerCase();

        // Update the filter if names match (considering case insensitivity)
        return normalizedName === normalizedInputName
          ? { ...f, value: value }
          : f;
      })
    );
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname; // Get the current path
    if (selectedConvo) {
      // navigate(`${selectedConvo?.ticketId?.ticketNumber}`);
      // toggleDrawer();
    } else {
      // navigate(`hellothere`);
    }
  }, [selectedConvo]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [rotate, setRotate] = useState(false); // State to track rotation

  const handleClick = () => {
    setRotate(!rotate); // Toggle rotation on every click
    // setFilters({});
    setInitialFilters();
    setShowFilters(() => {
      const viewFilters = viewData.filters || []; // Ensure viewData.filters exists

      // Use the helper function to initialize showFilters
      return initializeFilters(defaultFilters, viewFilters);
    });
    setFilterCount(viewData.filters.length);
    setKeyword("");
    setColumns(viewData.columns);
  };

  const handleItemsPerpage = (value) => {
    if (value === "" || value === null) {
      setItemsPerPage(25);
    } else setItemsPerPage(value);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const scrollDropDownRef = useRef(null);
  const columnDropdownRef = useRef(null);
  const actionsDropdownRef = useRef(null);
  const ownerDropdownRef = useRef(null);
  const assigneDropdownRef = useRef(null);

  useEffect(() => {
    // Define an async function to filter all users
    const searchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/filter/${loggedInUserData.uniquePartnerId}`,
          {
            params: { query: searchQuery }, // Pass the query as a query parameter
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    // Call the fetch function
    if (searchQuery !== "") {
      searchUsers();
    }
  }, [searchQuery]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionsDropdownRef.current &&
        !actionsDropdownRef.current.contains(event.target)
      ) {
        setShowActionsDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showActionsDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showActionsDropDown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        assigneDropdownRef.current &&
        !assigneDropdownRef.current.contains(event.target)
      ) {
        setShowAssigneeDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showAssigneeDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAssigneeDropdown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setShowColumnDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnDropdownRef.current &&
        !columnDropdownRef.current.contains(event.target)
      ) {
        setShowColumnDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showColumnDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColumnDropDown]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ownerDropdownRef.current &&
        !ownerDropdownRef.current.contains(event.target)
      ) {
        setShowOwnerDropDown(false);
      }
    };

    // Add event listener only if the dropdown is open
    if (showOwnerDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOwnerDropdown]);

  const handleToggle = (index) => {
    // Create a new array to avoid direct mutation
    const updatedColumns = columns.map((col, idx) =>
      idx === index ? { ...col, isActive: !col.isActive } : col
    );
    setColumns(updatedColumns); // Update state
  };

  // Fetch users based on page and limit
  const fetchUsers = async (reset = false) => {
    if (!hasMore || !loggedInUserData) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/users/chunks/${loggedInUserData.uniquePartnerId}?page=${page}&limit=${limit}`
      );
      const fetchedUsers = response.data.users;

      if (response.status === 204) {
        setHasMore(false); // Stop fetching when no more users are available
      } else {
        setUsers((prevUsers) =>
          reset ? fetchedUsers : [...prevUsers, ...fetchedUsers]
        );
        setPage((prevPage) => prevPage + 1); // Increment the page
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  // Fetch users when the dropdown is shown for the first time
  useEffect(() => {
    if (showOwnerDropdown || showAssigneeDropdown) {
      fetchUsers(true); // Fetch users for the first time or reset if dropdown is reopened
    }
  }, [showOwnerDropdown, showAssigneeDropdown]);

  // Infinite scroll logic for dropdown
  useEffect(() => {
    const dropdownElement = scrollDropDownRef.current; // Reference the dropdown element
    const handleScroll = () => {
      if (
        dropdownElement &&
        dropdownElement.scrollTop + dropdownElement.clientHeight >=
          dropdownElement.scrollHeight &&
        !loading
      ) {
        fetchUsers(); // Fetch more users on scroll
      }
    };

    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, page]);

  // Fetch logged-in user data
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));
    if (userData) setLoggedInUserData(userData);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [viewData]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedConvo(null);
    }
  }, [isDrawerOpen]);

  const logoMapping = {
    widget: logo,
    portal: portalLogo,
    email: googleLogo,
    servetel: Phone,
    internal: internalLogo,
    Internal: internalLogo,
  };

  return (
    <section>
      <div className="mx-auto max-w-screen-2xl">
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          {isDrawerOpen && selectedConvo && (
            <SubTicketViewer
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
              setIsDrawerOpen={setIsDrawerOpen}
              convo={selectedConvo}
              setSelectedConvo={setSelectedConvo}
              conversations={conversations}
              setConversations={setConversations}
              parentConvo={parentConvo}
              setParentConvo={setParentConvo}
              setParentConversations={setParentConversations}
            />
          )}
          <div className="flex w-full">
            {/* Right-side actions and select dropdown */}
            <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
              <button
                onClick={showTicketForm}
                className="text-white font-medium text-xs px-2 py-1 bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-lg text-center inline-flex items-center"
                type="button"
              >
                <RiChatNewFill className="mr-2" /> Subticket{" "}
              </button>
            </div>
            <div className="flex ml-auto mr-2 py-1">
              <div className="w-36 h-8 ml-auto">
                <label for="underline_select" class="sr-only">
                  Choose tickets per page
                </label>
                <select
                  id="underline_select"
                  class="block px-2 py-1 w-full text-xs text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  onChange={(e) => handleItemsPerpage(e.target.value || null)} // Handle select change
                  value={itemsPerPage || 25}
                >
                  {viewData?.itemsPerPage !== 50 &&
                    viewData?.itemsPerPage !== 100 &&
                    viewData?.itemsPerPage !== 25 && (
                      <option value={viewData?.itemsPerPage} selected>
                        {viewData?.itemsPerPage} Ticket per page
                      </option>
                    )}
                  <option value={25} selected>
                    25 Tickets per page
                  </option>
                  <option value={50}>50 Tickets per page</option>
                  <option value={100}>100 Tickets per page</option>
                </select>
              </div>
              <div ref={columnDropdownRef}>
                <button
                  id="dropdownHelperButton"
                  data-dropdown-toggle="dropdownHelper"
                  className="text-white font-medium text-xs px-2 py-1 bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-lg text-center inline-flex items-center"
                  type="button"
                  onClick={() => setShowColumnDropDown(!showColumnDropDown)}
                >
                  Columns{" "}
                  <svg
                    class="w-2 h-2 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {/* <!-- Dropdown menu --> */}
                {showColumnDropDown && (
                  <div
                    id="dropdownHelper"
                    className="absolute z-20 w-60 mt-2 right-2 top-20 bg-white rounded-lg shadow divide-y divide-gray-100"
                    style={{ maxHeight: "300px", overflowY: "auto" }} // Set a fixed height and make it scrollable
                  >
                    <ul
                      className="p-3 space-y-1 text-sm text-gray-700"
                      aria-labelledby="dropdownHelperButton"
                    >
                      {columns?.map((col, index) => (
                        <li key={index} onClick={() => handleToggle(index)}>
                          <div className="flex p-2 rounded hover:bg-gray-100">
                            <div className="flex items-center h-5">
                              <input
                                id={`helper-checkbox-${index}`}
                                aria-describedby={`helper-checkbox-text-${index}`}
                                type="checkbox"
                                value=""
                                checked={col.isActive}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                              />
                            </div>
                            <div className="ms-2 text-sm">
                              <label
                                htmlFor={`helper-checkbox-${index}`}
                                className="font-medium text-gray-900"
                              >
                                <div>{col.name}</div>
                              </label>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {conversations.length > 0 ? (
            <>
              <div className="overflow-x-auto overflow-y-auto max-h-[74vh]">
                <table className="w-full h-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 z-10">
                    <tr>
                      {columns?.map((col) => {
                        if (col.isActive) {
                          return (
                            <th
                              scope="col"
                              className={`px-4 py-3 justify-center whitespace-nowrap`}
                            >
                              {col.name}
                            </th>
                          );
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      conversations.length > 0 &&
                      conversations.map((convo, index) => {
                        return (
                          <tr
                            onClick={() => {
                              toggleDrawer();
                              setSelectedConvo(convo);
                              navigate(`${convo?.ticketId?.ticketNumber}`);
                            }}
                            className="border-b bg-white hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            {columns.some(
                              (col) =>
                                (col.name === "ticket-number" ||
                                  col.name === "ticket") &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className={`flex items-center pl-2 pr-16 py-2 font-medium text-gray-700 whitespace-nowrap`}
                              >
                                <img
                                  src={
                                    logoMapping[convo.ticketId.source] || logo
                                  } // Fallback to defaultLogo if source is not matched
                                  alt="iMac Front Image"
                                  className="w-auto h-8 mr-2"
                                />
                                {convo.ticketId.ticketNumber || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                (col.name === "subject" ||
                                  col.name === "title") &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className={`px-4 py-2 font-medium text-gray-700 whitespace-nowrap`}
                              >
                                  {convo.ticketId.subject || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                (col.name === "department") &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className={`px-4 py-2 font-medium text-gray-700 whitespace-nowrap`}
                              >
                                  {convo.ticketId.departmentAssigned || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "status" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.status || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "owner" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.owner?.imageUrl ? (
                                    <img
                                      src={convo.owner.imageUrl}
                                      alt={`${convo.owner.name}'s avatar`}
                                      className="w-auto h-6 mr-6 rounded-full"
                                    />
                                  ) : (
                                    <div
                                      className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                        convo.owner?.name
                                      )}`}
                                    >
                                      {convo.owner?.name
                                        ? convo.owner?.name
                                            .split(" ") // Split the name into words
                                            .filter(
                                              (word) => word.trim().length > 0
                                            ) // Ensure no empty strings are included
                                            .reduce(
                                              (acc, word, idx, arr) =>
                                                idx === 0 ||
                                                idx === arr.length - 1
                                                  ? acc + word[0]
                                                  : acc, // Take the first letter of the first and last words
                                              ""
                                            )
                                            .toUpperCase() // Convert to uppercase
                                        : "--"}
                                    </div>
                                  )}
                                  {convo.owner?.name || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "assignee" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.assignee?.imageUrl ? (
                                    <img
                                      src={convo.assignee.imageUrl}
                                      alt={`${convo.assignee.name}'s avatar`}
                                      className="w-auto h-6 mr-6 rounded-full"
                                    />
                                  ) : (
                                    <div
                                      className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                        convo.assignee?.name
                                      )}`}
                                    >
                                      {convo.assignee?.name
                                        ? convo.assignee?.name
                                            .split(" ") // Split the name into words
                                            .filter(
                                              (word) => word.trim().length > 0
                                            ) // Ensure no empty strings are included
                                            .reduce(
                                              (acc, word, idx, arr) =>
                                                idx === 0 ||
                                                idx === arr.length - 1
                                                  ? acc + word[0]
                                                  : acc, // Take the first letter of the first and last words
                                              ""
                                            )
                                            .toUpperCase() // Convert to uppercase
                                        : "--"}
                                    </div>
                                  )}
                                  {convo.assignee?.name || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "last update" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.updatedAt
                                  ? new Date(
                                      convo.ticketId.updatedAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.updatedAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}

                            {columns.some(
                              (col) =>
                                col.name === "customer code" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo?.customerDetails?.customerCode ||
                                    "---"}
                                </div>
                              </td>
                            )}

                            {columns.some(
                              (col) =>
                                col.name === "company name" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo?.customerDetails?.companyLogo ? (
                                    <img
                                      src={convo?.customerDetails?.companyLogo}
                                      alt={`avatar`}
                                      className="w-auto h-6 mr-6 rounded-full"
                                    />
                                  ) : (
                                    <div
                                      className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                        convo?.customerDetails?.companyName
                                      )}`}
                                    >
                                      {convo?.customerDetails?.companyName
                                        ? convo.customerDetails.companyName
                                            .split(" ") // Split the name into words
                                            .filter(
                                              (word) => word.trim().length > 0
                                            ) // Ensure no empty strings are included
                                            .reduce(
                                              (acc, word, idx, arr) =>
                                                idx === 0 ||
                                                idx === arr.length - 1
                                                  ? acc + word[0]
                                                  : acc, // Take the first letter of the first and last words
                                              ""
                                            )
                                            .toUpperCase() // Convert to uppercase
                                        : "--"}
                                    </div>
                                  )}
                                  {convo?.customerDetails?.companyName || "---"}
                                </div>
                              </td>
                            )}

                            {columns.some(
                              (col) =>
                                col.name === "customer name" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  <div
                                    className={`w-6 h-6 mr-3 flex items-center justify-center rounded-full text-white font-semibold text-xs ${getColorForOwner(
                                      convo.ticketId?.name
                                    )}`}
                                  >
                                    {convo?.ticketId?.name
                                      ? convo?.ticketId?.name
                                          .split(" ") // Split the name into words
                                          .filter(
                                            (word) => word.trim().length > 0
                                          ) // Ensure no empty strings are included
                                          .reduce(
                                            (acc, word, idx, arr) =>
                                              idx === 0 ||
                                              idx === arr.length - 1
                                                ? acc + word[0]
                                                : acc, // Take the first letter of the first and last words
                                            ""
                                          )
                                          .toUpperCase() // Convert to uppercase
                                      : "--"}
                                  </div>
                                  {convo.ticketId?.name || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "customer email" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.ticketId?.email || "---"}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "created date" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.updatedAt
                                  ? new Date(
                                      convo.ticketId.createdAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.createdAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "resolved date" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.resolvedAt
                                  ? new Date(
                                      convo.ticketId.resolvedAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.resolvedAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "first response date" &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.firstResponseAt
                                  ? new Date(
                                      convo.ticketId.firstResponseAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.firstResponseAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "resolution due" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.slaResolutionDue
                                  ? new Date(
                                      convo.ticketId.slaResolutionDue
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.slaResolutionDue
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "first response due" &&
                                col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.slaFirstResponseDue
                                  ? new Date(
                                      convo.ticketId.slaFirstResponseDue
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }) +
                                    " " +
                                    new Date(
                                      convo.ticketId.slaFirstResponseDue
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) =>
                                col.name === "sla breached" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                <div className="flex items-center">
                                  {convo.ticketId.slaBreached ? (
                                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                      Breached
                                    </span>
                                  ) : (
                                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                      Not breached
                                    </span>
                                  )}
                                </div>
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "type" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.type || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "source" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo?.ticketId?.source || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "priority" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium whitespace-nowrap"
                              >
                                <span
                                  className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded ${
                                    convo.ticketId.priority === "Low"
                                      ? "bg-green-100 text-green-800"
                                      : convo.ticketId.priority === "Medium"
                                      ? "bg-blue-100 text-blue-800"
                                      : convo.ticketId.priority === "High"
                                      ? "bg-yellow-100 text-yellow-800"
                                      : convo.ticketId.priority === "Critical"
                                      ? "bg-red-100 text-red-800"
                                      : "bg-gray-100 text-gray-800"
                                  }`}
                                >
                                  {convo.ticketId.priority || "---"}
                                </span>
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "category" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.category || "---"}
                              </td>
                            )}
                            {columns.some(
                              (col) => col.name === "tags" && col.isActive
                            ) && (
                              <td
                                onMouseEnter={() => setHoverFlag(convo._id)}
                                onMouseLeave={() => setHoverFlag(null)}
                                className="px-4 py-2 font-medium text-gray-700 whitespace-nowrap"
                              >
                                {convo.ticketId.tags || "---"}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {loading && (
                <div className="animate-pulse w-full p-4 overflow-y-auto max-h-[70vh]">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
              <nav
                className="flex flex-col items-start justify-between p-2 space-y-3 md:flex-row md:items-center md:space-y-0"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500">
                  Showing{" "}
                  <span className="font-semibold text-gray-900">
                    {firstIndex + 1}-{Math.min(lastIndex, totalCount)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900">
                    {totalCount}
                  </span>
                </span>
                <ul className="inline-flex items-stretch -space-x-px">
                  {/* Previous Button */}
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handlePrevPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>

                  {/* Page Numbers */}
                  {(() => {
                    const paginationItems = [];
                    const delta = 2; // Range around the current page
                    const lastPage = totalPages;
                    const addPageItem = (page) => {
                      paginationItems.push(
                        <li className="cursor-pointer" key={page}>
                          <p
                            onClick={() => handlePageChange(page)}
                            className={`py-2 px-3 text-sm font-medium ${
                              currentPage === page
                                ? "bg-blue-600 text-white"
                                : "text-gray-500 bg-white hover:bg-gray-100"
                            } border border-gray-300`}
                          >
                            {page}
                          </p>
                        </li>
                      );
                    };

                    // Always show the first page
                    addPageItem(1);

                    // Add dots if there's a gap between first page and the start of middle range
                    if (currentPage - delta > 2) {
                      paginationItems.push(
                        <li key="left-dots">
                          <p className="py-2 px-3 text-sm text-gray-500">...</p>
                        </li>
                      );
                    }

                    // Add pages around the current page
                    for (
                      let i = Math.max(2, currentPage - delta);
                      i <= Math.min(lastPage - 1, currentPage + delta);
                      i++
                    ) {
                      addPageItem(i);
                    }

                    // Add dots if there's a gap between the end of middle range and last page
                    if (currentPage + delta < lastPage - 1) {
                      paginationItems.push(
                        <li key="right-dots">
                          <p className="py-2 px-3 text-sm text-gray-500">...</p>
                        </li>
                      );
                    }

                    // Always show the last page
                    if (lastPage > 1) addPageItem(lastPage);

                    return paginationItems;
                  })()}

                  {/* Next Button */}
                  <li className="cursor-pointer">
                    <p
                      onClick={() => handleNextPage()}
                      className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </p>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              {!loading && (
                <section className="bg-white">
                  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                      <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
                        No subticket Found!
                      </p>
                      <p className="mb-4 text-lg font-light text-gray-500">
                        Sorry, there are no subtickets associated with this
                        ticket.
                      </p>
                    </div>
                  </div>
                </section>
              )}

              {loading && (
                <div className="animate-pulse w-full p-4">
                  {Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      className={`h-4 ${
                        index % 3 === 0
                          ? "bg-gray-200"
                          : index % 3 === 1
                          ? "bg-gray-300"
                          : "bg-gray-300"
                      } mb-6 rounded w-full`}
                    ></div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      
      {openTicketForm && parentConvo && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded-lg shadow-lg">
            <TicketForm
              setShowForm={showTicketForm}
              setConversations={setConversations}
              parentConvo={parentConvo}
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default SubTickets;
