import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Button, Card, Space, Modal, Row, Col, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

const Setting = () => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const navig = useNavigate();

  const items = [
    { key: "1", label: "General Setup", path: "/setting/general-setup" },
    {
      key: "2",
      label: "Import Export Setup",
      path: "/setting/import-export-setup",
    },
    // { key: '3', label: 'Application Setup' },
    { key: "4", label: "User Setup", path: "/setting/user-setup" },
    // { key: '5', label: 'Widget Setup' },
    {key: '6', label: 'Integration', path: '/setting/integration'},
    // {key: '7', label: 'Portal Setup', path: '/setting/portal-setup'}
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedItem = items.find((item) => currentPath.startsWith(item.path));

    if (matchedItem) {
      setSelectedKey(matchedItem.key);
    } else {
      setSelectedKey("");
    }
  }, [location.pathname, items]);

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (
              item.path &&
              !e.target.classList.contains("ant-menu-submenu-arrow")
            ) {
              navig(item.path);
            }
          }}
          className="dexkor-sales-hub-menu-item"
        >
          {item.label}
          {item.number !== undefined && <span>{item.number}</span>}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items
      .flatMap((item) => item.children || [item])
      .find((item) => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navig(selectedItem.path);
    }
    setSelectedKey(e.key);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);

    if (keys.includes("2")) {
      const firstChild = items.find((item) => item.key === "2").children[0];
      if (firstChild && firstChild.path) {
        setSelectedKey(firstChild.key);
        navig(firstChild.path);
      }
    }
  };

  return (
    <div>
      <Row>
        <Col span={3}>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className="cursor-pointer" onClick={() => navig("/setting")}>
              Setup
            </span>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]} // Highlight the selected key
            onClick={handleMenuClick}
            onOpenChange={onOpenChange}
            className="dexkor-sales-hub-nav-bar-menu-list"
            items={getMenuItems(items)}
            openKeys={openKeys}
          />
        </Col>
        <Col span={21}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default Setting;
