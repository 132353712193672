import React, { useEffect, useRef } from "react";
import "./campaignViewer.css";
import { IoArrowBackCircle } from "react-icons/io5";
import { Tabs, Col, Divider } from "antd";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import { useLocation, useNavigate  } from "react-router-dom";
import Details from "../Pages/Details/Details";
import Leads from "../Pages/Leads/Leads";

function CampaignViewer() {
  const location = useLocation();
  const selectedCampaign = location.state?.selectedCampaign;
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <>
  <div>

    <div style={{ display: 'flex', alignItems: 'center', gap: '50px', height: '56px', width: '100%', backgroundColor: "#2A4DAD"}}>
    <div style={{display: 'flex', alignItems: 'center', gap: '5px', color: "#fff", fontSize: "16px", marginLeft: "10px", cursor: "pointer"}} onClick={() => navigate(-1)} >
      <IoArrowBackCircle /> Back
    </div>
      <div>
      <h1 style={{alignItems: 'center', display: 'flex', color: "#fff", height: "56px", marginLeft: '5px', fontSize: "18px"}}>{selectedCampaign?.campaignId} - {selectedCampaign?.name}</h1>
      </div>
    </div>
        <div style={{marginLeft: "20px"}}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: "All Leads",
                key: "1",
                children: (
                  <Col style={{ height: "80vh" }}>
                    <div
                      style={{
                        marginLeft: "-20px",
                        marginTop: "-10px",
                        height: "80vh",
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                    >
                      <Leads selectedCampaign={selectedCampaign}/>
                    </div>
                  </Col>
                ),
              },
              {
                label: "Details",
                key: "2",
                children: (
                  <div
                    style={{
                      marginLeft: "-20px",
                      marginTop: "-10px",
                      height: "80vh",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                   <Details />
                  </div>
                ),
              },
            ]}
          />
        </div>
        </div>
    </>
  );
}

export default CampaignViewer;