import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../Private Route/PrivateRoute";
import PageNotFound from "../../components/PageNotFound";
import Index from "../Index";
import TicketExplorer from "../pages/TicketExplorer/TicketExplorer";
import { useSelector } from "react-redux";

const HelpDeskRoutes = () => {
  const ticketExplorerViews = useSelector(
    (state) => state.ticketExplorer.views
  );

  const [subTicketViews, setSubticketViews] = useState([]);

  // Filter the views where isSubticketView is set to true
  useEffect(() => {
    const filteredViews = ticketExplorerViews.filter(
      (view) => view.isSubticketView
    );
    setSubticketViews(filteredViews);
  }, [ticketExplorerViews]);

  return (
    <Route
      path="/help-desk"
      element={
        <PrivateRoute>
          <Index />
        </PrivateRoute>
      }
    >
      <Route>
        {ticketExplorerViews.map((view, index) => {
          return (
            <Route
              key={index}
              path={`${
                view?.isDefault
                  ? view?.subSource === "convo"
                    ? "convo-explorer"
                    : "ticket-explorer"
                  : "custom-views"
              }/${view.name
                .replace(/\s+/g, "-")
                .toLowerCase()}/:ticketNumber?/:subticketNumber?`}
              element={
                <PrivateRoute>
                  <TicketExplorer
                    viewData={view}
                    subTicketViewData={subTicketViews[0]}
                    subSource={view?.subSource || null}
                  />
                </PrivateRoute>
              }
            />
          );
        })}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  );
};

export default HelpDeskRoutes;
