import React from "react";
import io from "socket.io-client";
import StatusDropDown from "./StatusDropDown";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

function TicketNavBar({
  selectedConversation,
  setSelectedConversation,
  setConversations,
  departmentCode,
}) {
  const handleStatusChange = (newStatus) => {
    if (selectedConversation === null) return;

    const ticketId = selectedConversation.ticketId._id;

    if (newStatus === "Unassigned") {
    } else {
      socket.emit(
        "updateTicketStatus",
        {
          ticketId,
          status: newStatus,
          roomId: selectedConversation.roomId,
        },
        (response) => {
          if (response.success) {
            setConversations((prevConversations) => {
              return prevConversations.map((conversation) => {
                if (conversation.roomId === selectedConversation.roomId) {
                  // Update the status of the ticketId in the selected conversation
                  return {
                    ...conversation,
                    ticketId: {
                      ...conversation.ticketId,
                      status: newStatus,
                    },
                  };
                }
                return conversation;
              });
            });

            setSelectedConversation((prevSelectedConversation) => ({
              ...prevSelectedConversation,
              ticketId: {
                ...prevSelectedConversation.ticketId,
                status: newStatus,
              },
            }));
          } else {
            console.error("Error updating ticket status:", response.message);
          }
        }
      );
    }
  };

  return (
    <div>
      <div className="flex mt-4">
        <p class="relative inline-flex text-center">
          <p
            id="states-button"
            data-dropdown-toggle="dropdown-states"
            className=" h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
            type="button"
          >
            Status{" "}
          </p>
          <StatusDropDown
            departmentCode={departmentCode}
            handleStatusChange={handleStatusChange}
            selectedConversation={selectedConversation}
          />
        </p>
      </div>
    </div>
  );
}

export default TicketNavBar;
