import {
  SET_CAMPAIGN_EXPLORER_VIEWS,
  UPDATE_CAMPAIGN_EXPLORER_VIEW,
  ADD_CAMPAIGN_EXPLORER_VIEW,
} from "../actions/campaignExplorerView";

const initialState = {
  views: [], 
};

const campaignExplorerReducer = (state = initialState, action) => { 
  
  console.log(action);
  
  switch (action.type) {
    case SET_CAMPAIGN_EXPLORER_VIEWS:
      return {
        ...state,
        views: [...action.payload]
      };

    case UPDATE_CAMPAIGN_EXPLORER_VIEW:
      return {
        ...state,
        views: state.views.map((view) =>
          view._id === action.payload._id ? action.payload : view
        ),
      };

    case ADD_CAMPAIGN_EXPLORER_VIEW:
      return {
        ...state,
        views: [action.payload, ...state.views],
      };

    default:
      return state;
  }
};

export default campaignExplorerReducer;
