import React from "react";
import { RxCross2 } from "react-icons/rx";
import { IoSettingsSharp } from "react-icons/io5";
import { Modal } from "antd";

function Preferences({
  setOpenPreferences,
  columns,
  handleToggle,
  itemsPerPage,
  handleItemsPerpage,
  openPreferences,
}) {
  const pageSizeOptions = [
    {
      value: 5,
    },
    {
      value: 10,
    },
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 100,
    },
    {
      value: 200,
    },
  ];
  return (
    <div>
      <div className="relative group">
        <IoSettingsSharp
          onClick={() => setOpenPreferences(!openPreferences)}
          className="size-4 text-gray-500 hover:text-blue-700 cursor-pointer"
        />
        <span className="absolute top-[-15px] -left-4 transform -translate-x-1/2 text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
          Preferences
        </span>
      </div>
      <Modal
        open={openPreferences}
        footer={null}
        onCancel={() => setOpenPreferences(false)}
        closable={false}
        className="min-h-[50vh] min-w-[50vw] max-h-[50vh] max-w-[50vw] fixed left-[25%] top-[20%]"
      >
        <div className="w-full">
          <div className="flex justify-between mb-4">
            <p class="text-xl font-bold text-gray-900 mb-2">Preferences</p>
            <RxCross2
              onClick={() => setOpenPreferences(false)}
              className="size-7 cursor-pointer hover:text-blue-700"
            />
          </div>
          <div className="flex justify-between w-full">
            <div class="mb-4 w-[45%]">
              <p class="text-lg font-medium text-gray-900 mb-2">Page size</p>
              <div>
                {pageSizeOptions?.map((option, index) => {
                  return (
                    <div key={index} class="flex items-center mb-2">
                      <input
                        type="radio"
                        value={option.value}
                        checked={itemsPerPage === option.value}
                        onChange={() =>
                          handleItemsPerpage(option.value || null)
                        }
                        name="items-per-page"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        {option.value} tickets
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Vertical line */}
            <div className="border-l-[1px] border-gray-200 " />
            <div className="w-[50%]">
              <p class="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">
                Columns
              </p>
              <div
                className="max-h-[36vh] max-w-[40vw] overflow-auto"
                style={{
                  scrollbarWidth: "none", // For Firefox
                  msOverflowStyle: "none", // For Internet Explorer
                }}
              >
                {columns?.map((col, index) => {
                  return (
                    <label
                      key={index}
                      class="inline-flex w-full justify-between items-center mb-2 p-2 cursor-pointer border-b"
                    >
                      <span class="text-sm font-medium text-gray-900 dark:text-gray-300">
                        {col.name.charAt(0).toUpperCase() + col.name.slice(1)}
                      </span>
                      <input
                        type="checkbox"
                        value=""
                        checked={col?.isActive}
                        onChange={() => handleToggle(index)}
                        class="sr-only peer"
                      />
                      <div class="relative w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="flex mt-2">
            <button
              onClick={() => setOpenPreferences(false)}
              className="h-8 px-8 py-1 ml-auto bg-transparent text-blue-600 hover:text-blue-700 text-xs font-bold hover:bg-transparent"
            >
              Cancel
            </button>
            <button
              type="button"
              className="h-8 bg-blue-600 hover:bg-blue-700 px-8 py-1 text-xs font-bold rounded-full text-white"
              onClick={() => setOpenPreferences(false)}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Preferences;
