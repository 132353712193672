import React, { useEffect, useState } from "react";
import logo from "../Images/Logo icon.png";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { setUser } from "../Redux/Slice/userSlice";
import storeToken from "./storeToken";
import useNotification from "../components/snackbars/SnackBar";
import "./Login.css";
import SignInLoader from "./SignInLoader";
import { useDispatch } from "react-redux";
import { fetchTicketExplorerViews } from "../Redux/actions/ticketExplorerViewActions";
import { fetchPartnerSetupDetails } from "../Redux/actions/partnerSetupDetailActions";
import { fetchLeadExplorerViews } from "../Redux/actions/leadExplorerViewAction";
import { fetchCampaignExplorerViews } from "../Redux/actions/campaignExplorerView";
import { fetchTicketStatuses } from "../Redux/actions/ticketStatusAction";

function LoginSignup() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [conf, setConf] = useNotification();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Form state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const toggleForm = () => setIsSignUp(!isSignUp);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const navigate = useNavigate();

  const signUp = async () => {
    try {
      if (password === confirmPassword) {
        const res = await axios.post(
          `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/auth/signup`,
          {
            name,
            email,
            phone,
            password,
          }
        );
        navigate("/otp-verify", { state: { email } });
      } else {
        alert("Password and confirm password must be same!");
      }
    } catch (error) {
      console.error(
        "There was an error signing up!",
        error.response ? error.response.data : error.message
      );
    }
  };

  const signIn = async () => {
    let accessUrl = window.location.origin;
    if (accessUrl === "http://localhost:3000") {
      accessUrl = "https://staging.dexkor.com";
    }
    if (accessUrl === "https://client-admin-portal.vercel.app") {
      accessUrl = "https://demo.dexkor.com";
    }
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/auth/signin`,
        {
          email,
          password,
          accessUrl,
        }
      );

      storeToken(res.data.token);
      localStorage.setItem("dexkorUserData", JSON.stringify(res.data.userData));
      navigate("/help-desk/ticket-explorer/assigned-tickets");
      dispatch(
        fetchTicketExplorerViews(res.data.userData._id, res.data.userData.role, res.data.token)
      );
      dispatch(fetchLeadExplorerViews(res.data.userData._id, res.data.userData.role, res.data.token))
      dispatch(fetchCampaignExplorerViews(res.data.userData._id, res.data.userData.role, res.data.token))
      dispatch(fetchPartnerSetupDetails(res.data.userData.uniquePartnerId));
      dispatch(fetchTicketStatuses(res.data.userData.role, res.data.token));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.status === 404) {
        setConf({ msg: error.response.data, variant: "error" });
        console.error("There was an error signing in!", error);
      } else {
        {
          setLoading(false);
          if (error.response) {
            // Handle 403 for "Pending" status
            if (error.response.status === 403) {
              setConf({
                msg: "Your account status is pending. Please contact Admin.",
                variant: "warning",
              });
            } else {
              // Handle other errors
              setConf({ msg: error.response.data.msg, variant: "error" });
            }
          } else {
            console.error("There was an error signing in!", error.message);
            setConf({
              msg: "An error occurred. Please try again later.",
              variant: "error",
            });
          }
        }
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    if (isSignUp) {
      signUp();
    } else {
      signIn();
    }
  };

  const handleGoogleLoginSuccess = (response) => {
    const { credential } = response;
    const user = jwtDecode(credential); // Decode the JWT token
    console.log("User Info: ", response); // Log user info
    // You can now send the user info to your backend or use it as needed
  };

  const handleGoogleLoginError = (response) => {
    console.error("Google login failed", response);
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img alt="Dexkor" src={logo} className="mx-auto h-44 w-auto" />
        <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {isSignUp ? "Sign up for an account" : "Sign in to your account"}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={(e) => handleSubmit(e)} className="space-y-6">
          {isSignUp && (
            <div>
              <label
                htmlFor="name"
                className="flex block text-sm font-medium leading-6 text-gray-900"
              >
                Full Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="John senior"
                  required
                  autoComplete="name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          )}

          <div>
            <label
              htmlFor="email"
              className="flex block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="relative mt-2">
              <input
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="dexkor@example.com"
                type="email"
                required
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {isSignUp && (
            <div>
              <label
                htmlFor="phone"
                className="flex block text-sm font-medium leading-6 text-gray-900"
              >
                Phone Number
              </label>
              <div className="relative mt-2">
                <input
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="7780936392"
                  type="tel"
                  required
                  autoComplete="tel"
                  pattern="[0-9]*"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          )}

          <div>
            <div className="flex justify-between items-center">
              <label
                htmlFor="password"
                className="flex text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <a
                href="/recover-password"
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot Password?
              </a>
            </div>
            <div className="relative mt-2">
              <input
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                placeholder="Type a strong password"
                required
                autoComplete="current-password"
                className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-2 mr-2 right-0 hover:bg-white flex items-center pr-3 bg-white"
              >
                {showPassword ? (
                  <EyeSlashIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>

          {isSignUp && (
            <div>
              <label
                htmlFor="confirm-password"
                className="flex block text-sm font-medium leading-6 text-gray-900"
              >
                Confirm Password
              </label>
              <div className="relative mt-2">
                <input
                  id="confirm-password"
                  name="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  required
                  autoComplete="new-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute inset-y-2 mr-2 right-0 hover:bg-white flex items-center pr-3 bg-white"
                >
                  {showConfirmPassword ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          )}

          <div>
            {loading ? (
              <SignInLoader />
            ) : (
              <button
                type="submit"
                className="dexkor-signin-button flex ml-0 w-full justify-center mb-3 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
              >
                {isSignUp ? "Sign up" : "Sign in"}
              </button>
            )}
          </div>
        </form>

        {/* <GoogleLogin
            useOneTap
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
            scope="email profile"
          /> */}
        <p className="mt-10 text-center text-sm text-gray-500">
          {
            isSignUp ? (
              <>
                Already have an account?
                <a
                  href="#"
                  onClick={toggleForm}
                  className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  Sign in
                </a>
              </>
            ) : null
            // <>
            //   Don't have an account?
            //   <a
            //     href="#"
            //     onClick={toggleForm}
            //     className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            //   >
            //     Sign up
            //   </a>
            // </>
          }
        </p>
      </div>
    </div>
  );
}

export default LoginSignup;
