import React, { useEffect, useState, useRef } from "react";
import "./Emails.css";
import io from "socket.io-client";
import { PaperClipIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import { Tabs, Button, Tooltip } from "antd";
import { SendOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import EmailChat from "./EmailChat/EmailChat";
import TicketNavBar from "../TicketNavBar";
import InternalConversation from "../../components/Chat Conversation/InternalConversation";

const socket = io(`${process.env.REACT_APP_CHAT_SERVER}`);

const Emails = ({
  selectedConversation,
  setSelectedConversation,
  setConversations,
  conversations,
  isSubticket,
  parentConversation,
  setParentConversation,
  setParentConversations,
}) => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [attachmentType, setAttachmentType] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [recording, setRecording] = useState(false);
  const [userData, setUserData] = useState(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dexkorUserData"));
    setUserData(data);

    if (isSubticket) {
      setMessageType("Internal");
    }
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // Trigger scroll to bottom when messages change
    scrollToBottom();
  }, [selectedConversation?.messages, parentConversation?.messages]);

  const switchConversation = (key) => {
    if (isSubticket) {
      if (key === "2") {
        setMessageType(null); // Set to null for Customer Interaction
      } else if (key === "1") {
        setMessageType("Internal"); // Set to "Internal" for Internal Communication
      }
    } else {
      if (key === "1") {
        setMessageType(null); // Set to null for Customer Interaction
      } else if (key === "2") {
        setMessageType("Internal"); // Set to "Internal" for Internal Communication
      }
    }
  };

  // Function to filter messages
  const filterMessagesForCustomerinteraction = (messages) => {
    return messages?.filter((message) => message?.type !== "Internal");
  };

  // Assuming selectedConversation has a messages array
  const filteredMessagesForCustomerInteraction = isSubticket
    ? filterMessagesForCustomerinteraction(parentConversation?.messages)
    : filterMessagesForCustomerinteraction(selectedConversation?.messages);

  // Function to filter messages
  const filterMessagesForInternal = (messages) => {
    return messages?.filter((message) => message?.type === "Internal");
  };

  // Assuming selectedConversation has a messages array
  const filteredMessagesForInternal = filterMessagesForInternal(
    selectedConversation?.messages
  );

  const addMemberToConversation = (userInformation, currConvo) => {
    const memberId = userInformation._id;
    const conversationId = currConvo._id;
    socket.emit(
      "addMemberToConversation",
      {
        memberId,
        conversationId,
      },
      (response) => {
        if (response.success) {
          setConversations((prevConversations) => {
            return prevConversations.map((conversation) => {
              if (conversation.roomId === currConvo.roomId) {
                // Update the messages in the selected conversation
                return {
                  ...conversation,
                  members: [...conversation.members, userInformation],
                };
              }
              return conversation;
            });
          });

          if (setParentConversations) {
            setParentConversations((prevConversations) => {
              return prevConversations.map((conversation) => {
                if (conversation.roomId === currConvo.roomId) {
                  // Update the messages in the selected conversation
                  return {
                    ...conversation,
                    members: [...conversation.members, userInformation],
                  };
                }
                return conversation;
              });
            });
          }

          if (
            selectedConversation &&
            currConvo.roomId === selectedConversation.roomId
          ) {
            setSelectedConversation((prevSelectedConversation) => ({
              ...prevSelectedConversation,
              members: [...prevSelectedConversation.members, userInformation],
            }));
          }

          if (
            parentConversation &&
            currConvo.roomId === parentConversation.roomId
          ) {
            setParentConversation((prevSelectedConversation) => ({
              ...prevSelectedConversation,
              members: [...prevSelectedConversation.members, userInformation],
            }));
          }
        } else {
          console.error(
            "Error occured while assigning ticket:",
            response.message
          );
        }
      }
    );
  };

  useEffect(() => {
    if (attachment) {
      sendMessage(); // Automatically send message when attachment is set
    }
  }, [attachment]);

  const sendMessage = () => {
    const inputMessage = message.trim();
    if (inputMessage || (attachment && attachmentType)) {
      // Construct the message object
      let msg;

      if (isSubticket && messageType === null) {
        msg = {
          room: parentConversation.roomId,
          sender: "Agent",
          senderId: userData._id,
          message: inputMessage === "" ? null : inputMessage,
          type: messageType,
          attachment,
          attachmentType,
          source: parentConversation.ticketId?.source || null,
          user: userData || null,
          customer: parentConversation?.customer || null,
          ticket: parentConversation.ticketId,
        };

        if (
          !parentConversation?.members?.some(
            (member) => member._id === userData._id
          )
        ) {
          addMemberToConversation(userData, parentConversation);
        }
      } else {
        msg = {
          room: selectedConversation.roomId,
          sender: "Agent",
          senderId: userData._id,
          message: inputMessage === "" ? null : inputMessage,
          type: messageType,
          attachment,
          attachmentType,
          source: selectedConversation.ticketId?.source || null,
          user: userData || null,
          customer: selectedConversation?.customer || null,
          ticket: selectedConversation.ticketId,
        };

        if (
          !selectedConversation?.members?.some(
            (member) => member._id === userData._id
          )
        ) {
          addMemberToConversation(userData, selectedConversation);
        }
      }

      socket.emit("message", msg);
      setMessage("");
      setAttachment(null); // Reset attachment after sending
      setAttachmentType(""); // Reset attachment type after sending
    } else {
      console.warn("No message or attachment to send");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachment(reader.result);
        setAttachmentType(file.type); // Set attachment type based on the file type
      };
      reader.readAsDataURL(file);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachment(reader.result);
          setAttachmentType("audio/webm");
        };
        reader.readAsDataURL(blob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const customerInteractionTab = {
    label: "Customer Interaction ",
    key: isSubticket ? "2" : "1",
    children: (
      <div
        ref={chatContainerRef}
        style={{
          height: "78vh",
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <EmailChat
          selectedConversation={{
            ...(isSubticket && parentConversation
              ? parentConversation
              : selectedConversation),
            messages: filteredMessagesForCustomerInteraction,
          }}
          setSelectedConversation={setSelectedConversation}
          setMyTicket={setConversations}
          scrollToBottom={scrollToBottom}
          handleFileChange={handleFileChange}
          setMessage={setMessage}
          message={message}
          sendMessage={sendMessage}
        />
      </div>
    ),
  };

  const internalDiscussionTab = {
    label: "Internal Discussion",
    key: isSubticket ? "1" : "2",
    children: (
      <div
        ref={chatContainerRef}
        style={{
          height: "58vh",
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <InternalConversation
          conversation={{
            ...selectedConversation,
            messages: filteredMessagesForInternal,
          }}
        />
      </div>
    ),
  };

  // Conditional order of tabs
  const tabItems = isSubticket
    ? [internalDiscussionTab, customerInteractionTab]
    : [customerInteractionTab, internalDiscussionTab];

  return (
    <>
      <div style={{ height: "100vh" }}>
        <div>
          <div style={{ height: "100vh" }}>
            <div>
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#F6F7F9",
                    marginTop: "-15px",
                  }}
                >
                  {selectedConversation && (
                    <div className="relative">
                      <Tabs
                        defaultActiveKey="1"
                        onChange={switchConversation}
                        items={tabItems}
                      />

                      <div className="absolute -top-2 right-1">
                        <TicketNavBar
                          selectedConversation={selectedConversation}
                          setSelectedConversation={setSelectedConversation}
                          setConversations={setConversations}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {selectedConversation && messageType === "Internal" ? (
                  <div>
                    <div>
                      <span>
                        <div>
                          <div>
                            <ReactQuill
                              value={message}
                              onChange={setMessage}
                              required
                              theme="snow"
                              className="custom-quilly"
                              modules={{
                                toolbar: [
                                  { size: [] },
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",

                                  { list: "ordered" },
                                  { list: "bullet" },
                                  { indent: "-1" },
                                  { indent: "+1" },

                                  "link",
                                  "video",
                                  "blockquote",
                                  "code-block",
                                  { align: [] },
                                  { color: [] },
                                  { background: [] },
                                  "clean",
                                ],
                              }}
                              formats={[
                                "header",
                                "font",
                                "size",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "indent",
                                "link",
                                "video",
                                "align",
                                "color",
                                "background",
                                "code-block",
                              ]}
                            />
                          </div>
                          <div className="dexkor-email-reply-send-button-container flex items-center">
                            <span style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  height: "40px",
                                  padding: "0 10px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Tooltip title="Attach File">
                                    <label>
                                      <PaperClipIcon
                                        style={{ marginRight: "15px" }}
                                        className="h-5 w-5 text-gray-500"
                                      />
                                      <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="hidden"
                                      />
                                    </label>
                                  </Tooltip>

                                  <Tooltip title="Microphone">
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <button
                                        className={`bg-transparent hover:bg-transparent p-2 text-gray-500 ${
                                          recording
                                            ? "hover:text-green-500"
                                            : "hover:text-gray-700"
                                        } ml-1 ${
                                          recording ? "text-green-500" : ""
                                        }`}
                                        onClick={
                                          recording
                                            ? stopRecording
                                            : startRecording
                                        }
                                      >
                                        <MicrophoneIcon
                                          className={`h-5 w-5 ${
                                            recording
                                              ? "animate-pulse"
                                              : "text-gray-500"
                                          }`}
                                        />
                                      </button>
                                    </div>
                                  </Tooltip>
                                </div>
                                <Tooltip title="Send">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                      width: "auto",
                                    }}
                                  >
                                    <Button
                                      onClick={sendMessage}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: "#2A4DAD",
                                        color: "white",
                                        padding: "8px 16px",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      Send
                                      <SendOutlined
                                        style={{ marginLeft: "8px" }}
                                      />
                                    </Button>
                                  </div>
                                </Tooltip>
                              </div>
                            </span>
                          </div>
                        </div>
                      </span>
                      <span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",

                            height: "40px",
                            padding: "0 10px",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Emails;
