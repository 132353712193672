import React from "react";

function DexKorSuite() {
  return (
    <div className="overflow-auto">
      <div className="mt-16 mx-auto max-w-6xl mb-20 flex items-start gap-x-16">
        {/* Description */}
        <div>
          <h5 className="text-4xl text-blue-800 font-bold">
            Add to private Slack channels
          </h5>
          <p className="pt-3 text-gray-500 text-sm">Last updated 1 month ago</p>
          <p className="pt-7">
            You can add DexKor to private slack channels but you must do so from
            Slack directly. DexKor does not have visibility into private
            channels until it is added to them directly.
          </p>
          <p className="pt-7">
            Inside of the channel you wish to add DexKor to, type /invite
            @DexKor and replace DexKor with the name you've given Pylon.
          </p>
          <p className="pt-7">
            Follow the flow to link the channel as a customer account.
          </p>
        </div>

        {/* RelatedArticles */}
        <div className="border rounded-md p-5 bg-gray-100">
          <h5 className="text-lg text-blue-800 font-medium pb-2">
            Related Articles
          </h5>
          <p className="text-gray-600 pb-2 cursor-pointer hover:text-black">
            Adding DexKor to customer-created Slack channels{" "}
          </p>
          <p className="text-gray-600 pb-2 cursor-pointer hover:text-black">
            What's an example introduction message to DexKor for customers?{" "}
          </p>
          <p className="text-gray-600 pb-2 cursor-pointer hover:text-black">
            I invited DexKor into the shared channel, but the channel isn't
            linked{" "}
          </p>
          <p className="text-gray-600 pb-2 cursor-pointer hover:text-black">
            Rename the Dexkor slackbot{" "}
          </p>
          <p className="text-gray-600 cursor-pointer hover:text-black">
            My customer doesn't have a paid slack workspace{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DexKorSuite;
