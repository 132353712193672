import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button, Modal, Checkbox, message } from "antd";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver"; // Add this dependency: npm install file-saver

const Leads = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [allLeads, setAllLeads] = useState([]);
  const selectedCampaign = location.state?.selectedCampaign;
  const [selectedLeads, setSelectedLeads] = useState(
    selectedCampaign?.allLeads || []
  );
  const [unmatchedLeads, setUnmatchedLeads] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedForAddition, setSelectedForAddition] = useState([]);

  const columns = [
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone Number", dataIndex: "phone", key: "phone" },
    {
      title: "Notes Count",
      key: "notesCount",
      render: (_, record) => record?.notes?.length || 0,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        new Date(createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
    },
  ];

  useEffect(() => {
    const getAllLeads = async () => {
      setLoading(true);
      try {
        const data = JSON.parse(localStorage.getItem("dexkorUserData"));
        if (data) {
          const response = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/adsuite-contact/get-contact/${data.uniquePartnerId}`
          );
          const allLeadsData = response.data;

          // Compare with selected leads to initialize unmatchedLeads
          const selectedIds = selectedCampaign?.allLeads || [];
          const matchedLeads = allLeadsData.filter((lead) =>
            selectedIds.includes(lead._id)
          );
          const unmatched = allLeadsData.filter(
            (lead) => !selectedIds.includes(lead._id)
          );

          setAllLeads(allLeadsData); // Save all leads for export
          setSelectedLeads(matchedLeads); // Full objects
          setUnmatchedLeads(unmatched); // Full objects
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
        message.error("Failed to fetch leads.");
      } finally {
        setLoading(false);
      }
    };

    getAllLeads();
  }, [selectedCampaign]);

  const handleExportLeads = () => {
    if (allLeads.length === 0) {
      message.warning("No leads available to export.");
      return;
    }

    const csvData = selectedLeads.map((lead) => ({
      "First Name": lead.firstName,
      "Last Name": lead.lastName,
      "Email": lead.email,
      "Phone Number": lead.phone,
      "Created At": new Date(lead.createdAt).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
    }));

    // Convert to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [Object.keys(csvData[0]).join(","), ...csvData.map((row) =>
        Object.values(row).join(",")
      )].join("\n");

    // Create a download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    const timestamp = new Date().toISOString();
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `leads_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    message.success("Leads exported successfully.");
  };

  const handleAddLeads = () => {
    setModalVisible(true);
  };

  const handleAddSelectedLeads = async () => {
    const newLeadIds = unmatchedLeads
      .filter((lead) => selectedForAddition.includes(lead._id))
      .map((lead) => lead._id);
    const userToken = localStorage.getItem("dexkorUserToken");
    const userData = JSON.parse(localStorage.getItem("dexkorUserData"));

    if (userData && userToken) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_CONVERSATION}/campaign-explorer/update/${selectedCampaign._id}`,
          { allLeads: [...selectedLeads.map((lead) => lead._id), ...newLeadIds] },
          {
            // Configuration object
            headers: {
              Authorization: `Bearer ${userToken}`,
              Role: `${userData.role}`,
              "Content-Type": "application/json",
            },
          }
        );

        setUnmatchedLeads((prev) =>
          prev.filter((lead) => !selectedForAddition.includes(lead._id))
        );
        setSelectedForAddition([]);
        setModalVisible(false);
        message.success("Selected leads added to the campaign.");
      } catch (error) {
        console.error("Error updating campaign:", error);
        message.error("Failed to update the campaign.");
      }
    }
  };

  return (
    <section style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div>
          <h2 style={{ margin: 0 }}>All Leads ({selectedLeads.length})</h2>
        </div>
        <div>
          <Button type="primary" onClick={handleExportLeads}>
            Export Lead
          </Button>
          <Button type="primary" onClick={handleAddLeads}>
            Add Leads
          </Button>
        </div>
      </div>

      <Table
        dataSource={selectedLeads}
        columns={columns}
        rowKey={(record) => record?._id}
        pagination={{ pageSize: 10 }}
        loading={loading}
      />

      <Modal
        title="Add Leads to Campaign"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleAddSelectedLeads}
        okText="Add Selected"
        cancelText="Cancel"
        width={1200}
      >
        <div
          style={{
            maxHeight: "400px",
            overflowY: "auto",
            border: "1px solid #f0f0f0",
            borderRadius: "4px",
          }}
        >
          <Table
            dataSource={unmatchedLeads}
            columns={[
              {
                title: "Select",
                key: "select",
                render: (_, record) => (
                  <Checkbox
                    onChange={(e) => {
                      const selected = [...selectedForAddition];
                      const id = record._id;
                      if (e.target.checked) {
                        selected.push(id);
                      } else {
                        const index = selected.indexOf(id);
                        if (index > -1) selected.splice(index, 1);
                      }
                      setSelectedForAddition(selected);
                    }}
                  />
                ),
              },
              ...columns,
            ]}
            rowKey={(record) => record._id}
            pagination={{ pageSize: 10 }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default Leads;
