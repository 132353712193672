import React, { useEffect, useState } from "react";

function SlaRelatedDetails({
  selectedConversation,
  isfirstResponseDue,
  isResolutionDue,
}) {
  const [timeLeft, setTimeLeft] = useState("");
  const [resolutionTimeLeft, setResolutionTimeLeft] = useState("");
  const [respondedAfter, setRespondedAfter] = useState(null);
  const [resolvedAfter, setResolvedAfter] = useState(null);
  const [firstResponseDueBreached, setFirstResponseDueBreached] =
    useState(false);
  const [resolvedDueBreached, setResolvedDueBreached] = useState(false);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = new Date(); // Get the current time
      const slaDueTime = new Date(
        selectedConversation.ticketId.slaFirstResponseDue
      ); // Convert slaFirstResponseDue to Date object

      const timeDifference = slaDueTime - currentTime; // Calculate the time difference in milliseconds

      if (timeDifference > 0) {
        // SLA is still valid, calculate the time remaining

        // Convert milliseconds to seconds, minutes, hours, days, months, years
        const totalSeconds = Math.floor(timeDifference / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);
        const totalMonths = Math.floor(totalDays / 30); // Rough estimate
        const totalYears = Math.floor(totalMonths / 12);
        const totalDecades = Math.floor(totalYears / 10);

        // Remaining values after calculating larger units
        const daysLeft = totalDays % 30;
        const monthsLeft = totalMonths % 12;
        const yearsLeft = totalYears % 10;

        // Format the remaining time string
        let timeString = "";
        if (totalDecades > 0) {
          timeString += `${totalDecades}d `;
        }
        if (yearsLeft > 0) {
          timeString += `${yearsLeft}y `;
        }
        if (monthsLeft > 0) {
          timeString += `${monthsLeft}m `;
        }
        if (daysLeft > 0) {
          timeString += `${daysLeft}d `;
        }
        if (totalHours > 0) {
          timeString += `${totalHours % 24}h `;
        } else if (totalMinutes > 0) {
          timeString += `${totalMinutes % 60}m `;
        } else {
          timeString += `${totalSeconds % 60}s `;
        }

        // Set the time left and reset the breach flag
        setTimeLeft(`Respond in: ${timeString}`);
        setFirstResponseDueBreached(false); // SLA is not breached, reset the breach flag
      } else {
        // SLA is expired, calculate how much time has passed since expiration
        const overdueTime = Math.abs(timeDifference); // Make timeDifference positive to get the overdue time
        const overdueSeconds = Math.floor(overdueTime / 1000);
        const overdueMinutes = Math.floor(overdueSeconds / 60);
        const overdueHours = Math.floor(overdueMinutes / 60);
        const overdueDays = Math.floor(overdueHours / 24);
        const overdueMonths = Math.floor(overdueDays / 30);
        const overdueYears = Math.floor(overdueMonths / 12);
        const overdueDecades = Math.floor(overdueYears / 10);

        // Remaining values after calculating larger units
        const daysOverdue = overdueDays % 30;
        const monthsOverdue = overdueMonths % 12;
        const yearsOverdue = overdueYears % 10;

        // Format the overdue time string
        let overdueString = "Overdue by ";
        if (overdueDecades > 0) {
          overdueString += `${overdueDecades}d `;
        }
        if (yearsOverdue > 0) {
          overdueString += `${yearsOverdue}y `;
        }
        if (monthsOverdue > 0) {
          overdueString += `${monthsOverdue}m `;
        }
        if (daysOverdue > 0) {
          overdueString += `${daysOverdue}d `;
        }
        if (overdueHours > 0) {
          overdueString += `${overdueHours % 24}h `;
        } else if (overdueMinutes > 0) {
          overdueString += `${overdueMinutes % 60}m `;
        } else {
          overdueString += `${overdueSeconds % 60}s `;
        }

        // Mark the SLA as breached and set the overdue time
        setFirstResponseDueBreached(true); // SLA is breached
        setTimeLeft(overdueString);
      }
    };

    // Run the calculateTimeLeft function initially
    if (
      selectedConversation &&
      !selectedConversation.ticketId.firstResponseAt
    ) {
      calculateTimeLeft();
    }

    // Set up an interval to update the countdown every second
    const intervalId = setInterval(() => {
      if (
        selectedConversation &&
        !selectedConversation.ticketId.firstResponseAt
      ) {
        calculateTimeLeft();
      }
    }, 1000);

    // Clean up the interval when the component unmounts or SLA expires
    return () => {
      clearInterval(intervalId);
      setFirstResponseDueBreached(false);
      setTimeLeft("");
      setResolutionTimeLeft("");
      setResolvedAfter(null);
      setRespondedAfter(null);
    };
  }, [selectedConversation]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = new Date(); // Get the current time
      const slaDueTime = new Date(
        selectedConversation.ticketId.slaResolutionDue
      ); // Convert slaFirstResponseDue to Date object

      const timeDifference = slaDueTime - currentTime; // Calculate the time difference in milliseconds

      if (timeDifference > 0) {
        // SLA is still valid, calculate the time remaining

        // Convert milliseconds to seconds, minutes, hours, days, months, years
        const totalSeconds = Math.floor(timeDifference / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);
        const totalMonths = Math.floor(totalDays / 30); // Rough estimate
        const totalYears = Math.floor(totalMonths / 12);
        const totalDecades = Math.floor(totalYears / 10);

        // Remaining values after calculating larger units
        const daysLeft = totalDays % 30;
        const monthsLeft = totalMonths % 12;
        const yearsLeft = totalYears % 10;

        // Format the remaining time string
        let timeString = "";
        if (totalDecades > 0) {
          timeString += `${totalDecades}d `;
        }
        if (yearsLeft > 0) {
          timeString += `${yearsLeft}y `;
        }
        if (monthsLeft > 0) {
          timeString += `${monthsLeft}m `;
        }
        if (daysLeft > 0) {
          timeString += `${daysLeft}d `;
        }
        if (totalHours > 0) {
          timeString += `${totalHours % 24}h `;
        } else if (totalMinutes > 0) {
          timeString += `${totalMinutes % 60}m `;
        } else {
          timeString += `${totalSeconds % 60}s `;
        }

        // Set the time left and reset the breach flag
        setResolutionTimeLeft(`Resolve in: ${timeString}`);
      } else {
        // SLA is expired, calculate how much time has passed since expiration
        const overdueTime = Math.abs(timeDifference); // Make timeDifference positive to get the overdue time
        const overdueSeconds = Math.floor(overdueTime / 1000);
        const overdueMinutes = Math.floor(overdueSeconds / 60);
        const overdueHours = Math.floor(overdueMinutes / 60);
        const overdueDays = Math.floor(overdueHours / 24);
        const overdueMonths = Math.floor(overdueDays / 30);
        const overdueYears = Math.floor(overdueMonths / 12);
        const overdueDecades = Math.floor(overdueYears / 10);

        // Remaining values after calculating larger units
        const daysOverdue = overdueDays % 30;
        const monthsOverdue = overdueMonths % 12;
        const yearsOverdue = overdueYears % 10;

        // Format the overdue time string
        let overdueString = "Overdue by ";
        if (overdueDecades > 0) {
          overdueString += `${overdueDecades}d `;
        }
        if (yearsOverdue > 0) {
          overdueString += `${yearsOverdue}y `;
        }
        if (monthsOverdue > 0) {
          overdueString += `${monthsOverdue}m `;
        }
        if (daysOverdue > 0) {
          overdueString += `${daysOverdue}d `;
        }
        if (overdueHours > 0) {
          overdueString += `${overdueHours % 24}h `;
        } else if (overdueMinutes > 0) {
          overdueString += `${overdueMinutes % 60}m `;
        } else {
          overdueString += `${overdueSeconds % 60}s `;
        }

        // Mark the SLA as breached and set the overdue time
        setResolutionTimeLeft(overdueString);
      }
    };

    // Run the calculateTimeLeft function initially
    if (selectedConversation && !selectedConversation.ticketId.resolvedAt) {
      calculateTimeLeft();
    }

    // Set up an interval to update the countdown every second
    const intervalId = setInterval(() => {
      if (selectedConversation && !selectedConversation.ticketId.resolvedAt) {
        calculateTimeLeft();
      }
    }, 1000);

    // Clean up the interval when the component unmounts or SLA expires
    return () => {
      clearInterval(intervalId);
      setFirstResponseDueBreached(false);
      setTimeLeft("");
      setResolutionTimeLeft("");
      setResolvedAfter(null);
      setRespondedAfter(null);
    };
  }, [selectedConversation]);

  useEffect(() => {
    const calculateRespondedAfter = () => {
      const firstResponseAt = new Date(
        selectedConversation.ticketId.firstResponseAt
      ); // Get the current time
      const slaFirstResponseDue = new Date(
        selectedConversation.ticketId.slaFirstResponseDue
      ); // Convert slaFirstResponseDue to Date object

      const timeDifference = slaFirstResponseDue - firstResponseAt; // Calculate the time difference in milliseconds

      if (timeDifference > 0) {
        // SLA is still valid, calculate the time remaining
        const createdAt = new Date(selectedConversation.ticketId.createdAt);
        const respondedAfterDifference = firstResponseAt - createdAt;
        // Convert milliseconds to seconds, minutes, hours, days, months, years
        const totalSeconds = Math.floor(respondedAfterDifference / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);
        const totalMonths = Math.floor(totalDays / 30); // Rough estimate
        const totalYears = Math.floor(totalMonths / 12);
        const totalDecades = Math.floor(totalYears / 10);

        // Remaining values after calculating larger units
        const daysLeft = totalDays % 30;
        const monthsLeft = totalMonths % 12;
        const yearsLeft = totalYears % 10;

        // Format the remaining time string
        let timeString = "";
        if (totalDecades > 0) {
          timeString += `${totalDecades}d `;
        }
        if (yearsLeft > 0) {
          timeString += `${yearsLeft}y `;
        }
        if (monthsLeft > 0) {
          timeString += `${monthsLeft}m `;
        }
        if (daysLeft > 0) {
          timeString += `${daysLeft}d `;
        }
        if (totalHours > 0) {
          timeString += `${totalHours % 24}h `;
        } else if (totalMinutes > 0) {
          timeString += `${totalMinutes % 60}m `;
        } else {
          timeString += `${totalSeconds % 60}s `;
        }

        // Set the time left and reset the breach flag
        setRespondedAfter(`Responded after : ${timeString}`);
        setFirstResponseDueBreached(false);
      } else {
        // SLA is expired, calculate how much time has passed since expiration
        const createdAt = new Date(selectedConversation.ticketId.createdAt);
        const respondedAfterDifference = firstResponseAt - createdAt;
        const overdueTime = Math.abs(respondedAfterDifference); // Make timeDifference positive to get the overdue time
        const overdueSeconds = Math.floor(overdueTime / 1000);
        const overdueMinutes = Math.floor(overdueSeconds / 60);
        const overdueHours = Math.floor(overdueMinutes / 60);
        const overdueDays = Math.floor(overdueHours / 24);
        const overdueMonths = Math.floor(overdueDays / 30);
        const overdueYears = Math.floor(overdueMonths / 12);
        const overdueDecades = Math.floor(overdueYears / 10);

        // Remaining values after calculating larger units
        const daysOverdue = overdueDays % 30;
        const monthsOverdue = overdueMonths % 12;
        const yearsOverdue = overdueYears % 10;

        // Format the overdue time string
        let overdueString = "Responded after: ";
        if (overdueDecades > 0) {
          overdueString += `${overdueDecades}d `;
        }
        if (yearsOverdue > 0) {
          overdueString += `${yearsOverdue}y `;
        }
        if (monthsOverdue > 0) {
          overdueString += `${monthsOverdue}m `;
        }
        if (daysOverdue > 0) {
          overdueString += `${daysOverdue}d `;
        }
        if (overdueHours > 0) {
          overdueString += `${overdueHours % 24}h `;
        } else if (overdueMinutes > 0) {
          overdueString += `${overdueMinutes % 60}m `;
        } else {
          overdueString += `${overdueSeconds % 60}s `;
        }

        // Mark the SLA as breached and set the overdue time
        setRespondedAfter(overdueString);
        setFirstResponseDueBreached(true);
      }
    };
    if (
      selectedConversation &&
      selectedConversation.ticketId &&
      selectedConversation.ticketId.firstResponseAt
    ) {
      calculateRespondedAfter();
    }
    return () => {
      setFirstResponseDueBreached(false);
      setTimeLeft("");
      setResolutionTimeLeft("");
      setResolvedAfter(null);
      setRespondedAfter(null);
    };
  }, [selectedConversation]);

  useEffect(() => {
    const calculateResolvedAfter = () => {
      const resolvedAt = new Date(selectedConversation.ticketId.resolvedAt); // Get the current time
      const slaResolutionDue = new Date(
        selectedConversation.ticketId.slaResolutionDue
      ); // Convert slaFirstResponseDue to Date object

      const timeDifference = slaResolutionDue - resolvedAt; // Calculate the time difference in milliseconds

      if (timeDifference > 0) {
        // SLA is still valid, calculate the time remaining
        const createdAt = new Date(selectedConversation.ticketId.createdAt);
        const resolvedAfterDifference = resolvedAt - createdAt;
        // Convert milliseconds to seconds, minutes, hours, days, months, years
        const totalSeconds = Math.floor(resolvedAfterDifference / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);
        const totalMonths = Math.floor(totalDays / 30); // Rough estimate
        const totalYears = Math.floor(totalMonths / 12);
        const totalDecades = Math.floor(totalYears / 10);

        // Remaining values after calculating larger units
        const daysLeft = totalDays % 30;
        const monthsLeft = totalMonths % 12;
        const yearsLeft = totalYears % 10;

        // Format the remaining time string
        let timeString = "";
        if (totalDecades > 0) {
          timeString += `${totalDecades}d `;
        }
        if (yearsLeft > 0) {
          timeString += `${yearsLeft}y `;
        }
        if (monthsLeft > 0) {
          timeString += `${monthsLeft}m `;
        }
        if (daysLeft > 0) {
          timeString += `${daysLeft}d `;
        }
        if (totalHours > 0) {
          timeString += `${totalHours % 24}h `;
        } else if (totalMinutes > 0) {
          timeString += `${totalMinutes % 60}m `;
        } else {
          timeString += `${totalSeconds % 60}s `;
        }

        // Set the time left and reset the breach flag
        setResolvedAfter(`Resolved after : ${timeString}`);
        setResolvedDueBreached(false);
      } else {
        // SLA is expired, calculate how much time has passed since expiration
        // SLA is still valid, calculate the time remaining
        const createdAt = new Date(selectedConversation.ticketId.createdAt);
        const resolvedAfterDifference = resolvedAt - createdAt;
        const overdueTime = Math.abs(resolvedAfterDifference); // Make timeDifference positive to get the overdue time
        const overdueSeconds = Math.floor(overdueTime / 1000);
        const overdueMinutes = Math.floor(overdueSeconds / 60);
        const overdueHours = Math.floor(overdueMinutes / 60);
        const overdueDays = Math.floor(overdueHours / 24);
        const overdueMonths = Math.floor(overdueDays / 30);
        const overdueYears = Math.floor(overdueMonths / 12);
        const overdueDecades = Math.floor(overdueYears / 10);

        // Remaining values after calculating larger units
        const daysOverdue = overdueDays % 30;
        const monthsOverdue = overdueMonths % 12;
        const yearsOverdue = overdueYears % 10;

        // Format the overdue time string
        let overdueString = "Resolved after: ";
        if (overdueDecades > 0) {
          overdueString += `${overdueDecades}d `;
        }
        if (yearsOverdue > 0) {
          overdueString += `${yearsOverdue}y `;
        }
        if (monthsOverdue > 0) {
          overdueString += `${monthsOverdue}m `;
        }
        if (daysOverdue > 0) {
          overdueString += `${daysOverdue}d `;
        }
        if (overdueHours > 0) {
          overdueString += `${overdueHours % 24}h `;
        } else if (overdueMinutes > 0) {
          overdueString += `${overdueMinutes % 60}m `;
        } else {
          overdueString += `${overdueSeconds % 60}s `;
        }

        // Mark the SLA as breached and set the overdue time
        setResolvedAfter(overdueString);
        setResolvedDueBreached(true);
      }
    };
    if (
      selectedConversation.ticketId.resolvedAt &&
      selectedConversation.ticketId.resolvedAt !== null
    ) {
      calculateResolvedAfter();
    }
    return () => {
      setFirstResponseDueBreached(false);
      setTimeLeft("");
      setResolutionTimeLeft("");
      setResolvedAfter(null);
      setRespondedAfter(null);
    };
  }, [selectedConversation]);

  return (
    <>
      {isfirstResponseDue && (
        <span>
          {!firstResponseDueBreached ? (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
              {respondedAfter ? respondedAfter : timeLeft}
            </span>
          ) : (
            <span
              style={{}}
              className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
            >
              {respondedAfter ? respondedAfter : timeLeft}
            </span>
          )}
        </span>
      )}

      {isResolutionDue && (
        <span>
          {!selectedConversation.ticketId.slaBreached ? (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
              {resolvedAfter ? resolvedAfter : resolutionTimeLeft}
            </span>
          ) : (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
              {resolvedAfter ? resolvedAfter : resolutionTimeLeft}
            </span>
          )}
        </span>
      )}
    </>
  );
}

export default SlaRelatedDetails;
