import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function CustomerPrivateRoute({ children }) {
  const location = useLocation();
  const token = localStorage.getItem("token");  

  if (!token) {
    // Store the last accessed path to redirect to after login
    localStorage.setItem("redirectPath", location.pathname);
    return <Navigate to="/portal/login" />;
  }

  try {
    const decodedToken = jwtDecode(token); 
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      localStorage.removeItem("customerDetails");
      localStorage.setItem("redirectPath", location.pathname);
      return <Navigate to="/portal/login" />;
    }
  } catch (error) {
    console.error("Invalid token:", error);
    localStorage.setItem("redirectPath", location.pathname);
    return <Navigate to="/portal/login" />;
  }

  return children;
}

export default CustomerPrivateRoute;
