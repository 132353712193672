import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Button, Card, Space, Modal, Row, Col, Menu } from "antd";
import { useNavigate } from "react-router-dom";

const StarBoardSetup = () => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const navig = useNavigate();

  const items = [
    {
      key: "1",
      label: "Update Donation Details",
      path: "/setting/starboard-setup/update-donation",
    },
    {
      key: "2",
      label: "Update Core Values",
      path: "/setting/starboard-setup/update-core-values",
    },

    {
      key: "3",
      label: "Coupons Settings",
      path: "/setting/starboard-setup/coupons",
    },
  ];

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (
              item.path &&
              !e.target.classList.contains("ant-menu-submenu-arrow")
            ) {
              navig(item.path);
            }
          }}
          className="dexkor-sales-hub-menu-item"
        >
          {item.label}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navig(selectedItem.path);
    }
    setSelectedKey(e.key);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <div>
      <Row>
        <Col span={4}>
          <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className="cursor-pointer">StarBoard</span>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            onOpenChange={onOpenChange}
            className="dexkor-sales-hub-nav-bar-menu-list"
            items={getMenuItems(items)}
            openKeys={openKeys}
          />
        </Col>

        <Col span={20}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default StarBoardSetup;
