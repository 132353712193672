import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import "./PortalSetup.css"
import { Button, Card, Space, Modal, Row, Col, Menu } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'

const PortalSetup = () => {
  const [selectedKey, setSelectedKey] = useState('0');
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const navig = useNavigate();

  const items = [
    { key: '1', label: 'Portal', path: '/setting/portal-setup/portal'},
    { key: '2', label: 'Customer Management', path: '/setting/portal-setup/customer-management'},
    // { key: '3', label: 'Application Setup'},
    // { key: '4', label: 'User Setup' },
    
  ];

  useEffect(() => {
    const currentPath = location.pathname;

    // Find the item whose path is a prefix of the current URL
    const matchedItem = items.find((item) => currentPath.startsWith(item.path));
    
    if (matchedItem) {
      setSelectedKey(matchedItem.key); // Update selected key based on the matching URL
    } else {
      setSelectedKey(''); // Clear the selection if no match is found
    }
  }, [location.pathname, items]);

  const getMenuItems = (menuItems) =>
    menuItems.map(item => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (item.path && !e.target.classList.contains('ant-menu-submenu-arrow')) {
              navig(item.path);
            }
          }}
          className='dexkor-sales-hub-menu-item'
        >
          {item.label}
          {item.number !== undefined && <span>{item.number}</span>}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items.flatMap(item => item.children || [item]).find(item => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navig(selectedItem.path);
    }
      setSelectedKey(e.key);
    
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  
    if (keys.includes('2')) {
      const firstChild = items.find(item => item.key === '2').children[0];
      if (firstChild && firstChild.path) {
        setSelectedKey(firstChild.key);  
        navig(firstChild.path);      
      }
    }
  };

  return (
   <div>
    <Row>
      <Col span={4}> <div className="dexkor-sales-hub-main-screen-left-nav-bar-header">
            <span className='cursor-pointer' >User</span>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            onOpenChange={onOpenChange}
            className='dexkor-sales-hub-nav-bar-menu-list'
            items={getMenuItems(items)}
            openKeys={openKeys}
          />
          </Col>
      <Col span={20}>
      <Outlet /></Col>
    </Row>
    
   </div>
  )
}

export default PortalSetup