import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import storeToken from "./utils/storeToken";
import EnvelopeIcon from "./assets/Envelope.png";
import "./Otp.css";
import LoaderButton from "./pageLoaders/LoaderButton";
import useNotification from "../../components/snackbars/SnackBar";
import { useNavigate } from "react-router-dom";

function Otp({
  customerData,
  setShowOtp,
  setToken,
  setCustomerData,
  resendOTP,
  setupDetails,
  setIsUserLoggedIn,
  setLoginViaPassword,
  loginViaPassword
}) {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [conf, setConf] = useNotification();
  const [counter, setCounter] = useState(10); // 10-second countdown state
  const [error, setError] = useState(null);
  const navig = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const handleChange = (event, index) => {
    const { value } = event.target;

    // Ensure value is a digit and within the input length
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text").trim();
    if (pasteData.length === otp.length && /^\d+$/.test(pasteData)) {
      const newOtp = pasteData.split("");
      setOtp(newOtp);
      newOtp.forEach((digit, idx) => {
        inputRefs.current[idx].value = digit;
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const otpCode = otp.join("");
    const { email } = customerData;
    setLoading(true);
    if (otpCode === "" || otpCode === null) {
      setError("OTP required!");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_MICROSERVICE_URL}/otp/verify/customer`,
        {
          email,
          otp: otpCode,
        }
      );

      if (response.status === 200) {
        setShowOtp(false);
        setIsUserLoggedIn(true);
        setCustomerData(response.data.customer);
        storeToken(response.data.token);
        localStorage.setItem(
          "customerDetails",
          JSON.stringify(response.data.customer)
        );
        setLoading(false);
        navig("/portal/home")
      } else {
        console.log("Verification failed");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error?.response?.data?.msg);
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setCounter(10);
    resendOTP();
  };

  return (
    <div className="mx-auto flex w-full max-w-md flex-col space-y-4 mt-20 otp-box">
      <div className="flex flex-col items-center justify-center text-center space-y-2">
        <img src={EnvelopeIcon} alt="Envelope icon" />
        <div>
          <p className="dexkor-otp-header">E-mail Verification OTP</p>
        </div>
      </div>

      <div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center justify-center mx-auto w-full max-w-xs">
              {otp.map((data, index) => (
                <div key={index} className="w-12 h-12">
                  <input
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="dexkor-otp-input-box"
                    style={{
                      border: `1px solid ${setupDetails?.widgetCustomizations?.secondaryColor}`,
                    }}
                    name="otp"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    onPaste={index === 0 ? handlePaste : null} // Only listen to paste on the first input
                  />
                </div>
              ))}
            </div>

            <div className="flex items-center justify-center">
              <p className="dexkor-otp-description">
                Enter the verification code sent on your email address{" "}
                <span className="dexkor-otp-email">{customerData.email}</span>
              </p>
            </div>

            <div>
              <p className="text-red-500 text-xs">{error && error}</p>
            </div>

            <div className="flex flex-col space-y-0">
              <div>
                {loading ? (
                  <LoaderButton setupDetails={setupDetails} />
                ) : (
                  <button
                    type="submit"
                    className="dexkor-otp-submit-button"
                    style={{
                      background: `linear-gradient(90deg, ${setupDetails?.widgetCustomizations?.secondaryColor} 0%, ${setupDetails?.widgetCustomizations?.primaryColor} 100%)`,
                    }}
                  >
                    Submit
                  </button>
                )}
              </div>

              <div className="space-x-20">
                <a
                  className="dexkor-resend-link"
                  href="#"
                  onClick={counter === 0 ? handleResendOtp : null}
                  style={{
                    pointerEvents: counter === 0 ? "auto" : "none",
                    opacity: counter === 0 ? 1 : 0.5,
                    color: `${setupDetails?.widgetCustomizations?.secondaryColor}`,
                  }}
                >
                  {counter > 0 ? `Resend OTP in ${counter}s` : "Resend OTP"}
                </a>

                <span
                  className="dexkor-resend-link cursor-pointer"
                  onClick={() => setShowOtp(false)}
                  style={{
                    color: `${setupDetails?.widgetCustomizations?.secondaryColor}`,
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
            <div>
            {/* <span
                  className="dexkor-resend-link cursor-pointer"
                  onClick={() => setLoginViaPassword(true)}
                  style={{
                    color: `${setupDetails?.widgetCustomizations?.secondaryColor}`,
                  }}
                >
                  Login Via Password
                </span> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Otp;
