import axios from "axios";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { RiChatNewFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const CampaignExplorer = () => {
  const [keyword, setKeyword] = useState("");
  const [allCampaign, setAllCampaign] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch campaigns from the API
  useEffect(() => {
    const getCampaign = async () => {
      setLoading(true);
      try {
        const data = JSON.parse(localStorage.getItem("dexkorUserData"));
        if (data) {
          const response = await axios.get(
            `${process.env.REACT_APP_CONVERSATION}/campaign-explorer/${data._id}`
          );
          setAllCampaign(response.data);
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
        // You could add user-friendly error handling here, like setting an error state
      } finally {
        setLoading(false);
      }
    };

    getCampaign();
  }, []);

  // Handle selecting all campaigns
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelectedCampaign(allCampaign);  // Select all campaigns
    } else {
      setSelectedCampaign([]);  // Deselect all
    }
  };

  // Handle individual campaign selection via checkbox
  const handleCheckboxChange = (campaign) => {
    setSelectedCampaign((prevSelected) => {
      if (prevSelected.includes(campaign)) {
        return prevSelected.filter((item) => item !== campaign);
      }
      return [...prevSelected, campaign];
    });
  };

  // Function to check if any keyword matches with the campaign data
  const matchesSearch = (campaign, keywords) => {
    return keywords.some((keyword) => {
      const lowerCaseKeyword = keyword.trim().toLowerCase();
      return (
        (campaign?.name && campaign?.name.toLowerCase().includes(lowerCaseKeyword)) ||
        (campaign.status && campaign.status.toLowerCase().includes(lowerCaseKeyword)) ||
        (campaign.startDate && campaign.startDate.toLowerCase().includes(lowerCaseKeyword)) ||
        (campaign.endDate && campaign.endDate.toLowerCase().includes(lowerCaseKeyword)) ||
        (campaign.createdAt && campaign.createdAt.toLowerCase().includes(lowerCaseKeyword)) ||
        (campaign.updatedAt && campaign.updatedAt.toLowerCase().includes(lowerCaseKeyword))
      );
    });
  };

  return (
    <section className="bg-gray-50 py-1">
      <div className="px-1 mx-auto max-w-screen-2xl">
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <div className="flex flex-col px-2 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
            <div className="flex items-start">
              <p>All Campaign: {allCampaign.length}</p>
            </div>
            <div className="flex flex-1 justify-center items-center h-8">
              <div className="flex items-center space-x-4 relative">
                <div className="w-full">
                  <input
                    type="text"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    id="simple-search"
                    className="bg-gray-50 z-0 border h-8 border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 pr-16"
                    placeholder="Search campaign here (separate with commas)"
                    style={{
                      fontSize: "13px",
                      minWidth: "30rem",
                      borderRadius: "100px",
                    }}
                    required=""
                  />
                </div>
                <div className="absolute right-2">
                  <CiSearch />
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
              {/* <button
                className="text-white font-medium text-xs px-2 py-1 focus:outline-none rounded-lg text-center inline-flex items-center"
                type="button"
                style={{backgroundColor: "#2A4DAD"}}
              >
                <RiChatNewFill className="mr-2" /> Create Campaign
              </button> */}
            </div>
          </div>
          <div className="overflow-x-auto overflow-y-auto max-h-[80vh]">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="px-4 py-3 sticky left-0 z-20 bg-gray-50">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      checked={allCampaign.length > 0 && selectedCampaign.length === allCampaign.length}
                    />
                  </th>
                  <th className="px-4 py-3">Campaign ID</th>
                  <th className="px-4 py-3">Campaign Name</th>
                  <th className="px-4 py-3">Status</th>
                  <th className="px-4 py-3">Start Date</th>
                  <th className="px-4 py-3">End Date</th>
                  <th className="px-4 py-3">Created Date</th>
                  <th className="px-4 py-3">Updated Date</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center py-4">Loading...</td>
                  </tr>
                ) : (
                  allCampaign
                    .filter((campaign) =>
                      matchesSearch(campaign, keyword.split(","))
                    )
                    .map((campaign, index) => (
                      <tr
                        key={index}
                        className={`border-b bg-white hover:bg-gray-100 cursor-pointer ${
                          selectedCampaign?.includes(campaign) ? "bg-blue-50" : ""
                        }`}
                        onClick={() => {
                          setSelectedCampaign([campaign]);  // Ensure it's in array form
                          navigate(`/adsuite/campaign/${campaign.campaignId}`, {
                            state: { selectedCampaign: campaign },
                          });
                        }}
                      >
                        <td className="px-4 py-2 sticky left-0 z-10 bg-white">
                          <input
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                            checked={selectedCampaign?.includes(campaign)}
                            onChange={() => handleCheckboxChange(campaign)}
                          />
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {campaign?.campaignId || "---"}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {campaign?.name || "---"}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {campaign.status || "---"}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {campaign.startDate || "---"}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {campaign.endDate || "---"}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {campaign.createdAt || "---"}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {campaign.updatedAt || "---"}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </section>
  );
};

export default CampaignExplorer;
