import React from "react";
import {
  AcademicCapIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import WebhookIcon from "@mui/icons-material/Webhook";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

function LandingPage({ setView }) {
  return (
    <div className="overflow-auto">
      <div className="bg-blue-900 p-6 flex flex-col items-center">
        <h1 className="font-bold text-white text-5xl mb-4">
          Welcome to DexKor help
        </h1>
        <p className="font-semibold text-white text-lg">
          Find articles and discussions to help answer your DexKor questions{" "}
        </p>
        <div className="relative mt-5 w-full max-w-xl mb-4">
          <input
            type="text"
            placeholder="What are you looking for?"
            className="text-sm rounded-lg px-4 py-3.5 w-full placeholder-gray-700"
          />
          <div className="absolute inset-y-0 right-4 flex items-center">
            <MagnifyingGlassIcon
              className="w-5 h-5 cursor-pointer"
              strokeWidth={2}
            />
          </div>
        </div>
      </div>

      {/* CardsSection */}
      <div className="mt-16 mx-auto max-w-6xl mb-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7">
          <div
            className="flex flex-col items-center border rounded p-8 shadow-md hover:shadow-xl transition-shadow cursor-pointer"
            onClick={() => setView("GettingStarted")}
          >
            <AcademicCapIcon className="h-12 w-12 pb-2" />
            <h5 className="text-center font-bold text-lg pb-2">
              Getting started
            </h5>
            <p className="text-center">
              Set yourself up for success with onboarding guides
            </p>
          </div>
          <div className="flex flex-col items-center border rounded p-8 shadow-md hover:shadow-xl transition-shadow cursor-pointer">
            <LibraryBooksIcon className="pb-2" style={{ fontSize: "48px" }} />
            <h5 className="text-center font-bold text-lg pb-2">
              Documentation
            </h5>
            <p className="text-center">
              Make the most of your DexKor setup with our comprehensive
              documentation
            </p>
          </div>
          <div className="flex flex-col items-center border rounded p-8 shadow-md hover:shadow-xl transition-shadow cursor-pointer">
            <QuestionAnswerIcon className="pb-2" style={{ fontSize: "48px" }} />
            <h5 className="text-center font-bold text-lg pb-2">
              Help and FAQs
            </h5>
            <p className="text-center">Find solutions for common issues</p>
          </div>
          <div className="flex flex-col items-center border rounded p-8 shadow-md hover:shadow-xl transition-shadow cursor-pointer">
            <WebhookIcon className="pb-2" style={{ fontSize: "48px" }} />
            <h5 className="text-center font-bold text-lg pb-2">Developer</h5>
            <p className="text-center">
              Information about DexKor's APIs, Webhooks and apps
            </p>
          </div>
          <div className="flex flex-col items-center border rounded p-8 shadow-md hover:shadow-xl transition-shadow cursor-pointer">
            <AnalyticsIcon className="pb-2" style={{ fontSize: "48px" }} />
            <h5 className="text-center font-bold text-lg pb-2">
              Reporting & Analytics
            </h5>
            <p className="text-center">
              Run reports and get metrics on your DexKor data
            </p>
          </div>
          <div className="flex flex-col items-center border rounded p-8 shadow-md hover:shadow-xl transition-shadow cursor-pointer">
            <IntegrationInstructionsIcon
              className="pb-2"
              style={{ fontSize: "48px" }}
            />
            <h5 className="text-center font-bold text-lg pb-2">Integrations</h5>
            <p className="text-center">Information about DexKor integrations</p>
          </div>
        </div>
      </div>

      {/* ArticlesSection */}
      <div className="mt-16 mx-auto max-w-6xl mb-7">
        <h5 className="text-2xl font-bold mb-6">Popular Articles</h5>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Add to private Slack Channels</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>What's the difference between knowledge base and docs?</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>How do i setup my support email?</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>Can i add bullet points to broadcasts?</p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
        <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 cursor-pointer hover:border-blue-900 hover:shadow-lg transition-shadow">
          <div className="flex gap-x-3">
            <InsertDriveFileOutlinedIcon />
            <p>
              What's an example introduction message to DexKor for customers?
            </p>
          </div>
          <ChevronRightIcon className="h-4 w-4 " />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
