import React from "react";

const Edit = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.92627 23.3086H17.6841C18.7388 23.3086 19.5552 23.0039 20.1333 22.3945C20.7192 21.793 21.0122 20.8867 21.0122 19.6758V7.14844L19.1255 9.03516V19.582C19.1255 20.1914 18.981 20.6523 18.6919 20.9648C18.4028 21.2695 18.0591 21.4219 17.6606 21.4219H3.96143C3.37549 21.4219 2.92236 21.2695 2.60205 20.9648C2.28955 20.6523 2.1333 20.1914 2.1333 19.582V6.28125C2.1333 5.67188 2.28955 5.21094 2.60205 4.89844C2.92236 4.58594 3.37549 4.42969 3.96143 4.42969H14.6372L16.5239 2.54297H3.92627C2.70752 2.54297 1.78955 2.84766 1.17236 3.45703C0.555176 4.05859 0.246582 4.96484 0.246582 6.17578V19.6758C0.246582 20.8867 0.555176 21.793 1.17236 22.3945C1.78955 23.0039 2.70752 23.3086 3.92627 23.3086ZM8.19189 15.7148L10.4771 14.7188L21.4224 3.78516L19.8169 2.20312L8.8833 13.1367L7.82861 15.3398C7.78174 15.4414 7.80127 15.543 7.88721 15.6445C7.98096 15.7383 8.08252 15.7617 8.19189 15.7148ZM22.2896 2.92969L23.1333 2.0625C23.3286 1.85156 23.4263 1.62109 23.4263 1.37109C23.4341 1.11328 23.3364 0.886719 23.1333 0.691406L22.8638 0.410156C22.6841 0.230469 22.4653 0.148438 22.2075 0.164062C21.9575 0.179687 21.7349 0.28125 21.5396 0.46875L20.6841 1.3125L22.2896 2.92969Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default Edit;
