// Import necessary libraries and components
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Home.css";
import moment from "moment";
import { IoFilter } from "react-icons/io5";
import { isAfter, isBefore, parseISO, startOfDay, endOfDay } from "date-fns";
import { useOutletContext } from "react-router-dom";
import { Table, Modal, DatePicker, Select, Button, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelectedTicket } from "../Ticket/SelectedTicketContext";
import Papa from "papaparse";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Home = () => {
  const { selectedMenuName, searchQuery, selectedMenu } = useOutletContext();
  const { setSelectedTicket } = useSelectedTicket();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]); // Store tickets
  const [filteredTickets, setFilteredTickets] = useState([]); // Store filtered tickets
  const [priorityFilter, setPriorityFilter] = useState(""); // Priority filter
  const [createdDateFilter, setCreatedDateFilter] = useState("");
  const [customRange, setCustomRange] = useState([]);
  const [ticketIdFilter, setTicketIdFilter] = useState(""); // Filter by Ticket ID
  const [sortOrder, setSortOrder] = useState("ascend"); 

  
  useEffect(() => {
    const selectedMenuName = localStorage.getItem("selectedMenuName")
    const data = JSON.parse(localStorage.getItem("customerDetails"))
    const filtered = tickets.filter((ticket) => {
      const title =
        ticket.ticketId && ticket.ticketId.subject
          ? ticket.ticketId.subject.toLowerCase()
          : "";
      const number =
        ticket.ticketId && ticket.ticketId.ticketNumber
          ? ticket.ticketId.ticketNumber.toLowerCase()
          : "";
      const query = searchQuery ? searchQuery.toLowerCase() : "";

      const matchesSearch = title.includes(query) || number.includes(query);
            
      const matchesStatus =
        (selectedMenuName === "myOpenTickets" &&
          ticket.ticketId.status === "Open" && ticket.ticketId.email === data.email) ||
        (selectedMenuName === "myOnHoldTickets" &&
          ticket.ticketId.status === "On Hold" && ticket.ticketId.email === data.email) ||
        (selectedMenuName === "myInProgressTickets" &&
          ticket.ticketId.status === "In Progress" && ticket.ticketId.email === data.email) ||
        (selectedMenuName === "myClosedTickets" &&
          ticket.ticketId.status === "closed" && ticket.ticketId.email === data.email) ||
        (selectedMenuName === "teamOpenTickets" &&
          ticket.ticketId.status === "Open") ||
        (selectedMenuName === "teamInProgressTickets" &&
          ticket.ticketId.status === "In Progress") ||
        (selectedMenuName === "teamOnHoldTickets" &&
          ticket.ticketId.status === "On Hold") ||
        (selectedMenuName === "teamClosedTickets" &&
          ticket.ticketId.status === "closed") ||
        selectedMenuName === "allTickets"

      const matchesPriority =
        priorityFilter === "" || ticket.ticketId.priority === priorityFilter;

      const createdDate = parseISO(ticket.ticketId.createdAt);
      let matchesDate = true;
      if (createdDateFilter === "1day") {
        matchesDate = isAfter(
          createdDate,
          new Date(new Date().setDate(new Date().getDate() - 1))
        );
      } else if (createdDateFilter === "5days") {
        matchesDate = isAfter(
          createdDate,
          new Date(new Date().setDate(new Date().getDate() - 5))
        );
      } else if (createdDateFilter === "10days") {
        matchesDate = isAfter(
          createdDate,
          new Date(new Date().setDate(new Date().getDate() - 10))
        );
      } else if (createdDateFilter === "30days") {
        matchesDate = isAfter(
          createdDate,
          new Date(new Date().setDate(new Date().getDate() - 30))
        );
      } else if (createdDateFilter === "custom" && customRange.length === 2) {
        const [start, end] = customRange;
        matchesDate = isAfter(createdDate, start) && isBefore(createdDate, end);
        console.log(
          "Custom Date Range:",
          start,
          end,
          "Ticket Date:",
          createdDate,
          "Matches:",
          matchesDate
        );
      }
      return matchesSearch && matchesStatus && matchesPriority && matchesDate;
    });

     const sortedTickets = filtered.sort((a, b) => {
      const dateA = moment(a.ticketId.createdAt);
      const dateB = moment(b.ticketId.createdAt);

      const ticketIdA = a.ticketId.ticketNumber;
      const ticketIdB = b.ticketId.ticketNumber;

      if (sortOrder === "ascend") {
        return dateA.isBefore(dateB)
          ? -1
          : dateA.isAfter(dateB)
          ? 1
          : ticketIdA.localeCompare(ticketIdB);
      } else {
        return dateB.isBefore(dateA)
          ? -1
          : dateB.isAfter(dateA)
          ? 1
          : ticketIdB.localeCompare(ticketIdA);
      }
    });

    setFilteredTickets(sortedTickets);

  }, [
    searchQuery,
    tickets,
    selectedMenuName,
    priorityFilter,
    createdDateFilter,
    customRange,
    ticketIdFilter,
    sortOrder,
  ]);

  const handleExport = () => {
    const csvData = filteredTickets.map(ticket => ({
      TicketNumber: ticket.ticketId.ticketNumber,
      Title: ticket.ticketId.name,
      Status: ticket.ticketId.status,
      Priority: ticket.ticketId.priority,
      CreatedAt: moment(ticket.ticketId.createdAt).format("DD MMM YYYY"),
    }));

    // Using PapaParse to convert the filtered data to CSV
    const csv = Papa.unparse(csvData);

    // Creating a temporary download link
    const link = document.createElement("a");
    link.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    link.target = "_blank";
    link.download = "filtered_tickets.csv";
    link.click();
  };

  // Fetch tickets from API
  useEffect(() => {
    const fetchTickets = async () => {
      const uniquePartnerId = localStorage.getItem("uniqueId")
      if(uniquePartnerId){
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CONVERSATION}/conversations/all/${uniquePartnerId}`
        );
        setTickets(response.data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    }
    };
    fetchTickets();
  }, []);

  const setSelectedTicketContext = (ticket) => {
    setSelectedTicket(ticket);
    navigate(`/portal/home/${selectedMenu}/${ticket.ticketId.ticketNumber}`);
  };

  const handleDateChange = (value) => {
    setCreatedDateFilter(value);
    if (value !== "custom") {
      setCustomRange([]); // Clear custom range if not using custom date
    }
  };
  const handleCustomDateSelect = (dates) => {
    if (dates && dates.length === 2) {
      // Capture the start and end of the selected dates in local time using date-fns
      const startDate = startOfDay(new Date(dates[0])); // Get the start of the day
      const endDate = endOfDay(new Date(dates[1])); // Get the end of the day

      setCustomRange([startDate, endDate]); // Set the custom range
    }
  };

  const columns = [
    {
      title: "Ticket ID",
      dataIndex: ["ticketId", "ticketNumber"],
      key: "ticketId",
      render: (text, record) => (
        <span
          style={{
            display: "inline-block",
            padding: "4px 8px",
            backgroundColor: "#9EAAF34D",
            borderRadius: "4px",
            color: "#2A4DAD",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => setSelectedTicketContext(record)}
        >
          {record.ticketId.ticketNumber}
        </span>
      ),
      sorter: (a, b) =>
        a.ticketId.ticketNumber.localeCompare(b.ticketId.ticketNumber),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "title",
      render: (text, record) => record.ticketId.name,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => record.ticketId.status,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (text, record) => record.ticketId.priority,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) =>
        moment(record.ticketId.createdAt).format("DD MMM YYYY"),
      sorter: (a, b) =>
        moment(a.ticketId.createdAt).isBefore(moment(b.ticketId.createdAt))
          ? -1
          : 1,
    },
    ...(selectedMenuName === "myClosedTickets" ||
    selectedMenuName === "teamClosedTickets"
      ? [
          {
            title: "Resolved Date",
            key: "resolvedDate",
            render: (text, record) =>
              moment(record.ticketId.resolvedAt).format("DD MMM YYYY"),
          },
        ]
      : []),
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: "#F1F3F6",
          height: "calc(100vh - 65px)",
          padding: "0 20px",
          overflowY: "auto",
        }}
      >
      <div className="flex justify-between items-center">
        <div className="flex gap-5 items-center">
          <div className="flex w-48 pt-4">
            <p className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100">
              Priority
            </p>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg focus:ring-blue-500 focus:border-blue-500 block"
              onChange={(e) => setPriorityFilter(e.target.value)}
              value={priorityFilter}
            >
              <option value="">Select priority</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Critical">Critical</option>
            </select>
          </div>
          <div className="flex w-60 pt-4">
            <p className="flex-shrink-0 h-8 inline-flex items-center py-2.5 px-2 text-xs text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg">
              Created Date
            </p>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-8 w-full rounded-e-lg"
              onChange={(e) => handleDateChange(e.target.value)}
              value={createdDateFilter}
            >
              <option value="">Select Created Date</option>
              <option value="1day">1 Day</option>
              <option value="5days">5 Days</option>
              <option value="10days">10 Days</option>
              <option value="30days">30 Days</option>
              <option value="custom">Custom Date</option>
            </select>
          </div>
          <div className="pt-2">
            {createdDateFilter === "custom" && (
              <div className="pt-2">
                <RangePicker onChange={handleCustomDateSelect} />
              </div>
            )}
          </div>
        </div>
        <div className="pt-2">
        <div>
        <Button
            onClick={handleExport}
            style={{
              backgroundColor: '#2A4DAD',
              color: "#fff"
            }}
          > Export </Button>
          </div>
        </div>
</div>
        <Table
          className="custom-table"
          dataSource={filteredTickets}
          columns={columns}
          rowKey="ticketId"
          pagination={{
            position: ["bottomCenter"],
          }}
          bordered={false}
        />
      </div>
     
    </>
  );
};

export default Home;
