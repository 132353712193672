import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Menu, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaLink } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

const Index = () => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isInitialRender = useRef(true);

  // Get the Ticket Explorer views from the Redux store
  const leadExplorerViews = useSelector((state) => state.leadExplorer.views);

  const defaultLeads = leadExplorerViews.filter(
    (view) => view.leadType === "Default"
  );
  const customLeads = leadExplorerViews.filter(
    (view) => view.leadType !== "Default"
  );

  const defaultLeadChildren = defaultLeads.map((view) => ({
    key: `defaultLead-${view._id}`,
    label: view.name,
    path: `/adsuite/lead-explorer/${view.name.replace(/\s+/g, "-").toLowerCase()}`,
  }));

  const customLeadChildren = customLeads.map((view) => ({
    key: `customLead-${view._id}`,
    label: view.name,
    path: `/adsuite/custom-view-explorer/${view.name.replace(/\s+/g, "-").toLowerCase()}`,
  }));

  const items = [
    {
      key: "1",
      label: "Lead Explorer",
      children: [...defaultLeadChildren],
    },
    {
      key: "2",
      label: "Custom View Explorer",
      children: [...customLeadChildren],
    },
    {
      key: "3",
      label: "Campaign View Explorer",
      path: "/adsuite/campaign",
    },
  ];

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     const currentPath = location.pathname;
  //     const currentItem = items
  //       .flatMap((item) => item.children || item)
  //       .find((item) => item.path === currentPath);

  //     if (!currentItem && defaultLeadChildren.length > 0) {
  //       const firstLeadPath = defaultLeadChildren[0].path;
  //       setSelectedKey(defaultLeadChildren[0].key);
  //       navigate(firstLeadPath);
  //     }
  //     isInitialRender.current = false; // Mark as no longer the initial render
  //   }
  // }, [location, defaultLeadChildren, navigate]);

  const getMenuItems = (menuItems) =>
    menuItems.map((item) => ({
      key: item.key,
      label: (
        <div
          onClick={(e) => {
            if (
              item.path &&
              !e.target.classList.contains("ant-menu-submenu-arrow")
            ) {
              navigate(item.path);
            }
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {item.label}
        </div>
      ),
      children: item.children ? getMenuItems(item.children) : null,
    }));

  const handleMenuClick = (e) => {
    const selectedItem = items
      .flatMap((item) => item.children || [item])
      .find((item) => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(selectedItem.path);
    }
    setSelectedKey(e.key);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = items
      .flatMap((item) => item.children || item)
      .find((item) => item.path === currentPath);
    if (currentItem) {
      setSelectedKey(currentItem.key);
      const parentKey = getParentKey(currentItem.key, items);
      if (parentKey) {
        setOpenKeys([parentKey]);
      }
    }
  }, [location, leadExplorerViews]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const getParentKey = (childKey, menuItems) => {
    for (let item of menuItems) {
      if (item.children) {
        for (let child of item.children) {
          if (child.key === childKey) {
            return item.key;
          }
        }
      }
    }
    return null;
  };

  const pathSegments = location.pathname.split("/").filter(Boolean); // Split and remove empty segments

  // Handle navigation when a segment is clicked
  const handleSegmentClick = (index) => {
    // Construct the new path up to the clicked segment
    const newPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
    navigate(newPath);
  };

  const [copied, setCopied] = useState(false); // Track whether the URL is copied

  const handleCopyUrl = () => {
    const url = `${window.location}`; // Get the current URL
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true); // Set copied state to true
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <>
       <div>
      <div
        style={{ backgroundColor: "#eff3f5" }}
        className="top-0 fixed w-full flex items-center p-2"
      >
        <div>
          <GiHamburgerMenu
            onClick={toggleSidebar}
            className={`mr-1 size-6 rounded-full ${
              isExpanded
                ? "bg-blue-600 text-white"
                : "hover:bg-gray-200 bg-transparent text-gray-500"
            } cursor-pointer p-1 z-10`}
            style={{ userSelect: "none" }}
          />
        </div>
        <div className="text-md font-semibold text-gray-500 flex items-center justify-left p-1">
          {pathSegments.map((segment, index) => (
            <React.Fragment key={index}>
              <span
                onClick={() => handleSegmentClick(index)}
                className={`capitalize hover:text-blue-500 hover:underline cursor-pointer ${
                  index === pathSegments.length - 1 && "text-blue-500 underline"
                }`}
              >
                {segment}
              </span>
              {index !== pathSegments.length - 1 && (
                <MdKeyboardArrowRight className="size-5" />
              )}
            </React.Fragment>
          ))}
          <div className="ml-2 relative group" onClick={handleCopyUrl}>
            <FaLink className="size-4 cursor-pointer " />
            <span className="absolute top-[-12px] left-2 transform -translate-x-1/2 text-[10px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-2000">
              {copied ? "Copied!" : "Copy"}
            </span>
          </div>
        </div>
      </div>
      <Row style={{ height: "100vh" }} className="mt-10">
        <div
          className={`transition-all duration-300 mt-1 ${
            isExpanded ? "w-[15%]" : "w-0"
          }`}
          style={{ backgroundColor: "#eff3f5" }}
        >
          <div style={{ width: "100%", padding: "0px" }} className="relative">
            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              onOpenChange={onOpenChange}
              style={{
                height: "calc(95vh - 3px)",
                overflowY: "auto",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "600",
              }}
              className="dexkor-help-desk-left-bar rounded-lg"
              items={getMenuItems(items)}
              openKeys={openKeys}
            />
            {isExpanded && (
              <button
                onClick={toggleSidebar}
                className="absolute -right-[3px] bottom-24 hover:bg-blue-600 hover:text-white text-gray-500 p-1 rounded-full z-50 bg-gray-100"
                aria-label={isExpanded ? "Collapse sidebar" : "Expand sidebar"}
              >
                <FaChevronLeft className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
        <div
          style={{ backgroundColor: "#eff3f5" }}
          className={`transition-all duration-300 ${
            isExpanded ? "w-[85%]" : "w-full"
          }`}
        >
          <Outlet />
        </div>
      </Row>
    </div>
    </>
  );
};

export default Index;
