import {
    SET_PARTNER_SETUP_DETAILS, SET_PARTNER_DETAILS
} from "../actions/partnerSetupDetailActions";

const initialState = {
    details: {},
    partnerDetails: {}
};

const partnerSetupDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTNER_SETUP_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case SET_PARTNER_DETAILS:
      return {...state,
      partnerDetails: action.payload,}
    default:
      return state;
  }
};

export default partnerSetupDetailsReducer;
