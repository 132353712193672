import {
  SET_LEAD_EXPLORER_VIEWS,
  UPDATE_LEAD_EXPLORER_VIEW,
  ADD_LEAD_EXPLORER_VIEW,
} from "../actions/leadExplorerViewAction";

const getDefaultViews = (currentUserInfo) => [
  {
    _id: 123444,
    name: "All Leads",
    description: "View for All Leads",
    leadType: "Default",
    columns: [
      { name: "contact name", isActive: true, apiField: "firstName" },
      { name: "company name", isActive: true, apiField: "companyName" },
      { name: "email", isActive: true, apiField: "email" },
      { name: "title", isActive: true, apiField: "title" },
      { name: "stage", isActive: true, apiField: "stage" },
      { name: "sub_stage", isActive: true, apiField: "subStage" },
      { name: "lead owner", isActive: true, apiField: "leadOwner" },
      { name: "phone", isActive: false, apiField: "phone" },
      { name: "lead source", isActive: false, apiField: "leadSource" },
      { name: "website", isActive: false, apiField: "website" },
      { name: "created date", isActive: false, apiField: "createdAt" },
      { name: "updated date", isActive: false, apiField: "updatedAt" },
      { name: "tags", isActive: false, apiField: "tags" },
    ],
    filters: [{ name: "Status", value: null }],
    itemsPerPage: 10,
    isActive: true,
  },
  {
    _id: 123443,
    name: "Unassigned Leads",
    description: "View for unassigned leads",
    leadType: "Default",
    columns: [
      { name: "contact name", isActive: true, apiField: "firstName" },
      { name: "company name", isActive: true, apiField: "companyName" },
      { name: "email", isActive: true, apiField: "email" },
      { name: "title", isActive: true, apiField: "title" },
      { name: "stage", isActive: true, apiField: "stage" },
      { name: "sub_stage", isActive: true, apiField: "subStage" },
      { name: "lead owner", isActive: true, apiField: "leadOwner" },
      { name: "phone", isActive: false, apiField: "phone" },
      { name: "lead source", isActive: false, apiField: "leadSource" },
      { name: "website", isActive: false, apiField: "website" },
      { name: "created date", isActive: false, apiField: "createdAt" },
      { name: "updated date", isActive: false, apiField: "updatedAt" },
      { name: "tags", isActive: false, apiField: "tags" },
    ],
    filters: [{ name: "Status", value: "UnAssigned" }],
    itemsPerPage: 10,
    isActive: true,
  },
  {
    _id: 123442,
    name: "Assigned Leads",
    description: "View for assigned leads",
    leadType: "Default",
    columns: [
      { name: "contact name", isActive: true, apiField: "firstName" },
      { name: "company name", isActive: true, apiField: "companyName" },
      { name: "email", isActive: true, apiField: "email" },
      { name: "title", isActive: true, apiField: "title" },
      { name: "stage", isActive: true, apiField: "stage" },
      { name: "sub_stage", isActive: true, apiField: "subStage" },
      { name: "lead owner", isActive: true, apiField: "leadOwner" },
      { name: "phone", isActive: false, apiField: "phone" },
      { name: "lead source", isActive: false, apiField: "leadSource" },
      { name: "website", isActive: false, apiField: "website" },
      { name: "created date", isActive: false, apiField: "createdAt" },
      { name: "updated date", isActive: false, apiField: "updatedAt" },
      { name: "tags", isActive: false, apiField: "tags" },
    ],
    filters: [{ name: "owner", value: currentUserInfo?._id || null }],
    itemsPerPage: 10,
    isActive: true,
  },
];

const initialState = {
  views: getDefaultViews(
    JSON.parse(localStorage.getItem("dexkorUserData") || "{}")
  ), // Fallback to empty object if localStorage is null
};

const leadExplorerReducer = (state = initialState, action) => {  
  switch (action.type) {
    case SET_LEAD_EXPLORER_VIEWS:
      return {
        ...state,
        views: [...getDefaultViews(JSON.parse(localStorage.getItem("dexkorUserData"))), ...action.payload]
      };

    case UPDATE_LEAD_EXPLORER_VIEW:
      return {
        ...state,
        views: state.views.map((view) =>
          view._id === action.payload._id ? action.payload : view
        ),
      };

    case ADD_LEAD_EXPLORER_VIEW:
      return {
        ...state,
        views: [action.payload, ...state.views],
      };

    default:
      return state;
  }
};

export default leadExplorerReducer;
